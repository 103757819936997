const admin = {
  id: `dashboard`,
  title: `Dashboard`,
  type: `group`,
  children: [
    {
      id: `default`,
      title: `Dashboard`,
      type: `item`,
      url: `/customer/dashboard`, //this page .. 
      icon: `gridView`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/customer/dashboard`],
    },
    {
      id: `quotations`,
      title: `Quotations`,
      type: `item`,
      url: `/quotes`,
      icon: `quote`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/quotes`, '/quote/create', `/quote/view`, `/quote/edit`],
    },
    {
      id: `Policy`,
      title: `Policies`,
      type: `item`,
      url: `/policy`,
      icon: `policy`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/policy`, '/customers/policy/view'],
    },
    {
      id: `Vehicle`,
      title: `Vehicle`,
      type: `item`,
      url: `/customer/Vehicle`,
      icon: `vehicle`,
      breadcrumbs: false,
      href: false,
      activeItem: ['/customer/Vehicle' , '/customer/Vehicle/view'],
    },
    // {
    //   id: `invoice`,
    //   title: `Invoice`,
    //   type: `item`,
    //   url: `/invoice`,
    //   icon: `payment`,
    //   breadcrumbs: false,
    //   href: false,
    //   activeItem: [`/invoice`],
    // },
    // {
    //   id: `documents`,
    //   title: `Documents`,
    //   type: `item`,
    //   url: `/coming_soon`,
    //   icon: `document`,
    //   breadcrumbs: false,
    //   href: false,
    //   activeItem: [`/coming_soon`],
    // },
  ],
};

export default admin;

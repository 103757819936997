import React from 'react';
import Notfound from '../../asset/images/no-record-found.png';
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next';

const NoRecordFound = () => {
  const {t} =useTranslation();
  return (
    <Stack direction='column' alignItems= "center">
        <img src={Notfound} alt="record-not-found" width="150px"/>
     {t("No Record Found")}
    </Stack>
  )
}

export default NoRecordFound

import { useState } from "react";

import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import dayjs from "dayjs";

export const useSetState = (initialState: any) => {
  const [state, setData] = useState(initialState);

  const setState = (newState: any) => {
    setData((prevState: any) => ({ ...prevState, ...newState }));
  };

  return [state, setState];
};

export function parsePhoneNumber(phoneNumber: any) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const countryCode = parsedNumber.getCountryCode();
    const nationalNumber: any = parsedNumber.getNationalNumber();

    return {
      countryCode: `+${countryCode}`,
      mobileNumber: nationalNumber.toString(),
    };
  } catch (err) {
    return {
      countryCode: null,
      mobileNumber: null,
    };
  }
}

export function parsePhoneNumberformat(countryCode: any, mobileNumber: any) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = `+${countryCode}${mobileNumber}`;
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const formattedNumber = phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);

    return formattedNumber;
  } catch (error) {
    return null; // Invalid phone number
  }
}

export function parsePhoneNumberformatMasked(countryCode: any, mobileNumber: any) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = `+${countryCode}${mobileNumber}`;
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const formattedNumber = phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
    let maskedValue = formattedNumber.replace(/[a-zA-Z0-9]/g, 'X'); // Replace all alphanumeric characters with 'X'
    const newStr = maskedValue.slice(0, -3); 
    const str = formattedNumber.substring(12);
    const updatedMobile = newStr+str;
    return updatedMobile;
  } catch (error) {
    return null; // Invalid phone number
  }
}

export function dateOfBirthMask(date: string){

  try{
    const maskedDob = dayjs(date)?.format('MM/DD/YYYY') || "-";
    const parts = maskedDob.split('/');
    const maskedDay = 'XX';
    const maskedMonth = 'XX';
    const year = 'X' + parts[2].substring(1);
    return `${maskedMonth}/${maskedDay}/${year}`;
  }
  catch(error) {
    return null;
  }

}

export function dmvLicenseNumberMask(dmv_license_number: any){
  try {
    const maskedDigits = 'X'.repeat(dmv_license_number.length - 3);
    const lastThreeDigits = dmv_license_number.slice(-3);
    return maskedDigits + lastThreeDigits;
  } catch(error) {
   return dmv_license_number;
  }
}

export function parsePhoneFormatNumber(phoneNumber: string) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const formattedNumber = phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);

    return formattedNumber;
  } catch (error) {
    return phoneNumber
  }
}

export const formatSsn = (value: any, isCheckNumber = true) => {
  try {
    let finalValue = "";

    var val = isCheckNumber ? value.replace(/\D/g, "") : value;
    var newVal = "";
    if (val.length > 4) {
      finalValue = val;
    }
    if (val.length > 3 && val.length < 6) {
      newVal += val.substr(0, 3) + "-";
      val = val.substr(3);
    }
    if (val.length > 5) {
      newVal += val.substr(0, 3) + "-";
      newVal += val.substr(3, 2) + "-";
      val = val.substr(5);
    }
    newVal += val;
    finalValue = newVal.substring(0, 11);

    return finalValue;
  }
  catch {
    return value
  }
};



export const formatZipcode = (value: any, isCheckNumber = true) => {
  try {
    let finalValue = "";

    var val = isCheckNumber ? value.replace(/\D/g, "") : value;
    var newVal = "";

    if (val.length > 5) {
      newVal += val.substr(0, 5) + "-";
      val = val.substr(5);
    }
    newVal += val;
    finalValue = newVal.substring(0, 10);

    return finalValue;
  }
  catch {
    return value
  }
}
export const allowOnlyNumericValues = (value: any, isCheckNumber = true) => {
  try {
    let finalValue = "";

    var val = isCheckNumber ? value.replace(/\D/g, "") : value;
    var newVal = "";

    if (val.length > 2) {
      newVal += val.substr(0, 2) + "-";
      val = val.substr(2);
    }
    newVal += val;
    finalValue = newVal.substring(0, 10);

    return finalValue;
  }
  catch {
    return value
  }

};

export const formatAllowOnlyNumericValues = (val: any, isCheckNumber = true) => {
  try {
    let finalValue = "";


    var newVal = "";

    if (val.length > 2) {
      newVal += val.substr(0, 2) + "-";
      val = val.substr(2);
    }
    newVal += val;
    finalValue = newVal.substring(0, 10);

    return finalValue;
  }
  catch {
    return val
  }

};

export const unMaskedPhoneOffice = (val: any) => {
  try {
    let maskedValue = val.replace(/[a-zA-Z0-9]/g, 'X'); // Replace all alphanumeric characters with 'X'
    const newStr = maskedValue.slice(0, -3); 
    const str = val.substring(12);
    const updatedMobile = newStr+str;
    console.log(updatedMobile);                       
    return updatedMobile;
  }
  catch {
    return val;
  }                                                                                                                                                     
}                                                                                                                                                 


export const formatLicense = (value: any) => {
  try {
    let finalValue = "";

    var val = value.replace(/\D/g, "");
    var newVal = "";
    if (val.length > 4) {
      finalValue = val;
    }
    if (val.length > 3 && val.length < 7) {
      newVal += val.substr(0, 3) + " ";
      val = val.substr(3);
    }
    if (val.length > 5) {
      newVal += val.substr(0, 3) + " ";
      newVal += val.substr(3, 3) + " ";
      val = val.substr(6);
    }
    newVal += val;
    finalValue = newVal.substring(0, 11);

    return finalValue;
  } catch {
    return value
  }
};


export const handleKeyNumberPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  const enteredKey = event.key;
  if (!/^[0-9]$/.test(enteredKey)) {
    event.preventDefault();
  }
};

export const handleKeyDecimalPress = (event: any) => {
  const inputValue = event.key;
  // Allow digits, a decimal point (if not already present), and specific control keys
  const isValidKey =
    /^\d$/.test(inputValue) ||
    (inputValue === '.' && event.target.value.indexOf('.') === -1) ||
    event.key === 'Backspace' ||
    event.key === 'Delete' ||
    event.key === 'ArrowLeft' ||
    event.key === 'ArrowRight' ||
    event.key === 'Home' ||
    event.key === 'End';

  if (!isValidKey) {
    event.preventDefault();
  }
}
export const handleKeyNumberCommaPress = (event: any) => {
  const inputValue = event.key;
  // Allow digits, a comma, and specific control keys
  const isValidKey =
    /^\d$/.test(inputValue) || // Allow digits
    inputValue === ',' || // Allow comma
    event.key === 'Backspace' || // Allow backspace
    event.key === 'Delete' || // Allow delete
    event.key === 'ArrowLeft' || // Allow left arrow
    event.key === 'ArrowRight' || // Allow right arrow
    event.key === 'Home' || // Allow home key
    event.key === 'End'; // Allow end key

  if (!isValidKey) {
    event.preventDefault();
  }
};


// export function dateFormat(dateString: any) {
//   const date = new Date(dateString);
//   const formattedDate = date
//     .toLocaleDateString("en-US", {
//       month: "2-digit",
//       day: "2-digit",
//       year: "numeric",
//     })
//     .replace(/(\d+)\/(\d+)\/(\d+)/, (match, month, day, year) => {
//       // Rearrange the date format to month day and year
//       return `${month}-${day}-${year}`;
//     });
//   return formattedDate;
// }

export function dateFormat(dateString: any) {
  // Check if dateString is empty or not a valid date
  if (!dateString || isNaN(Date.parse(dateString))) {
    return "-"; // Return empty string if dateString is empty or not a valid date
  }
  
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, (match, month, day, year) => {
      // Rearrange the date format to month day and year
      return `${month}-${day}-${year}`;
    });
  return formattedDate;
}

export function maskPhoneNumber(phoneNumber: any) {
  // Check if the phone number is valid
  if (!phoneNumber || phoneNumber.length < 10) {
    return "-"; // Return "-" if the phone number is invalid
  }

  // Assuming phone number format is "1234567890"
  const lastThreeDigits = phoneNumber.slice(-3); // Get the last 3 digits
  const maskedPart = "*".repeat(phoneNumber.length - 3); // Mask all but the last 3 digits

  // Format the masked number
  const maskedNumber = `${maskedPart}${lastThreeDigits}`;

  return maskedNumber;
}


export function maskAccountNumber(accountNumber:any) {
  // Check if the account number is valid
  if (!accountNumber || accountNumber.length < 4) {
    return "-"; // Return "-" if the account number is invalid
  }

  // Mask all but the last 3 digits
  const maskedPart = "*".repeat(accountNumber.length - 3);
  return `${maskedPart}${accountNumber.slice(-3)}`;
}



export function dateFormatForm(dateString: any) {
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, (match, month, day, year) => {
      // Rearrange the date format to month day and year
      return `${month}-${day}-${year}`;
    });
  return formattedDate;
}

export function dateTimeFormat(dateString: any) {
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, "$1-$2-$3 $4:$5:$6");
  return formattedDate;
}

export function dateTimeFormatWithoutSecond(dateString: any) {
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, "$1-$2-$3 $4:$5");
  return formattedDate;
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function trimStringsInObject(obj: Record<string, any>): Record<string, any> {
  // Base case: if the input is not an object, return it as is
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  // Initialize a new object to store the trimmed values
  const trimmedObj: Record<string, any> = {};
  // Iterate through the keys of the input object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Check if the value is a string, and if so, trim it
      if (typeof value === 'string') {
        trimmedObj[key] = value.trim();
      } else {
        // If the value is not a string, leave it unchanged
        trimmedObj[key] = value;
      }
    }
  }
  return trimmedObj;
}

export function booleanToStringsInObject(obj: Record<string, any>): Record<string, any> {
  // Base case: if the input is not an object, return it as is
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  // Initialize a new object to store the trimmed values
  const modifiedObj: Record<string, any> = {};
  // Iterate through the keys of the input object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Check if the value is a string, and if so, trim it
      if (typeof value === 'string') {
        modifiedObj[key] = value.trim();
      } else if (typeof value === 'boolean') {
        // If the value is boolean, convert it to a string
        modifiedObj[key] = String(value);
      } else {
        // If the value is not a string or boolean, leave it unchanged
        modifiedObj[key] = value;
      }
    }
  }
  return modifiedObj;

}
export function shortenFileName(row: string) {
  // try {
    const fileName = row?.split("/")?.slice(-1)?.toString() || "";
    // const dotIndex = fileName.lastIndexOf('.');
    // const extension = dotIndex !== -1 ? fileName.substring(dotIndex) : '';
    const shortenedFileName = fileName.length > 15 ? fileName.substring(0, 12) + '...' + fileName.split('.').pop() : fileName;
    return shortenedFileName;
  // } catch (err) {
  //   return ""
  // }
}

interface PaymentItem {
  id: string;
  due_date: string;
  scheduled_amt: number;
  reason: string;
  type: string;
  deposit: boolean;
  created_at: string;
  updated_at: string;
  due_amount: number;
  premium_deposit: any;
  start_date: any;
}

export const slicePaymentList = (data: any, targetDate: string) => {
  // Sort the data based on due_date
  const sortedData = data.sort((a: any, b: any) => {
    return new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
  });

  // Find the index of the closest date to targetDate in sortedData
  let targetIndex = sortedData.findIndex(
    (item: any) => item.due_date.split('T')[0] >= targetDate
  );

  // If targetIndex is -1, all dates are before today, so set it to the last index
  if (targetIndex === -1) {
    targetIndex = sortedData.length - 1;
  }

  // Items before targetDate
  const beforeItems = sortedData.slice(0, targetIndex);
console.log('sortedData', sortedData)
  // Items after targetDate
  const afterItems = sortedData.slice(targetIndex);
  const afterFeeList = afterItems
  .filter((item: any) => item.type === 'Fee');
  const filterBeforePremium = beforeItems
  .filter((item: any) => item.type === 'Premium');
  const filterPremium = afterItems
  .filter((item: any) => item.type === 'Premium');
  const balPremiumAmt = filterPremium
  .reduce((sum: number, item: any) => sum + item.scheduled_amt, 0);
  const installmentBal: any = afterItems
    .filter((item: any) => item.type === 'Installment Charge')
    .reduce((sum: number, item: any) => sum + item.scheduled_amt, 0);

  return { beforeItems, afterItems, balPremiumAmt, premiumCount: filterBeforePremium?.length, afterFeeList, installmentBal };
};

export const arraySortData = (array: any[], key: string) => {
  return array.sort((a, b) => {
    return new Date(a[key]).getTime() - new Date(b[key]).getTime();
  });
}

export const calculateFeeList = (feesList: PaymentItem[], afterFeeList: PaymentItem[]) => {
  return new Promise((resolve, reject) => {
    const result: PaymentItem[] = JSON.parse(JSON.stringify(afterFeeList)); // Copy afterFeeList to avoid mutation
    // Iterate through feesList
    feesList.forEach(feeItem => {
      // Check if feeItem exists in afterFeeList
      const existingItemIndex = afterFeeList.findIndex(item => item.reason === feeItem.reason);
      if (existingItemIndex !== -1) {
        // If feeItem exists, sum the "scheduled_amt"
        result[existingItemIndex].scheduled_amt += feeItem.scheduled_amt;
        result[existingItemIndex].due_amount = result[existingItemIndex].scheduled_amt;
      } else {
        // If feeItem doesn't exist, push it to the result list
        result.push(feeItem);
      }
    });
  
    resolve(result);
  })
};

export const maskSocialSecurity = (ssn:string) => {
  if (typeof ssn === 'string' && ssn.length >= 3) {
      const visibleDigits = ssn.slice(-3); // Last 3 digits are visible
      const maskedDigits = '*'.repeat(ssn.length - 3); // Masking all digits except the last 3
      return maskedDigits + visibleDigits;
  }
  return '-';
}

export const convertToTitleCase = (text: string) => {
  if(!!text) {
  // Split the text into words based on spaces
  let words = text.split(' ');

  // Capitalize the first letter of each word
  words = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back together with a space
  return words.join(' ');
} else return ""
};
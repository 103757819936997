import "./App.scss";
import { useAppSelector } from "./store/store";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "asset/themes";
import NavigationScroll from "container/layout/NavigationScroll";
import { SetAxiosDefauls } from "helpers/interceptor";
import { Suspense, useContext, useEffect } from "react";
import RoutePath from "routes";
import { clearStore } from "utils/redux.utils";
import Loader from "ui-component/Loader";
import { RouterProvider } from "react-router-dom";
import GlobalErrorBoundary from "ErrorBoundary";
import { SocketProvider } from "routes/socket.provider";
import i18n from "utils/i18n";

function App() {
  SetAxiosDefauls();
  const customization = useAppSelector((state: any) => state.customization);
  const {language} = useAppSelector((state: any) => state.settings);
// Define a fallback UI component for the error boundary
const FallbackUI: React.ReactNode = (
  <div>
    <h2>Something went wrong!</h2>
    <p>Please refresh the page or try again later.</p>
  </div>
);

// useEffect(() => {
//   i18n.changeLanguage(language || "en");
// }, [])

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline>
            {/* <NavigationScroll> */}
              <SocketProvider>
                <Suspense fallback={<Loader />}>
                  <wc-toast position="bottom-right"></wc-toast>
                  <RouterProvider router={RoutePath} />
                </Suspense>
              </SocketProvider>
  {/* </NavigationScroll> */}
          </CssBaseline>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;

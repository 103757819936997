import EnhancedTable from 'component/tables/selectableTable'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import { useEffect, useState } from 'react';
import { t, use } from "i18next";
import { commonService } from 'forms/helpers/common.service';
import { Failed, Success } from 'forms/helpers/toast.helper';
import { errorMessage } from 'forms/helpers/function.helper';
import { useNavigate } from 'react-router-dom';
import deleteAlert from 'component/HOC/deleteAlert';
import SweetAlertComponent from 'component/HOC/Alert';
import { debounce } from '@mui/material';

function FinanceCompanyList() {

    const financeCompanyListHeaderCell = [

        {
            id: "name",
            numeric: false,
            disablePadding: false,
            label: t("Name"),
            isSort: true,
        },
        {
            id: "email",
            numeric: false,
            disablePadding: true,
            label: t("email"),
            isSort: true,
            isBold: true,
        },
        {
            id: "contact_name",
            numeric: false,
            disablePadding: false,
            label: t("contact_name"),
            isSort: true,
        },
        {
            id: "account",
            numeric: false,
            disablePadding: false,
            label: t("account"),
            isSort: true,
        },
        {
            id: "website",
            numeric: false,
            disablePadding: false,
            label: t("website"),
            isSort: false,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: t("Action"),
            isSort: false,
        },

    ]

    let [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
    let [orderBy, setOrderBy] = useState("created_at");
    const [count, setCount] = useState<any>();
    const [rows, setRows] = useState<any>();
    const [initailLoad, setInitailLoad] = useState(true);
    const [isLoading, setIsLoading] = useState<any>(false);
    const navigate = useNavigate();

    const title = `${t("Finance Company List")}`



    useEffect(() => {
        getTableData()
    }, [rowsPerPage, page, orderBy, order])



    const getTableData = () => {
        commonService
            .getServiceWithParams(`finance-company/get-all-finance-company`,
                {
                    per_page: rowsPerPage,
                    page: page + 1,
                    search: search ?? "",
                    sort_order: order,
                    sort_by: orderBy,

                }).then((res: any) => {
                    const tableData = res?.data?.data?.results.map((i: any) => {
                        return {
                            id: i?.id,
                            name: i?.name,
                            email: i?.email,
                            contact_name: i?.contact_name,
                            account: i?.account,
                            website: i?.website,
                        }
                    })
                    setRows(tableData)
                    setCount(res?.data.data?.pagination?.total);
                    setIsLoading(false);
                    setInitailLoad(false);
                }).catch((err: any) => {
                    Failed(
                        errorMessage(err, "Something went wrong, please try again later")
                    )
                })
    }


    const handleAction = (id: string, action: string) => {
        if (action === "edit") {
            navigate(`/settings/financeCompany/edit/${id}`)
        } else if (action === "delete") {
            handleDelete(id)
        }
    }


    const handleDelete = (id: string) => {
        deleteAlert({
            title: t("Delete"),
            message: t("Are You sure?"),
            confirmButtonText: t("Yes"),
            onConfirmed: deleteAction,
            data: id,
        });
    };


    const deleteAction = (id: string) => {
        commonService
            .deleteService(`/finance-company/${id}`)
            .then((res: any) => {
                Success(res?.data?.message);
                getTableData();
            })
            .catch((err) => {

            });
    };

    const handleCreateFinance = () => {
        navigate(`create`)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "ASC";
        order = isAsc ? "DESC" : "ASC";
        setOrder(order);
        orderBy = property;
        setOrderBy(orderBy);
    };



    const searchHandler = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        search = e.target.value;
        setSearch(search);
    };

    useEffect(() => {
        const delay = 500; // Debounce delay in milliseconds
        const performSearch = (text: string) => {
            // Perform your search logic here
            if (initailLoad) return;
            if (page == 0) getTableData();
            else {
                setPage(0);
            }
        };
        // if (search.length >= 3 || !search) {
        const debouncedSearch = debounce(performSearch, delay);
        const timeoutId = setTimeout(() => debouncedSearch(search), delay);
        return () => {
            clearTimeout(timeoutId);
        };
        // }
    }, [search]);



    return (
        <>
            <MainContainerWrapper
                headingText={title}
                isCreateButton
                isBackButton={true}
                buttonText={t("Create")}
                buttonIcon={<span>&#43;</span>}
                handleClick={handleCreateFinance}
                isSearch={true}
                search={search}
                searchPlaceHolder={t("Search")}
                handleSearch={searchHandler}
                handleSearchClear={() => setSearch("")}

            >
                <EnhancedTable
                    headCells={financeCompanyListHeaderCell}
                    rows={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    total={count}
                    isEdit={true}
                    actionFunction={handleAction}
                    isDelete={true}
                />

            </MainContainerWrapper>
        </>
    )
}

export default FinanceCompanyList


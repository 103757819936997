import { Grid, IconButton, useTheme } from "@mui/material";
import FormLayout from "component/form";
import AddressModal from "component/helper-modals/address.modal";
import { commonService } from "forms/helpers/common.service";
import { InputField, SelectField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { createBankValidation, createBankValidationSchema } from "forms/hooks/validateRules";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import EnhancedTable from "component/tables/selectableTable";
import { addressHeadCell } from "utils/table.utils";
import Loader from "ui-component/Loader";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useAppSelector } from "store/store";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const Bank = ({
      handleBack,
      bankID,
    }: BankInfoTypes
  ) => {
  const [addressOpen, setAddressOpen] = useState(false);
  const [address, setAddress] = useState<any[]>([]);
  const [addressOptions, setAddressOptions] = useState<any[]>([]);
  const theme = useTheme();
  const [acctType, setActType] = useState<any>();
  const [entityType, setEntityType] = useState<any>();
  const [bankType, setBankType] = useState<any>();
  const [editvalues, setEditvalues] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const { locationID } = useAppSelector((state) => state.location);
  
  const initialValues = {
    bank: null,
    routing_no: null,
    account_no: null,
    account_type: null,
    starting_check_no: null,
    name_of_account: null,
    address: null,
    entity_type: null,
  };

  const initialAddressValues = {
    address_type: null,
    address_line_1: null,
    address_line_2: null,
    state: null,
    statename: null,
    is_default: 0,
    city: null,
    zip_code: null,
  };

  const handleFormSubmit = async () => {
    try {
      const defaultAddress = address?.map((add) => {
        return {
          ...add,
          address_type: add?.address_type,
          state: add?.state,
          is_default: values?.address === add?.id ? 1 : 0,
        };
      });
      const data: any = {
            ...values,
            tenantBankAddresses: defaultAddress,
            routing_no: +values?.routing_no
          };
        if(bankID) data['id'] = bankID;
      const api = bankID
      ? `/tenant-bank`
      : `/tenant-bank`;
        commonService
          .postPutService(api, {
            tenantBanks: [data],
            location: locationID,
          }, bankID ? "patch" : "post")
          .then((res) => {
            console.log(values);
            Success(res?.data?.message);
            setIsLoading(false);
            handleBack();
          })
          .catch((err) => {
            setIsLoading(false);
            Failed(
              errorMessage(err, "Something went wrong, please try again later")
            );
          });
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getMaster();
    if (bankID) {
      getBankInfo();
    }
  }, []);
  function getMaster() {
    let params = new URLSearchParams();
    params.append("types", `Account Type`);
    params.append("types", "Entity Type");
    params.append("types", "Bank");

    commonService
      .getServices(`/master?${params.toString()}`)
      .then((res) => {
        const AccountType = res?.data?.data["Account Type"];
        const EntityType = res?.data?.data["Entity Type"];
        const Bank = res?.data?.data.Bank;

        if (AccountType) {
          setActType(AccountType);
        }

        if (EntityType) {
          setEntityType(EntityType);
        }
        if (Bank) {
          setBankType(Bank);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  const handleAddressModal = () => {
    setAddressOpen(!addressOpen);
  };

  const addAddress = (values: any) => {
      const id = uuid();
      const data = [{ ...values, id: id }, ...address];
      setAddress(data);
      const addressOptionList = [
        ...addressOptions,
        {
          name:
            values.address_line_1 +
            " " +
            (values.address_line_2 ? values.address_line_2 : ""),
          value: id,
        },
      ];
      setAddressOptions(addressOptionList);
      if(values?.is_default) setUpdateValue("address", id);
    setAddressOpen(false);
  };
  const { t } = useTranslation();
 const importModalT = createBankValidationSchema(t);
  const {
    errors,
    values,
    handleChange,
    setUpdateValue,
    setUpdateFormValue,
    handleSubmit,
    setPatchValue,
  } = useForm(initialValues, handleFormSubmit, createBankValidation,importModalT);

  const handleAction = (id: string, action: string) => {
    if (action === "delete") {
      const remainingAddress = address.filter((item) => item.id !== id);
      setAddress(remainingAddress);
      const options = remainingAddress.map((data: any) => {
        return {
          name:
            data.address_line_1 +
            " " +
            (data.address_line_2 ? data.address_line_2 : ""),
          value: data?.id,
        };
      });
      setAddressOptions(options);
      if (remainingAddress?.length === 0) {
        setUpdateFormValue("address", null);
      }
    }
  };

  const getBankInfo = () => {
    commonService
      .getServiceWithParams(`tenant-bank/${bankID}/`, {
        tab: "bank",
      })
      .then((res: any) => {
        const bankInfo = res?.data?.data;
        if (!!bankInfo) {
          setEditvalues(bankInfo);
          editvalues.id = bankInfo.id;
          editvalues.bank = bankInfo.bank.id;
          editvalues.routing_no = bankInfo.routing_no;
          editvalues.account_no = bankInfo.account_no;
          editvalues.starting_check_no = bankInfo.starting_check_no;
          editvalues.name_of_account = bankInfo.name_of_account;
          editvalues.account_type = bankInfo.account_type?.id?? null;
          editvalues.entity_type = bankInfo.entity_type?.id?? null;
          const addressData: any[] = [];
          const addressOptions: any[] = [];
          bankInfo.tenantBankAddresses.forEach((address: any) => {
            addressData.push({
              ...address,
              state: address?.state?.id,
              statename: address?.state?.name,
            });
            addressOptions.push({
              name:
                address.address_line_1 +
                " " +
                (address.address_line_2 ? address.address_line_2 : ""),
              value: address.id,
            });
            if (address.is_default === 1) {
              editvalues.address = address.id;
            }
          });
          setAddress(addressData);
          setAddressOptions(addressOptions);
          setPatchValue(editvalues);
        }
      });
  };
  const title = bankID? "Edit Bank" : "Create Bank";
  return (
    <MainContainerWrapper headingText={title}>
      {isLoading && <Loader />}

      {addressOpen && (
        <AddressModal
          open={addressOpen}
          initValues={initialAddressValues}
          handleClose={handleAddressModal}
          handleFormSubmit={addAddress}
        />
      )}
      <FormLayout
        isBackButton={true}
        handleSubmit={handleSubmit}
        // scrollable="hidden"
        backButtonClick={handleBack}
        submitButtonText={t("Complete")}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 2 }}
          // px={2}
        >
          <Grid item xs={12} lg={3}>
            <SelectField
              fieldName={t("Bank")}
              values={values?.bank || ""}
              name="bank"
              errors={errors?.bank}
              handleChange={handleChange}
              options={bankType}
              id="bank"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <InputField
              fieldName={t("Routing No")}
              values={values?.routing_no || ""}
              name="routing_no"
              errors={errors?.routing_no}
              handleChange={handleChange}
              id="routing_no"
              length={9}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <InputField
              fieldName={t("Account No")}
              values={values?.account_no || ""}
              name="account_no"
              errors={errors?.account_no}
              handleChange={handleChange}
              id="account_no"
              onlyNumber
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <SelectField
              fieldName={t("Account Type")}
              values={values?.account_type || ""}
              name="account_type"
              errors={errors?.account_type}
              handleChange={handleChange}
              options={acctType}
              id="account_type"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Grid container>
              <Grid item xs={12} lg={10}>
                <SelectField
                  fieldName={t("Default Address")}
                  values={values?.address || ""}
                  name="address"
                  errors={errors?.address}
                  handleChange={handleChange}
                  options={addressOptions}
                  id="address"
                />
              </Grid>
              <Grid item xs={12} lg={1}>
                <IconButton
                  color="primary"
                  aria-label="Add email"
                  component="button"
                  sx={{
                    mt: 5.7,
                    ml: 2,
                    backgroundColor: theme?.palette?.primary?.main,
                    "&:hover": {
                      backgroundColor: theme?.palette?.primary?.main,
                    },
                  }}
                  onClick={handleAddressModal}
                >
                  <AddIcon sx={{ color: "#FFFFFF" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <InputField
              fieldName={t("Starting Check No")}
              values={values?.starting_check_no || ""}
              name="starting_check_no"
              errors={errors?.starting_check_no}
              handleChange={handleChange}
              id="starting_check_no"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <InputField
              fieldName={t("Account Name")}
              values={values?.name_of_account || ""}
              name="name_of_account"
              errors={errors?.name_of_account}
              handleChange={handleChange}
              id="name_of_account"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <SelectField
              fieldName={t("Entity Type")}
              values={values?.entity_type || ""}
              name="entity_type"
              errors={errors?.entity_type}
              handleChange={handleChange}
              options={entityType}
              id="entity_type"
            />
          </Grid>
          
          <Grid item lg={6} sx={{ mt: 2 }}>
            <EnhancedTable
              headCells={addressHeadCell}
              rows={address}
              isDelete={true}
              isPagination={false}
              width={"100%"}
              actionFunction={handleAction}
            />
          </Grid>
        </Grid>
      </FormLayout>
    </MainContainerWrapper>
  );
};
type BankInfoTypes = {
  handleBack?: any;
  bankID?: any;
};
export default Bank;

import { Roles } from "helpers/enum.helpers";
import { popupCustomer, popupEmployee, popupPaidTenent, popupTenentFree } from "./popup-item";

const popupItems: any = {
  [Roles.TenantAdmin]: popupPaidTenent,
  [Roles.TenantEmployee]: popupEmployee,
  [Roles.Customer]: popupCustomer,
  "tenentFree": popupTenentFree,
};

export default popupItems;

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./localstorage.helper";
import { useAppDispatch, useAppSelector } from "../store/store";
import { commonService } from "forms/helpers/common.service";
import { clearStore } from "utils/redux.utils";
import {
  setMenuItemsRedux,
  setPopupItemsRedux,
  setSubMenuItemsRedux,
  setUserRedux,
} from "store/slice/user.slice";
import menuItems from "utils/menu-items";
import popupItem from "utils/popup-item";
import { Roles } from "helpers/enum.helpers";
import { setSettings } from "store/slice/settings.slice";
import Loader from "ui-component/Loader";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { useTranslation } from "react-i18next";
import i18n from "utils/i18n";

export const AuthContext: any = createContext({});

export const AuthProvider = ({ children }: any) => {
  // const { i18n } = useTranslation();
  const [token, setToken] = useLocalStorage(
    "token",
    localStorage.getItem("token")
  );
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const {language} = useAppSelector((state: any) => state.settings);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("TOKEN", token);
    if (token) {
      getUser();
    }
    getLandingPageDetails();
  }, []);

  const getLandingPageDetails = async () => {
    try {
      const data = await commonService.getServices(`/landing-page/fetch`);
      dispatch(setSettings(data?.data?.data[0]));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Landing Page Details Fetching Failed", err);
    }
  };
  const location = useLocation();
  const getUser = async () => {
    try {
      commonService.getServices(`/user/getProfile`).then(async (res) => {
        const resData = res?.data?.data;
        dispatch(setUserRedux(resData));
        dispatch(setSubMenuItemsRedux([]));
        if (resData?.role?.name === Roles.TenantAdmin) {
          dispatch(
            setPopupItemsRedux(
              popupItem[
                resData?.plan?.is_free === 0
                  ? resData?.role?.name
                  : "tenentFree"
              ] || []
            )
          );
          dispatch(
            setMenuItemsRedux(
              menuItems[
                resData?.plan?.is_free === 0
                  ? resData?.role?.name
                  : "tenentFree"
              ] || []
            )
          );
        } else {
          dispatch(setPopupItemsRedux(popupItem[resData?.role?.name] || []));
          dispatch(setMenuItemsRedux(menuItems[resData?.role?.name] || []));
        }
        // console.log('location.pathname', location.pathname);
        // if(location.pathname === "/login") {
        // const lang = !!language? language : resData?.language;
        // if(!!lang && resData?.role?.name !== Roles.Customer) {
        //   await commonService.patchService(`/tenant/update-language`, { language: lang });
        //   i18n.changeLanguage(lang);
        // } else 
        // if(resData?.role?.name === Roles.Customer) {
          // await commonService.patchService(`/tenant/update-language`, { language: 'en' });
          i18n.changeLanguage(resData?.language || "en");
        // }
      // }
      });
    } catch (err) {
      console.log("err", err);
      clearStore();
      setToken(null);
      navigate("/login");
    }
  };

  const login = async (data: any) => {
    setToken(data?.token);
    await getUser();
  };

  const logout = async () => {
    try {
      await commonService.postService(`/auth/logout`, {});
      clearStore();
      setToken(null);
      setToken(null);
      navigate("/quote", { replace: true }); //
    } catch (err) {
      Failed(errorMessage(err, "Something went wrong."));
    }
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      user,
      getUser,
      getLandingPageDetails,
    }),
    [token]
  );

  if (loading) return <Loader />;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth: any = () => {
  return useContext(AuthContext);
};

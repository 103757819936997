import { Box, Grid, Typography } from "@mui/material";
import { commonService } from "forms/helpers/common.service";
import TextView from "forms/hoc/text-view";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "ui-component/Loader"; 
import { useTranslation } from "react-i18next";
 

const WholesellerView = () => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const [locationData, setLocationData] = useState<any>({});
  const { id } = useParams();

  useEffect(() => {
    if (id) {
        getWholesellerData();
    }
  }, []);

   const getWholesellerData = () => {
    setIsloading(true);
    commonService
      .getServices(`/whole-salers/${id}`)
      .then((res) => {       
        console.log("Location data",res);
        const locationData = {
            name: res.data.data?.name,
            fax: res.data.data?.fax,
            contact_name: res.data.data?.contact_name,
  
            address_line_1: res.data.data?.address?.address_line_1,
            address_line_2: res.data.data?.address?.address_line_2,
            // state: res.data.data?.state?.id,
            state: res.data.data?.address?.state?.name,
            city: res.data.data?.address?.city,
            zip_code: res.data.data?.address?.zip_code,
            phone : res?.data.data?.phone,
           // contact_name : res?.data.data?.contact_name,
            bank_account : res?.data.data?.bank_account,
            email : res?.data.data?.email
        }
        setLocationData(locationData);
        setIsloading(false);
      })

  }

  return (
    <>
    <MainContainerWrapper
    headingText="Wholesaler View"
    isBackButton={true}>
      {isLoading && <Loader />}
      <Grid container sx={{ paddingRight: "25px" , pl:2 }}>
        <Grid item xs={12} lg={12}>
          <Box py={2} pl={2}>
          </Box>
        </Grid>
        <Grid container columnSpacing={2} pt={1}>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName= {t("Name")}
                fieldValue={
                    locationData?.name ? locationData?.name : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Fax")}
                fieldValue={
                    locationData?.fax ? locationData?.fax : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Phone")}
                fieldValue={
                    locationData?.phone ? locationData?.phone : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Contact Name")}
                fieldValue={
                    locationData?.contact_name ? locationData?.contact_name : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("email")}
                fieldValue={
                    locationData?.email ? locationData?.email : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Bank Account")}
                fieldValue={
                    locationData?.bank_account ? locationData?.bank_account : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Address Line One")}
                fieldValue={
                    locationData?.address_line_1? locationData?.address_line_1 : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Address Line Two")}
                fieldValue={
                    locationData?.address_line_2 ? locationData?.address_line_2 : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("State")}
                fieldValue={
                    locationData?.state ? locationData?.state : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("City")}
                fieldValue={
                    locationData?.city ? locationData?.city: "-"
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("zip_code")}
                fieldValue={
                    locationData?.zip_code ? locationData?.zip_code: "-"
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      </MainContainerWrapper>
    </>
  );
};

export default WholesellerView;

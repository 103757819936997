import { Box, Grid } from "@mui/material";
import FormLayout from "component/form";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { Success } from "forms/helpers/toast.helper";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { smsConfirgurationPagevalidation, smsConfirgurationPagevalidationSchema } from "forms/hooks/validateRules";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
 
 
export default function SmsConfig(){
 
    const [loading, setLoading] =  useState<any>(false);
 
    const  navigate = useNavigate()
 
    const initialValues = {
        twilio_accountSid:null,
        twilio_authToken:null,
        twilio_fromNumber:null
    }
 
    const handleBack = () => {
        navigate(-1);
      };
 
    const formSubmit = () => {
        setLoading(true);
        const api = `/settings-config/sms`
        commonService.postService(api,values)
        .then((res)=>{
            setLoading(false);
            Success(res?.data?.message)
            handleBack()
        })
 
    }
 
    const { t } = useTranslation();
    const importModalT = smsConfirgurationPagevalidationSchema(t);
  
    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
 
     } =  useForm(initialValues,formSubmit,smsConfirgurationPagevalidation, importModalT)
 
 
     useEffect(()=>{
        getSmsConfigData();
     },[])
 
     const getSmsConfigData = () =>{
        setLoading(true);
        commonService.
        getServices(`/settings-config/sms`)
        .then((res)=>{
            const result =  res?.data?.data;
            const smsConfigData : any = {}
            smsConfigData.twilio_accountSid = result?.twilio_accountSid;
            smsConfigData.twilio_authToken = result?.twilio_authToken;
            smsConfigData.twilio_fromNumber = result?.twilio_fromNumber;
            setPatchValue(smsConfigData);
            setLoading(false);
        })
     }
 
    return(
        <>
        <MainContainerWrapper headingText={t("SMS Configuration")} isBackButton = {true}>
        <Box sx={{p:3}}>
            {loading && <Loading/>}
            <FormLayout
                handleSubmit={handleSubmit}
                submitButtonText={t("Submit")}
            >
                <>
               <Grid container columnSpacing={2}>
               <Grid item xs={12} lg={4}>
                    <InputField
                        fieldName={t("Account SID")}
                        values={values?.twilio_accountSid || ""}
                        name="twilio_accountSid"
                        errors={errors?.twilio_accountSid}
                        handleChange={handleChange}
                        id="twilio_accountSid"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <InputField
                        fieldName={t("Auth Token")}
                        values={values?.twilio_authToken || ""}
                        name="twilio_authToken"
                        errors={errors?.twilio_authToken}
                        handleChange={handleChange}
                        // mask={true} // This will mask the input
                        id="twilio_authToken"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <InputField
                        fieldName={t("From Number")}
                        values={values?.twilio_fromNumber || ""}
                        name="twilio_fromNumber"
                        errors={errors?.twilio_fromNumber}
                        handleChange={handleChange}
                        id="twilio_fromNumber"
                    />
                </Grid>
               </Grid>
               </>
                
            </FormLayout>
 
        </Box>
        </MainContainerWrapper>
        </>
    )
 
}
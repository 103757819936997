import EnhancedTable from "component/tables/selectableTable";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { debounce } from "helpers/debounce";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import { testimonialTableHeader } from "utils/table.utils";
import axios from "axios";
import deleteAlert from "component/HOC/deleteAlert";
import SweetAlertComponent from "component/HOC/Alert";
import { useTranslation } from "react-i18next";

const TestimonialList = () => {
  const { t} = useTranslation();

  const testimonialTableHeader = [
    {
      id: "avatarCompany",
      numeric: false,
      disablePadding: false,
      label: t("Company Name"),
      isSort: false,
    },
  
  
    {
      id: "avatarName",
      numeric: false,
      disablePadding: false,
      label: t("name"),
      isSort: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: t("Action"),
      isSort: false,
    },
  ];
  


  let [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [initailLoad, setInitailLoad] = useState(true);
  const navigate = useNavigate();

  const handleAction = (id: string, action: string) => {
    if (action === "edit") {
      navigate(`/testimonial/edit/${id}`, { state: { actionText: "Edit" } });
      
    } else if (action === "delete") handleDeleteTestimonial(id)
  };
  const handleDeleteTestimonial = (id: string) => {
    deleteAlert({
      title: t("Delete"),
      message: t("Are You sure?"),
      confirmButtonText: t("Yes"),
      onConfirmed: deleteAction,
      data: id,
    });
  };

  const deleteAction = (id: string) => {
    commonService
      .deleteService(`/testimonials/delete/${id}`)
      .then((res: any) => {
        Success(res.data.message);
        handleChangeList();
      })
      .catch((err) => {
        SweetAlertComponent({
          message: errorMessage(err, "Oops! Something went wrong!"),
          title: "Warning",
          onSuccess: () => { },
        });
      });
  };
  const truncateText = (text:string, maxLength:number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + "...";
    }
    return text;
  };
  const handleChangeList = () => {
    if (page === 0) getTableData();
    else setPage(0);
  };

  useEffect(() => {
    getTableData();
  }, [rowsPerPage, page, orderBy, order]);

  const getTableData = () => {
    setIsLoading(true);
    commonService
      .getServiceWithParams(`/testimonials/all-testimonials`, {
        per_page: rowsPerPage,
        page: page + 1,
        search: search ?? "",
        sort_order: order,
        sort_by: orderBy,
      })
      .then((res) => {
        const tableDetails = res?.data?.data?.results.map((item: any) => {
          return {
            id: item?.id,
            avatarName: item?.avatarName,
            avatarCompany: item?.avatarCompany,
            created_at: item?.created_at,
            testimonial: truncateText(item?.testimonial, 25),
          };
        });

        setCount(res?.data.data?.pagination?.total);
        setRows(tableDetails);
        setIsLoading(false);
        setInitailLoad(false);
      })
      .catch((err) => {
        Failed(errorMessage(err, "Something went wrong, please try again later"));
        setIsLoading(false);
      });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "ASC";
    order = isAsc ? "DESC" : "ASC";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
  };

  const searchHandler = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    search = e.target.value;
    setSearch(search);
  };

  useEffect(() => {
    const delay = 500; // Debounce delay in milliseconds
    const performSearch = (text: string) => {
      if (initailLoad) return;
      if (page == 0) getTableData();
      else {
        setPage(0);
      }
    };

    const debouncedSearch = debounce(performSearch, delay);
    const timeoutId = setTimeout(() => debouncedSearch(search), delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);
 
  return (
    <>
      {isLoading && <Loader />}

      <MainContainerWrapper
        headingText={t("Testimonials")}
        isSearch={true}
        search={search}
        searchPlaceHolder={t("Search")}
        handleSearch={searchHandler}
        handleSearchClear={() => setSearch("")}
        isCreateButton={true}
        isBackButton = {true}
        buttonText={t("Add Testimonial")}
        buttonIcon={<span>&#43;</span>}
        handleClick={() => navigate("/testimonial", { state: { actionText: "Create" } })}
      >
        <EnhancedTable
          headCells={testimonialTableHeader}
          rows={rows}
          rowsPerPage={rowsPerPage}
          maxHeightValue={"65vh"}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          isSelectable={false}
          order={order === "ASC" ? "asc" : "desc"}
          orderBy={orderBy}
          isView={false}
          isEdit={true}
          total={count}
          actionFunction={handleAction}
          isDelete={true}
        />
      </MainContainerWrapper>
    </>
  );
};

export default TestimonialList;

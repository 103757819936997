import EnhancedTable from "component/tables/selectableTable";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { debounce } from "helpers/debounce";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import { bankHeadCell } from "utils/table.utils";
import SweetAlertComponent from "component/HOC/Alert";
import deleteAlert from "component/HOC/deleteAlert";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "store/store";
import Bank from "./createBank";
import BankView from "./viewBank";
import { useTranslation } from "react-i18next";

const BankList = ({handlePreTab,isViewList=false}: {handlePreTab: any, isViewList?: boolean}) => {
  const {t}=useTranslation();
  let [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState<any>();
  const [rows, setRows] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [initailLoad, setInitailLoad] = useState(true);
  const navigate = useNavigate();
  const [isAdd, setAdd] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [editBankId, setEditBankId] = useState("");
  const settings = useAppSelector((state)=> state.settings.settings);
  const theme = useTheme();
  const [isView, setView] = useState(false);
  const { locationID } = useAppSelector((state) => state.location);

  const handleAction = (id: string, action: string) => {
    if (action === "edit") {
        setEdit(true);
        setEditBankId(id);
      }  else if (action === "view") {
        setView(true);
        setEditBankId(id);
      } else if (action === "delete") handleDeleteDriver(id);
  };

  const handleDeleteDriver = (id: string) => {
    deleteAlert({
      title: t("Delete"),
      message: t("Are You sure?"),
      confirmButtonText: t("Yes"),
      onConfirmed: deleteAction,
      data: id,
    });
  };

  const deleteAction = (id: string) => {
    commonService
      .deleteService(`/tenant-bank/${id}`)
      .then((res: any) => {
        Success(res.data.message);
        handleChangeList();
      })
      .catch((err) => {
        SweetAlertComponent({
          message: errorMessage(err, "Oops! Something went wrong!"),
          title: "Warning",
          onSuccess: () => { },
        });
      });
  };
  
  const handleChangeList = () => {
    if (page === 0) getTableData();
    else setPage(0);
  };
  
  useEffect(() => {
    getTableData();
  }, [rowsPerPage, page, orderBy, order]);

  const getTableData = () => {
    setIsLoading(true);
    commonService
      .postServiceParams(`/tenant-bank/search`, {
        filter: {
          location: locationID
        }
      }, {
        per_page: rowsPerPage,
        page: page + 1,
        // search: search ?? "",
        sort_order: order,
        sort_by: orderBy,
      })
      .then((res) => {
        console.log(res?.data);
        const tableDetails = res?.data?.data?.results.map((item: any) => {
          return {
            id: item?.id,
            account_no: item?.account_no,
            name_of_account: item?.name_of_account,
            routing_no: item?.routing_no,
            starting_check_no: item?.starting_check_no,
            bank: item?.bank?.values
          };
        });
        setCount(res?.data.data?.pagination?.total);
        setRows(tableDetails);
        setIsLoading(false);
        setInitailLoad(false);
      })
      .catch((err) => {
        setIsLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "ASC";
    order = isAsc ? "DESC" : "ASC";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
  };

  const searchHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    search = e.target.value;
    setSearch(search);
  };

  useEffect(() => {
    const delay = 500; // Debounce delay in milliseconds
    const performSearch = (text: string) => {
      // Perform your search logic here
      if (initailLoad) return;
      if (page == 0) getTableData();
      else {
        setPage(0);
      }
    };
    // if (search.length >= 3 || !search) {
    const debouncedSearch = debounce(performSearch, delay);
    const timeoutId = setTimeout(() => debouncedSearch(search), delay);
    return () => {
      clearTimeout(timeoutId);
    };
    // }
  }, [search]);

  const handleAddDriver = () => {
    setAdd(!isAdd);
  };

  const handleBack = () => {
    setAdd(false);
    setEdit(false);
    setView(false);
    setEditBankId("");
    getTableData();
  };

  return (
    <>
      {isLoading && <Loader />}
      {(!isAdd && !isEdit && !isView) ? (
        <Stack
          sx={{ height: "100%" }}
          direction={"column"}
          justifyContent={"space-between"}
        >
          <Grid container>
            <Grid item md={12} lg={12} xs={12}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                pb={2}
              >
                <Typography variant="h3">List</Typography>
                {!isViewList && <Button variant="contained" onClick={handleAddDriver}
                sx={{
                  backgroundColor: settings?.button_background_color
                  ? settings?.button_background_color
                  : theme.palette.primary.buttonColor,
                "&:hover": {
                  backgroundColor: settings?.button_background_color
                    ? settings?.button_background_color
                    : theme.palette.primary.buttonColor,
                },
                }}
                >
                  Add Bank
                </Button>}
              </Box>
            </Grid>
            <Grid item md={12} lg={12} xs={12}>
            <EnhancedTable
                headCells={bankHeadCell}
                rows={rows}
                rowsPerPage={rowsPerPage}
                maxHeightValue={"70vh"}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleRequestSort={handleRequestSort}
                order="asc"
                orderBy="name"
                isView={true}
                isEdit={!isViewList}
                isDelete={!isViewList}
                total={count}
                actionFunction={handleAction}
                />
            </Grid>
          </Grid>
        </Stack>
      ) : 
      <>
      {(isEdit || isAdd) ?(
        <Bank
            handleBack={handleBack}
            bankID={editBankId}
        />
      ): 
      <BankView
        id={editBankId} 
        handleBack={handleBack}
      />
      }
      </>}
    </>
  );
};

export default BankList;

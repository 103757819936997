import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface LocationType {
  locationID: string | null;
}

const initialState: LocationType = {
  locationID: null,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocationID(state, action: PayloadAction<any>) {
      state.locationID = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    }),
});

export const { setLocationID } = locationSlice.actions;
export default locationSlice.reducer;

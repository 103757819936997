import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IPolicy {
  policy: any;
  isPayment?: boolean;
}

const initialState: IPolicy = {
  policy: {},
  isPayment: false,
};

export const policySlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    setPolicy(state, action: PayloadAction<any>) {
      state.policy = action.payload;
    },
    setPayment(state, action: PayloadAction<any>) {
      state.isPayment = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    }),
});

export const { setPolicy, setPayment } = policySlice.actions;
export default policySlice.reducer;

import { Box, Grid } from '@mui/material'
import FormLayout from 'component/form'
import { InputField, PhoneField, SelectField } from 'forms/hoc/formfield'
import useForm from 'forms/hooks/useForm'
import { createReferenceContactValidation, createReferenceContactValidationSchema } from 'forms/hooks/validateRules'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/store'

function ReferenceContact({ handleNextTab }: ReferenceContactType) {

    const initialValues = {
        contact_name: null,
        phone: null,
        phone_type: null,
        title: null,
    }

    const { referenceID } = useAppSelector((state) => state.reference)

    const { t } = useTranslation();
    const navigate = useNavigate();

    const createReferanceContactT = createReferenceContactValidationSchema(t)

    const formSubmit = () => {

        handleNextTab();
    }



    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
        setUpdateFormValue,
    } = useForm(initialValues, formSubmit, createReferenceContactValidation, createReferanceContactT);



    const handleBack = () => {
        navigate(-1);
    }


    return (
        <>
            <Box sx={{ p: 3, height: "auto", width: "100%" }}>
                <FormLayout
                    handleSubmit={handleSubmit}
                    isBackButton
                    backButtonClick={handleBack}
                    backButtonText={t("Cancel")}
                    isSaveButton>
                    <>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("contact_name")}
                                    values={values?.contact_name || ""}
                                    name="contact_name"
                                    errors={errors?.contact_name}
                                    handleChange={handleChange}
                                    id="contact_name"
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <PhoneField
                                    fieldName={t("phone")}
                                    value={values?.phone || ""}
                                    name="phone"
                                    errors={errors?.phone}
                                    handleChange={handleChange}
                                    id="phone"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectField
                                    fieldName={t("phone_type")}
                                    values={values?.phone_type || ""}
                                    name="phone_type"
                                    errors={errors?.phone_type}
                                    handleChange={handleChange}
                                    options={[] || []}
                                    id="phone_type"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("title")}
                                    values={values?.title || ""}
                                    name="title"
                                    errors={errors?.title}
                                    handleChange={handleChange}
                                    id="title"
                                />
                            </Grid>
                        </Grid>
                    </>
                </FormLayout>

            </Box>
        </>
    )
}

export default ReferenceContact


type ReferenceContactType = {
    handleNextTab: any
}
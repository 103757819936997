import { Box, Tab, Tabs, useTheme } from "@mui/material";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import Loader from "ui-component/Loader";
import { transactionsHistoryHeadCell } from "utils/table.utils";
import { useTranslation } from "react-i18next";

import TransactionHistory from "./TransactionHistory";
import { useAppSelector } from "store/store";
import React from "react";
import { commonService } from "forms/helpers/common.service";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";


export default function Index() {

    const [value, setValue] = React.useState(0);
    const { role } = useAppSelector((state) => state.user.user);
    const [page, setPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [filter, setFilter] = useState<any>(null);
    const [typeList, setTypeList] = useState([]);
    const [location, setLocation] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();


    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);

    };

    const getLocation = async () => {
        try {
            const masterData: any = await commonService.getServices(
                `/location/get-all-location`
            );
            const tableDetails = masterData?.data?.data?.results?.map((item: any) => {
                return {
                    value: item?.id,
                    name: item?.name
                };
            });
            setTypeList(tableDetails);
            if (!filter) {  // Only set filter if it hasn't been manually set yet
                setFilter(tableDetails[0]);  // Set the initial default filter value (first location)
            }
        } catch (error) { }
    };

    const handleFilterChange = (newFilter: any) => {
        setFilter(newFilter);
        setPage(0);
      };
const { t } = useTranslation();

    const navigate = useNavigate();

    return (


        <>
            {isLoading && <Loader />}
            <MainContainerWrapper
                headingText={t("Transaction History")}
                isBackButton
                >
                    <Box>
                        <Tabs
                            id="cheque"
                            onChange={handleTabChange}
                            value={value}
                            aria-label="Tabs where selection follows focus"
                            selectionFollowsFocus
                        >
                            <Tab
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
                                        {t("Card")}
                                    </Box>
                                }
                                value={0} />
                            <Tab
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
                                       {t("ACH")}
                                    </Box>
                                }
                                value={1} />
                        </Tabs>
                        {value === 0 && <TransactionHistory  type={"CARD"}/>}
                        {value === 1 && <TransactionHistory type={"ACH"}/>}
                    </Box>
                </MainContainerWrapper>
        </>
    )
}

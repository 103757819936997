import { Box, Grid } from '@mui/material'
import FormLayout from 'component/form'
import Loading from 'container/loading'
import { commonService } from 'forms/helpers/common.service'
import { Failed, Success } from 'forms/helpers/toast.helper'
import { InputField, PhoneField } from 'forms/hoc/formfield'
import { parsePhoneNumber } from 'forms/hooks/customHook'
import useForm from 'forms/hooks/useForm'
import { createFinanceCompanyValidation, createFinanceCompanyValidationSchema } from 'forms/hooks/validateRules'
import { t } from 'i18next'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { setBlocker } from 'store/slice/blocker.slice'

function FinanceCompanyCreate() {

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { id } = useParams();

    const initialValues = {
        name: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        zip_code: null,
        phone: null,
        fax: null,
        email: null,
        contact_name: null,
        account: null,
        website: null,
    };

    useEffect(() => {
        if (id) getFinanceCompanyData()
    }, [])

    const getFinanceCompanyData = () => {
        commonService.getServices(`/finance-company/${id}`).
            then((res: any) => {
                setPatchValue(res?.data?.data)
            })
            .catch((err: any) => { })
    }



    const formSubmit = () => {

        setLoading(true);
        const PhoneData = parsePhoneNumber(values.phone)
        console.log("phpoeeeee",values.phone);
        
        const api = id ? `/finance-company/${id}` : "/finance-company";
        const bodyData = id
            ? {
                ...values,
            }
            : {
                ...values,
            };

        commonService
            .postPutService(api, bodyData, id ? "patch" : "post")
            .then((res) => {
                Success(res?.data?.message);
                setLoading(false);
                handleBack();
            })
            .catch((err) => {
                setLoading(false);
                Failed("Something went wrong, please try again later");
            });
    };


    const { t } = useTranslation();
    const createFinanceCompanyT = createFinanceCompanyValidationSchema(t)


    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
    } = useForm(initialValues, formSubmit, createFinanceCompanyValidation, createFinanceCompanyT);


    const handleBack = () => {
        navigate(-1);
    };

    return (
        <MainContainerWrapper
            headingText = { !id ? t('Finance Company Create') : t('Finance Company Edit')}
        >
            <>
                {loading && <Loading />}
                <Box sx={{ p: 3, height: "80vh", width: "100%" }}>
                    <FormLayout
                        handleSubmit={handleSubmit}
                        isBackButton
                        backButtonClick={handleBack}
                        backButtonText={t("Cancel")}
                        isSaveButton
                    >
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("Name")}
                                    values={values?.name || ""}
                                    name="name"
                                    errors={errors?.name}
                                    handleChange={handleChange}
                                    id="name"
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("address_line_1")}
                                    values={values?.address_line_1 || ""}
                                    name="address_line_1"
                                    errors={errors?.address_line_1}
                                    handleChange={handleChange}
                                    id="address_line_1"
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("address_line_2")}
                                    values={values?.address_line_2 || ""}
                                    name="address_line_2"
                                    errors={errors?.address_line_2}
                                    handleChange={handleChange}
                                    id="address_line_2"
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("city")}
                                    values={values?.city || ""}
                                    name="city"
                                    errors={errors?.city}
                                    handleChange={handleChange}
                                    id="city"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("zip_code")}
                                    values={values?.zip_code || ""}
                                    name="zip_code"
                                    errors={errors?.zip_code}
                                    handleChange={handleChange}
                                    id="zip_code"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <PhoneField
                                    fieldName={t("phone")}
                                    value={values?.phone || ""}
                                    name="phone"
                                    errors={errors?.phone}
                                    handleChange={handleChange}
                                    id="phone"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("fax")}
                                    values={values?.fax || ""}
                                    name="fax"
                                    errors={errors?.fax}
                                    handleChange={handleChange}
                                    id="fax"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("email")}
                                    values={values?.email || ""}
                                    name="email"
                                    errors={errors?.email}
                                    handleChange={handleChange}
                                    id="email"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("contact_name")}
                                    values={values?.contact_name || ""}
                                    name="contact_name"
                                    errors={errors?.contact_name}
                                    handleChange={handleChange}
                                    id="contact_name"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("account")}
                                    values={values?.account || ""}
                                    name="account"
                                    errors={errors?.account}
                                    handleChange={handleChange}
                                    id="account"
                                    isRequired={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <InputField
                                    fieldName={t("website")}
                                    values={values?.website || ""}
                                    name="website"
                                    errors={errors?.website}
                                    handleChange={handleChange}
                                    id="website"
                                    isRequired={false}
                                />
                            </Grid>
                        </Grid>
                    </FormLayout>

                </Box>
            </>
        </MainContainerWrapper>
    )
}

export default FinanceCompanyCreate

function dispatch(arg0: any) {
    throw new Error('Function not implemented.')
}

import { Box, Grid } from "@mui/material";
import FormLayout from "component/form";
import ModalComponent from "component/modal";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import { AutoCompleteField, GroupRadioButton, InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import {
  CustomerPolicyValidation,
  CustomerPolicyValidationSchema,
  importModalValidation,
} from "forms/hooks/validateRules";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { useTranslation } from "react-i18next";

const CustomerPolicyLocation = ({
  open,
  handleClose,
  handleFormSubmit,
  isEdit,
  isEditId
}: ImportModalTypes) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [locationList, setLocationList] = useState([]);
  const { locationID } = useAppSelector((state) => state.location);

  const initialValues = {
    policy_number: "",
    location: "",
  };


  console.log("dddddddddddddd", isEdit, isEditId);

  const formSubmit = () => {
    console.log("Form is submitting...");
    setLoading(true);
    console.log("Form Submitted with values:", values);

    const bodydata =
      isEdit === true ? {
        ...values,
        id: isEditId,
        location: values?.location?.value,
        policy_number: values.policy_number,
        due_amount: values.due_amount,
        
      } : {
        ...values,
        location: values?.location?.value,
        policy_number: values.policy_number,
        due_amount: values.due_amount,
        
      };
    console.log("Body data:", bodydata);


    const api = isEdit === true ? `excel-policy/${isEditId}` : `excel-policy`
    const method = isEdit === true ? "patch" : "post"


    console.log("editApi", api);
    console.log("createApi", api);


    commonService
      .postPutService(api, bodydata, method)
      .then((res) => {
        setLoading(false);
        handleFormSubmit();
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };

  console.log("jsjsjsjsjsjjjsjsns", isEditId);

  const getLocationList = async () => {
    setLoading(true);
    commonService
      .getServices("/location/get-all-location")
      .then((res) => {

        const loc = res?.data?.data?.results?.map((i: any) => {
          return {
            name: i.name,
            value: i.id
          }
        })
        setLocationList(loc);
        setLoading(false);
      })
      .catch((err) => {
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
    console.log("jcbjc", locationList);
  };

  const getCustomerPolicyData = () => {

    commonService.getServices(`excel-policy/${isEditId}`)
      .then((res: any) => {
        console.log("ressss", res?.data?.data?.location?.id);

        const CustomerPolicyData = {
          policy_number: res?.data?.data?.policy_number,
          location: {
            name:res?.data?.data?.location.name,
            value : res?.data?.data?.location?.id
          },
          due_amount: res?.data?.data?.due_amount,
          broker_fee: res?.data?.data?.broker_fee,
          who_has_to_pay_the_transaction_fee:res?.data?.data?.who_has_to_pay_the_transaction_fee,
        }
        setPatchValue(CustomerPolicyData)
      })
  }
  useEffect(() => {
    getLocationList();
    if (isEdit) {
      getCustomerPolicyData();
    }
  }, []);


  const { t, i18n } = useTranslation();
  const policyLocationT = CustomerPolicyValidationSchema(t);
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setPatchValue,
    setUpdateValue,
    setUpdateFormValue,
  } = useForm(initialValues, formSubmit, CustomerPolicyValidation, policyLocationT);


  // const handleBlurchange = () => {
  //   let finalAmt = values.due_amount
  //   values.due_amount = parseFloat(finalAmt).toFixed(2);
  // }
 
  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      customeStyle={{ width: "42%" }}
    >
      <Box>
        {loading && <Loading />}
        <FormLayout
          handleSubmit={handleSubmit}
          backButtonText={t("Cancel")}
          backButtonClick={handleClose}
          isBackButton
          scrollable="hidden"
          submitButtonText={t("Save")}
        >
          <Grid container flexGrow={1} columnSpacing={2}>
            <Grid item xs={12} pb={3} lg={6}>
              <InputField
                fieldName={t("Policy Number")}
                fontSize={i18n?.language === "es" ? "0.9rem" : ""}
                values={values?.policy_number}
                name="policy_number"
                errors={errors?.policy_number}
                handleChange={handleChange}
                id="policy_number"
                disabled={isEdit ? true : false}
              />
            </Grid>
            <Grid item xs={12} pb={3} lg={6}>
              <AutoCompleteField
                fieldName={t("Location")}
                // fontSize={i18n?.language === "es" ? "0.9rem":""}
                values={values.location || null}
                name="location"
                errors={errors.location}
                handleChange={(loc: any) =>
                  setUpdateFormValue("location", loc)
                }
                options={locationList}
                id="location"
              disabled={isEdit ? true : false}
              />
            </Grid>
            <Grid item xs={12} pb={3} lg={6}>
              <InputField
                fieldName={t("Due Amount")}
                fontSize={i18n?.language === "es" ? "0.9rem" : ""}
                values={values?.due_amount}
                name="due_amount"
                errors={errors?.due_amount}
                handleChange={handleChange}
                id="due_amount"
                adorament="startAdornment"
                adoramentText="$"
                // onBlur={handleBlurchange}
                onlyDecimalNumber={true}
              />
            </Grid>
            <Grid item xs={12} pb={3} lg={6}>
              <InputField
                fieldName={t("Broker Fee")}
                fontSize={i18n?.language === "es" ? "0.9rem" : ""}
                values={values?.broker_fee}
                name="broker_fee"
                errors={errors?.broker_fee}
                handleChange={handleChange}
                id="broker_fee"
                isRequired={true}
                adorament="startAdornment"
                adoramentText="$"
                onlyDecimalNumber={true}
                
              />
            </Grid>
            <Grid item xs={12} pb={3}>
              <GroupRadioButton
                isRow={true}
                fieldName={t("Who has to pay the Transaction Fee?")}
                values={values?.who_has_to_pay_the_transaction_fee || ""}
                name="who_has_to_pay_the_transaction_fee"
                handleChange={
                  handleChange
                }
                errors={errors?.who_has_to_pay_the_transaction_fee}
                options={[
                  { value: "driver", name: "Driver" },
                  { value: "broker", name: "Broker" },
                  
                ] || []}
                id="who_has_to_pay_the_transaction_fee"          
              />
            </Grid>
          </Grid>
        </FormLayout>
      </Box>
    </ModalComponent>
  );
};

type ImportModalTypes = {
  open: boolean;
  initValues?: any;
  handleClose: any;
  handleFormSubmit: any;
  isEdit?: boolean;
  isEditId?: string;
};

export default CustomerPolicyLocation;

import { Stack } from '@mui/material'
import TabComponent from 'component/tabs'
import Loading from 'container/loading'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/store'
import AgencyInformation from './AgencyInformation'
import ReferenceContact from './ReferenceContact'
import ReferenceNotes from './ReferenceNotes'
import ReferenceDocument from './ReferenceDocument'

function ReferecnceIndex() {

    const [tab, setTab] = useState<number>(0);
    const auth = useAppSelector((state) => state.user.user);
    const [loading, setLoading] = useState(false);
    // const {referenceID } = useAppSelector()


    const { t } = useTranslation();

    // useEffect(() => {
    //     if ("locationID") {
    //         tabList = tabs.map((t: any) => {
    //             return {
    //                 ...t,
    //                 disabled: false
    //             }
    //         });
    //         setTabList(tabList);
    //     }
    // }, ["locationID"])


    const handleNextTab = () => {
        setTab((next:number) => next + 1)
    }


    const handlePreTab = () => {
        setTab((prev: number) => prev - 1)
    }

    const tabs =
        [
            {
                label: t("Agency Information"),
                value: 0,
                Component: (
                    <AgencyInformation handleNextTab={handleNextTab}/>
                ),
                disabled: false,
            },
            {
                label: t("Contacts"),
                value: 1,
                Component: (
                    <ReferenceContact handleNextTab={handleNextTab}/>
                ),
                disabled: true,
            },
            {
                label: t("Notes"),
                value: 2,
                Component: (
                    <ReferenceNotes  handleNextTab={handleNextTab} />
                ),
                disabled: true,
            },
            {
                label: t("Documents"),
                value: 3,
                Component: (
                    <ReferenceDocument handleNextTab={handleNextTab}/>
                ),
                disabled: true,
            },
        ];
    let [tabList, setTabList] = useState<any>(tabs);
    return (
        <>
            <MainContainerWrapper headingText={"Create Reference"} isBackButton={true}>
                <Stack flexGrow={1}>
                    {loading ?
                        <Loading /> :
                        <TabComponent
                            handleChange={(e: any, value: number) => setTab(value)}
                            value={tab}
                            tabs={tabList}
                        />}
                </Stack>
            </MainContainerWrapper>
        </>
    )
}

export default ReferecnceIndex
import EnhancedTable from "component/tables/selectableTable";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { useDebounce } from "forms/hooks/useDebounce";
import { debounce } from "helpers/debounce";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import { remaindersHeaders } from "utils/table.utils";
import { saveAs } from "file-saver";
import axios from "axios";
import { parsePhoneNumberformat } from "forms/hooks/customHook";
import deleteAlert from "component/HOC/deleteAlert";
import SweetAlertComponent from "component/HOC/Alert";
import { t } from "i18next";

const Remainder = () => {

  const remaindersHeaders = [
    {
      id: "remainder_name",
      numeric: false,
      disablePadding: true,
      label: t("Remainder Name"),
      isSort: true,
      isBold: true,
    },
    {
      id: "no_of_days",
      numeric: true,
      disablePadding: true,
      label: t("No of Days"),
      isSort: true,
      isBold: true,
    },
    {
      id: "heading",
      numeric: false,
      disablePadding: true,
      label: t("Heading"),
      isSort: true,
      isBold: true,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: t("type"),
      isSort: true,
      isBold: true,
    },
    {
      id: "is_active",
      numeric: false,
      disablePadding: false,
      label: t("Active"),
      isSort: true,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: t("Action"),
      isSort: false,
    },
  ]



  let [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState<any>();
  const [rows, setRows] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [initailLoad, setInitailLoad] = useState(true);
  const [actionType, setActionType] = useState('Create');
  let [actionData, setActionData] = useState<any>(null);
  const navigate = useNavigate();

  const handleAction = (id: string, action: string) => {
    if (action === "edit") {
      // setActionType("Edit")
      // setActionData(id)
      // setOpen(true)
      navigate(`/settings/reminder/edit/${id}`, { state: { actionText: "Edit" } });
    } else if (action === "delete") {
      handleDelete(id)
    }else if(action === "view"){
      navigate(`/settings/reminder/view/${id}`, { state: { actionText: "View" } });
    }
  };
  useEffect(() => {
    getTableData();
  }, [rowsPerPage, page, orderBy, order]);

  const handleChangeList = () => {
    if (page === 0) getTableData();
    else setPage(0);
  };

  const handleStatus = (id: string, checked: boolean) => {
    deleteAlert({
      title: checked ? t("Activate") : t("Deactivate"),
      message: t("Are You sure?"),
      confirmButtonText: t("Yes"),
      onConfirmed: setActive,
      data: { id, checked },
    });
  };

  const handleDelete = (id: string) => {
    deleteAlert({
      title: t("Delete"),
      message: t("Are You sure?"),
      confirmButtonText: t("Yes"),
      onConfirmed: deleteAction,
      data: id,
    });
  };

  const deleteAction = (id: string) => {
    commonService
      .deleteService(`/invoice-reminder/${id}`)
      .then((res: any) => {
        Success(res.data.message);
        handleChangeList();
      })
      .catch((err) => {
        SweetAlertComponent({
          message: errorMessage(err, "Oops! Something went wrong!"),
          title: "Warning",
          onSuccess: () => { },
        });
      });
  };

  const setActive = (data: { id: string; checked: boolean }) => {
    setIsLoading(true);
    commonService
      .patchService(`invoice-reminder/active-inactive/${data.id}`, { is_active: data.checked })
      .then((res: any) => {
        Success(res.data.message);
        handleChangeList();
      })
      .catch((err) => {
        console.log(err);
        Failed(
          errorMessage(
            err,
            err?.response?.data?.error ?? "Oops! Something went wrong!"
          )
        );
        setIsLoading(false);
      });
  };


  const getTableData = () => {
    setIsLoading(true);
    commonService
      .getServiceWithParams(`/invoice-reminder`, {
        per_page: rowsPerPage,
        page: page + 1,
        search: search ?? "",
        search_by: "remainder_name,heading,custom_message,type",
        sort_order: order,
        sort_by: orderBy,
      })
      .then((res) => {
        const tableDetails = res?.data?.data?.records.map((item: any) => {
          return {
            id: item?.id,
            remainder_name: item?.remainder_name,
            no_of_days: item?.no_of_days,
            heading: item?.heading,
            custom_message: item?.custom_message.replace(/(<([^>]+)>)/ig, ''),
            type: item?.type,
            is_active: item?.is_active
          };
        });
        console.log('tableDetails)', tableDetails)

        setCount(res?.data.data?.total);
        setRows(tableDetails);
        setIsLoading(false);
        setInitailLoad(false);
      })
      .catch((err) => {
        ;
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "ASC";
    order = isAsc ? "DESC" : "ASC";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
  };

  const searchHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    search = e.target.value;
    setSearch(search);
  };

  useEffect(() => {
    const delay = 500; // Debounce delay in milliseconds
    const performSearch = (text: string) => {
      // Perform your search logic here
      if (initailLoad) return;
      if (page == 0) getTableData();
      else {
        setPage(0);
      }
    };
    // if (search.length >= 3 || !search) {
    const debouncedSearch = debounce(performSearch, delay);
    const timeoutId = setTimeout(() => debouncedSearch(search), delay);
    return () => {
      clearTimeout(timeoutId);
    };
    // }
  }, [search]);


  const handleSuccess = () => {
    handleChangeList();
    setOpen(false);
  }
  const [open, setOpen] = useState(false);

  return (
    <>
      {isLoading && <Loader />}
      <MainContainerWrapper
        headingText={t("Reminders")}
        isSearch={true}
        search={search}
        searchPlaceHolder={t("Search")}
        handleSearch={searchHandler}
        handleSearchClear={() => setSearch("")}
        isBackButton = {true}
        isCreateButton={true}
        buttonText={t("Add Reminder")}
        buttonIcon={<span>&#43;</span>}
        handleClick={() => {
          // setActionType("Create");
          // setOpen(true);
          navigate(`/settings/reminder/create`, { state: { actionText: "Create" } });
        }}
      >
        <EnhancedTable
          headCells={remaindersHeaders}
          rows={rows}
          rowsPerPage={rowsPerPage}
          maxHeightValue={"65vh"}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          isSelectable={false}
          order={order === "ASC" ? "asc" : "desc"}
          orderBy={orderBy}
          isView={true}
          isEdit={true}
          isDelete={true}
          total={count}
          actionFunction={handleAction}
          setActive={handleStatus}
        />
      </MainContainerWrapper>
    </>
  );
};

export default Remainder;

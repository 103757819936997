import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import FormLayout from "component/form";
import ModalComponent from "component/modal";
import { commonService } from "forms/helpers/common.service";
import { InputField, PhoneField, SelectField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import {
  AddressModalValidation,
  PhoneModalValidation,
  addressModalSchema,
} from "forms/hooks/validateRules";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AddressModal = ({
  open,
  initValues,
  handleClose,
  handleFormSubmit,
  type,
}: AddressModalTypes) => {
  const [stateoptions, setStateoptions] = useState<any>();
  const [addressoptions, setAddressoptions] = useState<any>();
  const { t } = useTranslation();

  const initialValues = {
    address_type: null,
    address_line_1: null,
    address_line_2: null,
    state: null,
    statename: null,
    is_default: 0,
    city: null,
    zip_code: null,
  };

  const formSubmit = () => {
    const statename = stateoptions.find(
      (item: any) => item.value === values?.state
    );
    const addressType = addressoptions.find((address: any) => address.value === values?.address_type);
    const updatedValues = {
      ...values,
      state: statename?.value,
      statename: statename?.name,
      addressType: addressType.name,
    };
    handleFormSubmit(updatedValues, type);
  };
  useEffect(() => {
    getState();
    getMaster();
    if (initValues?.id) {
      setPatchValue(initValues);
    }
  }, []);

  const getState = () => {
    commonService
      .getServices(`/state`)
      .then((res) => {
        console.log(res?.data?.data);
        setStateoptions(res?.data?.data);
      })
      .catch((err) => {
        ;
      });
  };
  function getMaster() {
    let params = new URLSearchParams();
    params.append("types", `Address Type`);

    // var request = {
    //   params: params,
    // };
    commonService
      .getServices(`/master?${params.toString()}`)
      .then((res) => {
        console.log(res?.data?.data);

        const addressObject = res?.data?.data["Address Type"];
        if (addressObject) {
          setAddressoptions(addressObject);
        }

        console.log(addressObject);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  const importModalT = addressModalSchema(t)
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setPatchValue,
    setUpdateValue,
  } = useForm(initialValues, formSubmit, AddressModalValidation,importModalT );
  console.log(values);

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      customeStyle={{ width: "50%" }}
    >
      
      <Box>
          <Box sx={{textAlign:"center",mb:1}}>
                <Typography variant="h4">
                    {t("add_address")}
                </Typography>
          </Box>
        <FormLayout
          handleSubmit={handleSubmit}
          backButtonText={t("cancel")}
          backButtonClick={handleClose}
          isBackButton
          scrollable="hidden"
          submitButtonText={t("save")}
        >
          <Grid container flexGrow={1} columnSpacing={2}>
            <Grid item lg={6} pb={3}>
              <SelectField
                fieldName={t("address_type")}
                name="address_type"
                id="address_type"
                options={addressoptions}
                errors={errors?.address_type}
                handleChange={handleChange}
                values={values?.address_type}
              />
            </Grid>
            <Grid item lg={6}>
              <InputField
                fieldName={t("address_line_one")}
                values={values?.address_line_1 || ""}
                name="address_line_1"
                errors={errors?.address_line_1}
                handleChange={handleChange}
                id="address_line_1"
              />
            </Grid>
            <Grid item lg={6}>
              <InputField
                fieldName={t("address_line_two")}
                values={values?.address_line_2 || ""}
                name="address_line_2"
                isRequired={false}
                errors={errors?.address_line_2}
                handleChange={handleChange}
                id="address_line_2"
              />
            </Grid>
            <Grid item lg={6} pb={3}>
              <SelectField
                fieldName={t("state")}
                name="state"
                id="state"
                options={stateoptions}
                errors={errors?.state}
                handleChange={handleChange}
                values={values?.state}
              />
            </Grid>
            <Grid item lg={6} pb={3}>
              <InputField
                fieldName={t("city")}
                values={values?.city || ""}
                name="city"
                errors={errors?.city}
                handleChange={handleChange}
                id="city"
              />
            </Grid>
            <Grid item lg={6} pb={3}>
              <InputField
                fieldName={t("zip_code")}
                values={values?.zip_code || ""}
                name="zip_code"
                errors={errors?.zip_code}
                handleChange={handleChange}
                id="zip_code"
              />
            </Grid>
            <Grid item lg={12} pb={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={Boolean(values?.is_default)}
                    onChange={(e) =>
                      setUpdateValue("is_default", Boolean(e.target.checked))
                    }
                    name="is_default"
                  />
                }
                label={t("set_as_default")}
              />
            </Grid>
          </Grid>
        </FormLayout>
      </Box>
    </ModalComponent>
  );
};

type AddressModalTypes = {
  open: boolean;
  initValues?: any;
  handleClose: any;
  handleFormSubmit: any;
  type?: string;
};

export default AddressModal;

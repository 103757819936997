import { Box, Button, Typography } from "@mui/material";
import ModalComponent from "component/modal";
import EnhancedTable from "component/tables/selectableTable";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import { useEffect, useState } from "react";
import {
  VehiclePolicyHeadCell,
  customerVehicleViewHeadCell,
} from "utils/table.utils";
import { useNavigate } from "react-router-dom";
import { commonService } from "forms/helpers/common.service";
import Loading from "container/loading";
import CloseIcon from "@mui/icons-material/Close";

const VehiclePolicyListModel = ({
  open,
  handleClose,
}: VehiclePolicyListModelTypes) => {
  const [rows, setRows] = useState<any>();
  const [initailLoad, setInitailLoad] = useState(true);
  const [isLoading, setIsLoading] = useState<any>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getTableData();
  }, []);

  const handleAction = (id: string, action: string) => {
    if (action === "view") {
      navigate(`/customers/policy/view/${id}`);
    }
  };

  const getTableData = () => {
    setIsLoading(true);
    commonService
      .getServiceWithParams(`/customer/customerVehicle`, {})
      .then((res) => {
        console.log("Result: ", res?.data?.data?.results[0].policy);
        const tableDetails = res?.data?.data?.results[0].policy.map(
          (item: any) => {
            return {
              id: item?.id,
              policyId: item?.id,
              policy_number1: item?.policy_number,
              effective_date: item?.effective_date,
              expiration_date: item?.expiration_date,
              policy_status: item?.policy_status,
              insurance_company: item?.insurance_company,
            };
          }
        );
        setRows(tableDetails);
        setIsLoading(false);
        setInitailLoad(false);
      })
      .catch((err) => {
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        customeStyle={{ width: "635" }}
      >
        <>
          <Box
            sx={{
              padding: 2,
            }}
          >
            <EnhancedTable
              headCells={VehiclePolicyHeadCell}
              rows={rows}
              //   maxHeightValue={"100%"}
              actionFunction={handleAction}
              isView={true}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 4 }}>
              <Button onClick={handleClose}>Back</Button>
            </Box>
          </Box>
        </>
      </ModalComponent>
    </>
  );
};

type VehiclePolicyListModelTypes = {
  open: boolean;
  handleClose: any;
};

export default VehiclePolicyListModel;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { quoteInitailValue } from "utils/constant.utils";

interface ICustomer {
  first_name: string | null;
  last_name: string | null;
  gender: string | null;
  dob: string | null;
  marital_status: string | null;
  email: string | null;
  id?: string | null;
  prefix: string | null;
  middle_name: string | null;
  suffix: string | null;
  social_security: string | null;
  county_code: string | null;
  mobile: string | null;
  business_type: string | null;
  website: string | null;
  license_number: string | null;
  license_state: string | null;
  license_issue_date: string | null;
  license_exp_date: string | null;
  address_type: string | null;
  address_line_one: string | null;
  address_line_two: string | null;
  state: string | null;
  city: string | null;
  zipcode: string | null;
  emails?: any[];
  phones?: any[]
}

interface IQuote {
  id: null | string;
  customer: ICustomer;
  policy: any;
  driver: any;
  vehicle: any;
  documents: any;
  tax: any;
}

const initialState: IQuote = quoteInitailValue;

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setQuoteQuote(state, action: PayloadAction<any>) {
      state.id = action.payload;
    },
    setQuoteCustomer(state, action: PayloadAction<any>) {
      state.customer = action.payload;
    },
    setQuotePolicy(state, action: PayloadAction<any>) {
      state.policy = action.payload;
    },
    setQuoteDriver(state, action: PayloadAction<any>) {
      state.driver = action.payload;
    },
    setQuoteVehicle(state, action: PayloadAction<any>) {
      state.vehicle = action.payload;
    },
    setQuoteDocument(state, action: PayloadAction<any>) {
      state.documents = action.payload;
    },
    setQuoteTax(state, action: PayloadAction<any>) {
      state.tax = action.payload;
    },
    setInitialValueDocument(state, action: PayloadAction<any>) {
      state.id = "";
      state.customer = action.payload?.customer;
      state.driver = action.payload?.driver;
      state.documents = action.payload?.documents;
      state.vehicle = action.payload?.vehicle;
      state.policy = action.payload?.policy;
      state.tax = action.payload?.tax;
    },
  },
  extraReducers: (builder) =>
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    }),
});

export const {
  setQuoteCustomer,
  setQuoteDocument,
  setQuoteDriver,
  setQuoteVehicle,
  setQuotePolicy,
  setQuoteQuote,
  setInitialValueDocument,
  setQuoteTax
} = quoteSlice.actions;
export default quoteSlice.reducer;

import { Typography } from "@mui/material";
import EnhancedTable from "component/tables/selectableTable";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import Loader from "ui-component/Loader";
import { locationHeadCell } from "utils/table.utils";
import deleteAlert from "component/HOC/deleteAlert";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import SweetAlertComponent from "component/HOC/Alert";
import { errorMessage } from "forms/helpers/function.helper";
import { useTranslation } from "react-i18next";

export default function Location() {

  const { t} = useTranslation();

  const locationHeadCell = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("Location Name"),
    },
    {
      id: "code",
      numeric: false,
      disablePadding: false,
      label: t("Location Code"),
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: t("Action"),
    },
  ]

  let [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState<any>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [count, setCount] = useState<any>();
  const [rows, setRows] = useState<any>();
  const [initailLoad, setInitailLoad] = useState(true);
  const navigate = useNavigate();

  const handleAction = (id: string, action: string) => {
    console.log("iddd", id, "Action", action);
    if (action === "view") {
      navigate(`/settings/location/view/${id}`, { state: { actionText: "View" } });
    } else if (action === "edit") {
      navigate(`/settings/location/edit/${id}`, { state: { actionText: "Edit" } });
    } else if (action === "delete") {
      handleDeleteLocation(id)
    }
  };

  const handleDeleteLocation = (id: string) => {
    deleteAlert({
      title: t("Delete"),
      message: t("Are You sure?"),
      confirmButtonText: t("Yes"),
      onConfirmed: deleteAction,
      data: id,
    });
  };

  const deleteAction = (id: string) => {
    commonService
      .deleteService(`/location/${id}`)
      .then((res: any) => {
        Success(res.data.message);
        handleChangeList()
      })
      .catch((err) => {
        SweetAlertComponent({
          message: errorMessage(err, "Oops! Something went wrong!"),
          title: "Warning",
          onSuccess: () => {},
        });
      });
  };

  const handleChangeList = () =>{
    if (page === 0) getTableData();
    else setPage(0);
  }

  useEffect(() => {
    getTableData();
  }, [rowsPerPage, page, orderBy, order]);

  const getTableData = () => {
    setIsLoading(true);
    commonService
      .getServiceWithParams(`/location/get-all-location`, {
        per_page: rowsPerPage,
        page: page + 1,
        search: search ?? "",
        sort_order: order,
        sort_by: orderBy,
      })
      .then((res) => {        
        const tableDetails = res?.data?.data?.results?.map((item: any) => {
          return {
            id: item?.id,
            name: item?.name,
            code: item?.code,
          };
        });

        setCount(res?.data.data?.pagination?.total);
        setRows(tableDetails);
        setIsLoading(false);
        setInitailLoad(false);
      })
      .catch((err) => {
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
        setIsLoading(false);
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "ASC";
    order = isAsc ? "DESC" : "ASC";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
  };

  const searchHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    search = e.target.value;
    setSearch(search);
  };
 

  return (
    <>
      {isLoading && <Loader />}
      <MainContainerWrapper
        headingText={t("Location")}
        // isSearch={true}
        isCreateButton={true}
        isBackButton={true}
        buttonText={t("Add Location")}
        buttonIcon={<span>&#43;</span>}
        handleClick={() => navigate("/settings/location/create")}
      >
        <EnhancedTable
          headCells={locationHeadCell}
          rows={rows}
          rowsPerPage={rowsPerPage}
          maxHeightValue={"65vh"}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          isSelectable={false}
          order={order === "ASC" ? "asc" : "desc"}
          orderBy={orderBy}
          isView={true}
          isEdit={true}
          isDelete={true}
          total={count}
          actionFunction={handleAction}
        />
      </MainContainerWrapper>
    </>
  );
}

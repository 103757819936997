const Employee = {
  id: `dashboard`,
  title: `Dashboard`,
  type: `group`,
  children: [
    {
      id: `default`,
      title: `Dashboard`,
      type: `item`,
      url: `/dashboard`,
      icon: `gridView`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/dashboard`],
    },
    {
      id: `quotations`,
      title: `Leads`,
      type: `item`,
      url: `/quotes`,
      icon: `quote`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/quotes`, `/quote/view`, `/quote/create`, `/quote/edit`],
    },
    {
      id: `Policy`,
      title: `Policies`,
      type: `item`,
      url: `/policy`,
      icon: `policy`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/policy`],
    },
    {
      id: `Customers`,
      title: `Customers`,
      type: `item`,
      url: `/customers`,
      icon: `group`,
      breadcrumbs: false,
      href: false,
      activeItem: [
        `/customers`,
        "/customers/create",
        "/customers/view",
        "/customers/policy/create",
        "/customers/policy/view",
        "/customers/edit",
        "/customers/policy/edit",
      ],
    },
    {
      id: `Cheque`,
      title: `Check`,
      type: `item`,
      url: `/chequelist`,
      icon: `check`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/chequelist`, `/cheque/edit`, `/cheque/view`],
    },
    {
      id:`documents`,
      title: `documents`,
      type: `item`,
      url: `/all-documents`,
      icon: `documents`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/all-documents`],
    },

  ],
};

export default Employee;

import { Stack } from '@mui/material';
import TabComponent from 'component/tabs';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import { useEffect, useState } from 'react'
import PaymentConfig from './PaymentConfig';
import LocationCreate from './LocationCreate';
import BankList from './List';
import { useParams } from 'react-router-dom';
import { setLocationID } from 'store/slice/location.slice';
import { useAppDispatch, useAppSelector } from 'store/store';
import Loading from 'container/loading';
import { useTranslation } from "react-i18next";

const Location = () => {
    const [tab, setTab] = useState<number>(0);
    const auth = useAppSelector((state) => state.user.user);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const { locationID } = useAppSelector((state) => state.location);
    
    const handleNextTab = () => {
      setTab((pre: number) => pre + 1);
    };
  
    const handlePreTab = () => {
      setTab((pre: number) => pre - 1);
    };
    const params = useParams();
    useEffect(() => {
      if(params?.id) {
        dispatch(setLocationID(params?.id));
      }
      setTimeout(() => {
        setLoading(false);
      }, 0);
      return() => {
        dispatch(setLocationID(null));
      }
    }, []);
    const { t } = useTranslation();
    useEffect(() => {
      if(locationID) {
        tabList = tabs.map((t: any) => {
          return {
            ...t,
            disabled: false
          }
        });
        setTabList(tabList);
      }
    }, [locationID])
    const tabs = 
    [
      {
        label: t("Location"),
        value: 0,
        Component: (
          <LocationCreate handleNextTab={handleNextTab}/>
        ),
        disabled: false,
      },
      {
        label: t("Payment Config"),
        value: 1,
        Component: <PaymentConfig handleNextTab={handleNextTab} handlePreTab={handlePreTab} />,
        disabled: true,
      },

      auth?.plan?.is_free === 0  ?
      {
        label: "Banks",
        value: 2,
        Component: <BankList handlePreTab={handlePreTab} />,
        disabled: true,
      } : {}
    ];
    let [tabList, setTabList] = useState<any>(tabs);
  return (
    <MainContainerWrapper headingText={"Location"} isBackButton = {true}>
      <Stack flexGrow={1}>
        {loading ? 
        <Loading /> :
        <TabComponent
          handleChange={(e: any, value: number) => setTab(value)}
          value={tab}
          tabs={tabList}
        />}
      </Stack>
  </MainContainerWrapper>
  )
}

export default Location;
import Pagenotfound from "../../asset/images/404.png";
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { Box, Toolbar } from '@mui/material';
import Logo from 'ui-component/Logo';
import Loading from "container/loading";

const NotFound = () => {
  return (
    <div>
      <div className='page-not-found'>
        
        <img 
        src= {Pagenotfound}
        alt={"page not found"}
        />
        <h1>Domain you are accessing not found</h1>
      </div>
    </div>
  )
}

export default NotFound
import { Box, Grid, Typography } from "@mui/material";
import { commonService } from "forms/helpers/common.service";
import TextView from "forms/hoc/text-view";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "ui-component/Loader"; 
import { useTranslation } from "react-i18next";
 

const LocationView = () => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const [locationData, setLocationData] = useState<any>({});
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getLocationData();
    }
  }, []);

   const getLocationData = () => {
    setIsloading(true);
    commonService
      .getServices(`/location/${id}`)
      .then((res) => {       
        console.log("Location data",res);
        const locationData = {
          name: res.data.data?.name,
          code: res.data.data?.code,
          location_url: res.data.data?.location_url,
          address_line_one: res.data.data?.address_line_one,
          address_line_two: res.data.data?.address_line_two,
          state: res.data.data?.state?.name,
          city: res.data.data?.city,
          zip_code: res.data.data?.zip_code,
        }
        setLocationData(locationData);
        setIsloading(false);
      })

  }

  return (
    <>
      {isLoading && <Loader />}
      <Grid container sx={{ paddingRight: "25px" , pl:2 }}>
        <Grid item xs={12} lg={12}>
          <Box py={2} pl={2}>
          </Box>
        </Grid>
        <Grid container columnSpacing={2} pt={1}>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName= {t("Location Name")}
                fieldValue={
                    locationData?.name ? locationData?.name : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Location Code")}
                fieldValue={
                    locationData?.code ? locationData?.code : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Location URL")}
                fieldValue={
                    locationData?.location_url ? locationData?.location_url : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Address Line One")}
                fieldValue={
                    locationData?.address_line_one? locationData?.address_line_one : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("Address Line Two")}
                fieldValue={
                    locationData?.address_line_two ? locationData?.address_line_two : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("State")}
                fieldValue={
                    locationData?.state ? locationData?.state : "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("City")}
                fieldValue={
                    locationData?.city ? locationData?.city: "-"
                }
              />
            </Box>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Box p={1}>
              <TextView
                fieldName={t("zip_code")}
                fieldValue={
                    locationData?.zip_code ? locationData?.zip_code: "-"
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LocationView;

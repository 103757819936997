import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, IconButton, useTheme, Box, Typography } from "@mui/material";
import FormLayout from "component/form";
import AddressModal from "component/helper-modals/address.modal";
import EmailModal from "component/helper-modals/email.modal";
import PhoneModal from "component/helper-modals/phone.modal";
import Loading from "container/loading";
import dayjs from "dayjs";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { shortenFileName } from "forms/hooks/customHook";
import {
  AutoCompleteField,
  InputDatePicker,
  InputField,
  PhoneField,
  InputTimePicker,
  SelectField,
} from "forms/hoc/formfield";
import { dateTimeFormat, parsePhoneNumber } from "forms/hooks/customHook";
import useForm from "forms/hooks/useForm";
import { PolicyCancelValidation, createQuoteCustomerValidation, policyCancelSchema } from "forms/hooks/validateRules";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setQuoteQuote } from "store/slice/quote.slice";
import { useAppDispatch, useAppSelector } from "store/store";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import EnhancedTable from "component/tables/selectableTable";
import { emailHeadCell, phoneHeadCell } from "utils/table.utils";
import { formatSSN } from "helpers/function.helper";
import { setBlocker } from "store/slice/blocker.slice";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import WithFileUpload from "forms/hoc/formfield/FileUpload";
import { useAuth } from "routes/auth.provider";
import { useTranslation } from "react-i18next";
import { error } from "console";
import { Navigate, useNavigate, useParams } from "react-router-dom";

const PolicyCancellationForm = () => {
  const {id} = useParams(); 
  const { t } = useTranslation(); 
  console.log("pid",id)
  const minDate = dayjs().subtract(18, "years");
  const navigate = useNavigate();
  const basicInfo = useAppSelector((state) => state.quote.customer);
  const [loading, setLoading] = useState<any>(false);
  const [policyInfoData , setPolicyInfoData] = useState<any>("")
  const [masterData, setMasterData] = useState<any>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [stateoptions, setStateoptions] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [emailOpen, setEmailOpen] = useState(false);
  const [phoneOpen, setPhoneOpen] = useState(false);
  const [emails, setEmails] = useState<any[]>([]);
  const [phones, setPhones] = useState<any[]>([]);
  const [emailOptions, setEmailOptions] = useState<any[]>([]);
  const [phoneOptions, setPhoneOptions] = useState<any[]>([]);
  const [authorizedSignatureFile, setAuthorizedSignatureFile] = useState<any>(null);
  const [signatureOfWitnessFile, setSignatureOfWitnessFile] = useState<any>(null);
  const [signatureOfInsuredFile, setSignatureOfInsuredFile] = useState<any>(null);
  const [signatureOfProducerFile, setSignatureOfProducerFile] = useState<any>(null);
  const theme = useTheme();
  const initialValues = {
    producer_name: null,
    producer_phone: null,
    code: null,
    sub_code: null,
    agency_customer_id: null,
    producer_address_line_one: null,
    producer_address_line_two: null,
    producer_state: null,
    producer_city: null,
    producer_zipcode: null,
    company_name: null,
    naic_code: null,
    nac_address_line_one: null,
    nac_address_line_two: null,
    nac_state: null,
    nac_city: null,
    nac_zipcode: null,
    insured_name: null,
    insured_address_line_one: null,
    insured_address_line_two: null,
    insured_state: null,
    insured_city: null,
    insured_zipcode: null,
    policy_number: null,
    cancellation_date: null,
    cancellation_time: null,
    effective_date_of_cancellation: null,
    expiration_date: null,
    cancellation_request_policy_release: null,
    title: null,
    additional_interest_name: null,
    additional_interest_address_line_one: null,
    type_of_additional_interest: null,
    date_authorized: null,
    date_witness: null,
    date_named_insured: null,
    authorized_signature_path: null,
    signature_of_witness_path: null,
    signature_of_the_name_insured_path: null,
    reason_for_cancellation: null,
    info: null,
    company_rewritten: null,
    policy_number_rewritten: null,
    effective_date_rewritten: null,
    method_of_cancellation: null,
    full_term_premium: null,
    unearned_factor: null,
    returned_premium: null,
    remarks: null,
    loss_payee_mortgagee_lienholder: null,
    party_name: null,
    party_address_line_one: null,
    party_address_line_two: null,
    party_state: null,
    party_city: null,
    party_zipcode: null,
    date_producer: null,
    producer_signature_path: null
  };
  


  useEffect(() => {
    dispatch(setBlocker({ status: false }));
    getState();
  }, []);
  const formSubmit = () => {
    setLoading(true);
    const api =  `/policy-cancellation`
    const bodyData = {...values,policy:id ,
      party_state: values?.party_state?.name ,
       insured_state: values?.insured_state?.name ,
       nac_state: values?.nac_state?.name ,
       producer_state: values?.producer_state?.name,
       effective_date_of_cancellation :values?.effective_date_of_cancellation.format('YYYY-MM-DD'), 
       expiration_date :values?.expiration_date.format('YYYY-MM-DD'), }
    commonService.postService(api, bodyData).then((res: any) => {
      console.log(res?.data);

      setLoading(false);
      Success(res?.data?.message);  
      navigate(-1);
    });
    };


  useEffect(()=>{
    getPolicyInfo()

  },[])
   
  const getPolicyInfo = () => {
      setLoading(true);
      commonService
        .getServices(`/policy/${id}`)
        .then((res) => {
          const st = res?.data?.data?.customer?.contactInfos[0]?.addresses[0]?.state;
          // setPolicyInfoData(res?.data?.data?.policy_number);
          setPatchValue({
            expiration_date: dayjs(res?.data?.data?.expiration_date),
            effective_date_of_cancellation: dayjs(res?.data?.data?.effective_date),
            policy_number: res?.data?.data?.policy_number,
            insured_name: res?.data?.data?.customer?.contactInfos[0]?.first_name +" "+ res?.data?.data?.customer?.contactInfos[0]?.last_name,
            company_name: res?.data?.data?.insurance_company?.name,
            insured_address_line_one : res?.data?.data?.customer?.contactInfos[0]?.addresses[0]?.address_line_1,
            insured_address_line_two : res?.data?.data?.customer?.contactInfos[0]?.addresses[0]?.address_line_2,
            insured_city : res?.data?.data?.customer?.contactInfos[0]?.addresses[0]?.city,
            insured_state : st? { name: st?.name, value: st?.id }: null,
            insured_zipcode : res?.data?.data?.customer?.contactInfos[0]?.addresses[0]?.zip_code,
            
          })
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    const importModalT = policyCancelSchema(t);
  
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setUpdateValue,
    setUpdateFormValue,
    setPatchValue,
  } = useForm(initialValues , formSubmit, PolicyCancelValidation,importModalT);
  console.log("values 11",values)
  console.log("error 11",errors)
  const getState = () => {
    commonService
      .getServices(`/state`)
      .then((res) => {
        console.log(res?.data?.data);
        let stateList: any = res.data.data;
        // const NY = stateList?.find((state: any) => state.name === "New York");
        setStateoptions(stateList || []);
        // setUpdateValue("party_state", NY || null);
        // setUpdateValue("insured_state", NY || null);
        // setUpdateValue("nac_state", NY || null);
        // setUpdateValue("producer_state", NY || null);
      })
      .catch((err) => {});
  };


  const fileUpload = (fi: any) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      // for (const file of fi) {
      formData.append("file", fi);
      formData.append("upload_type", "customer");
      // }

      commonService
        .fileUploadService("/uploads", formData)
        .then((res: any) => {
          setLoading(false);
          if (res?.data) {
            setUpdateValue("path", res.data?.data[0]?.path);
            setUpdateValue("file_type", res.data?.data[0]?.file_type);
            resolve(res.data);
          } else {
            Failed("Something went wrong");
            reject();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          Failed(errorMessage(err, "Failed to Upload files"));
          reject();
        });
    });
  };

  const handleRemoveFile = (field: string) => {
    setUpdateValue(`${field}_path`, '');
    setUpdateValue(`${field}_type`, '');
    if (field === 'authorized_signature') setAuthorizedSignatureFile(null);
    if (field === 'signature_of_witness') setSignatureOfWitnessFile(null);
    if (field === 'signature_of_the_name_insured') setSignatureOfInsuredFile(null);
    if (field === 'producer_signature') setSignatureOfProducerFile(null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const selectedFile = e.target.files;
    if (selectedFile?.length) {
      const file = selectedFile[0];
      const fileName = file.name;
      const fileType = file.type;
      const size = file.size;
      const limit: any = process.env.REACT_APP_UPLOAD_LIMIT;
      if (size >= ((+limit || 10) * 1024 * 1024)) {
        return Failed("Size of attachments cannot exceed 10MB");
      }
      if (fileName.endsWith('.pdf') || fileType === 'application/pdf') {
        fileUpload(selectedFile[0]);
        setUpdateValue(`${field}_path`, fileName);
        setUpdateValue(`${field}_type`, fileType);
        if (field === 'authorized_signature') setAuthorizedSignatureFile(selectedFile[0]);
        if (field === 'signature_of_witness') setSignatureOfWitnessFile(selectedFile[0]);
        if (field === 'signature_of_the_name_insured') setSignatureOfInsuredFile(selectedFile[0]);
        if (field === 'producer_signature') setSignatureOfProducerFile(selectedFile[0]);
      } else {
        handleRemoveFile(field);
        Failed("Invalid file type. Please select a PDF file.");
      }
    }
  };
  
  

  



  return (
    <MainContainerWrapper headingText="Policy Cancellation">
      <Grid container height={"100%"} padding={2}>
        {loading && <Loading />}
        <Grid item lg={12}>
          <FormLayout
            handleSubmit={handleSubmit}
            submitButtonText={t("Save & Proceed")}
          >
            <>
              <Grid container sx={{ paddingRight: "25px" }}>
                <Grid item xs={12} lg={12}>
                  <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                    <Typography variant="h3" textAlign={"left"}>
                      {t("Producer")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <InputField
                      fieldName={t("Producer Name")}
                      values={values?.producer_name || ""}
                      name="producer_name"
                      errors={errors?.producer_name}
                      handleChange={handleChange}
                      id="producer_name"
                      isRequired
                    />
                  </Grid>

                  {/* <Grid item xs={12} lg={3}>
                    <InputField
                      fieldName="Producer Address"
                      values={values?.producer_address || ""}
                      name="producer_address"
                      errors={errors?.producer_address}
                      handleChange={handleChange}
                      id="producer_address"
                      isRequired
                    />
                  </Grid> */}

                  <Grid item xs={12} lg={3}>
                    <PhoneField
                      fieldName={t("Producer Phone")}
                      value={values?.producer_phone}
                      name="producer_phone"
                      errors={errors?.producer_phone}
                      handleChange={handleChange}
                      id="producer_phone"
                      isRequired
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <InputField
                      fieldName={t("Code")}
                      values={values?.code || ""}
                      name="code"
                      errors={errors?.code}
                      handleChange={handleChange}
                      id="code"
                      isRequired={false}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <InputField
                      fieldName={t("Sub code")}
                      values={values?.sub_code || ""}
                      name="sub_code"
                      errors={errors?.sub_code}
                      handleChange={handleChange}
                      id="sub_code"
                      isRequired={false}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <InputField
                      fieldName={t("Agency Customer ID")}
                      values={values?.agency_customer_id || ""}
                      name="agency_customer_id"
                      errors={errors?.agency_customer_id}
                      handleChange={handleChange}
                      id="agency_customer_id"
                      isRequired
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("address_line_one")}
                values={values?.producer_address_line_one || ""}
                name="producer_address_line_one"
                errors={errors?.producer_address_line_one}
                handleChange={handleChange}
                id="producer_address_line_one"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("address_line_two")}
                values={values?.producer_address_line_two || ""}
                name="producer_address_line_two"
                isRequired={false}
                errors={errors?.producer_address_line_two}
                handleChange={handleChange}
                id="producer_address_line_two"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AutoCompleteField
                fieldName={t("State")}
                values={values.producer_state || null}
                name="producer_state"
                errors={errors.producer_state}
                handleChange={(producer_state: any) => setUpdateFormValue("producer_state", producer_state)}
                options={stateoptions || []}
                id="producer_state"
                // disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("City")}
                values={values?.producer_city || ""}
                name="producer_city"
                errors={errors?.producer_city}
                handleChange={handleChange}
                id="producer_city"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("Zip code")}
                values={values?.producer_zipcode || ""}
                name="producer_zipcode"
                errors={errors?.producer_zipcode}
                handleChange={handleChange}
                id="producer_zipcode"
              />
            </Grid>
                </Grid>
              </Grid>
            </>

            <Grid container sx={{ paddingRight: "25px", mt: 2 }}>
              <Grid item xs={12} lg={12}>
                <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                  <Typography variant="h3" textAlign={"left"}>
                    {t("Company details")}
                  </Typography>
                </Box>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Company Name")}
                    values={values?.company_name || ""}
                    name="company_name"
                    errors={errors?.company_name}
                    handleChange={handleChange}
                    id="company_name"
                    isRequired
                    disabled
                  />
                </Grid>

                {/* <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName="Company Address"
                    values={values?.company_address || ""}
                    name="company_address"
                    errors={errors?.company_address}
                    handleChange={handleChange}
                    id="company_address"
                    isRequired={false}
                  />
                </Grid> */}

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("NAIC Code")}
                    values={values?.naic_code || ""}
                    name="naic_code"
                    errors={errors?.naic_code}
                    handleChange={handleChange}
                    id="naic_code"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("address_line_one")}
                values={values?.nac_address_line_one || ""}
                name="nac_address_line_one"
                errors={errors?.nac_address_line_one}
                handleChange={handleChange}
                id="nac_address_line_one"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("address_line_two")}
                values={values?.nac_address_line_two || ""}
                name="nac_address_line_two"
                isRequired={false}
                errors={errors?.nac_address_line_two}
                handleChange={handleChange}
                id="nac_address_line_two"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AutoCompleteField
                fieldName={t("State")}
                values={values.nac_state || null}
                name="nac_state"
                errors={errors.nac_state}
                handleChange={(nac_state: any) => setUpdateFormValue("nac_state", nac_state)}
                options={stateoptions || []}
                id="nac_state"
                // disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("City")}
                values={values?.nac_city || ""}
                name="nac_city"
                errors={errors?.nac_city}
                handleChange={handleChange}
                id="nac_city"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("Zip code")}
                values={values?.nac_zipcode || ""}
                name="nac_zipcode"
                errors={errors?.nac_zipcode}
                handleChange={handleChange}
                id="nac_zipcode"
              />
            </Grid>
                {/* <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName="Insured Name"
                    values={values?.insured_name || ""}
                    name="insured_name"
                    errors={errors?.insured_name}
                    handleChange={handleChange}
                    id="insured_name"
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName="Insured Address"
                    values={values?.insured_address || ""}
                    name="insured_address"
                    errors={errors?.insured_address}
                    handleChange={handleChange}
                    id="insured_address"
                    isRequired
                  />
                </Grid> */}
              </Grid>
            </Grid>
            <Grid container sx={{ paddingRight: "25px", mt: 2 }}>
              <Grid item xs={12} lg={12}>
                <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                  <Typography variant="h3" textAlign={"left"}>
                    {t("Insured details")}
                  </Typography>
                </Box>
              </Grid>
              <Grid container columnSpacing={2}>
                {/* <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName="Company Name"
                    values={values?.company_name || ""}
                    name="company_name"
                    errors={errors?.company_name}
                    handleChange={handleChange}
                    id="company_name"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName="Company Address"
                    values={values?.company_address || ""}
                    name="company_address"
                    errors={errors?.company_address}
                    handleChange={handleChange}
                    id="company_address"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName="NAIC Code"
                    values={values?.naic_code || ""}
                    name="naic_code"
                    errors={errors?.naic_code}
                    handleChange={handleChange}
                    id="naic_code"
                    isRequired={false}
                  />
                </Grid> */}
                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Insured Name")}
                    values={values?.insured_name || ""}
                    name="insured_name"
                    errors={errors?.insured_name}
                    handleChange={handleChange}
                    id="insured_name"
                    isRequired
                    disabled={true}
                  />
                </Grid>

                {/* <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName="Insured Address"
                    values={values?.insured_address || ""}
                    name="insured_address"
                    errors={errors?.insured_address}
                    handleChange={handleChange}
                    id="insured_address"
                    isRequired
                  />
                </Grid> */}
                <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("address_line_one")}
                values={values?.insured_address_line_one || ""}
                name="insured_address_line_one"
                errors={errors?.insured_address_line_one}
                handleChange={handleChange}
                id="insured_address_line_one"
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("address_line_two")}
                values={values?.insured_address_line_two || ""}
                name="insured_address_line_two"
                isRequired={false}
                errors={errors?.insured_address_line_two}
                handleChange={handleChange}
                id="insured_address_line_two"
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AutoCompleteField
                fieldName={t("State")}
                values={values.insured_state || null}
                name="insured_state"
                errors={errors.insured_state}
                handleChange={(insured_state: any) => setUpdateFormValue("insured_state", insured_state)}
                options={stateoptions || []}
                id="insured_state"
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("city")}
                values={values?.insured_city || ""}
                name="insured_city"
                errors={errors?.insured_city}
                handleChange={handleChange}
                id="insured_city"
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("Zip code")}
                values={values?.insured_zipcode || ""}
                name="insured_zipcode"
                errors={errors?.insured_zipcode}
                handleChange={handleChange}
                id="insured_zipcode"
                disabled
              />
            </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingRight: "25px", mt: 2 }}>
              <Grid item xs={12} lg={12}>
                <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                  <Typography variant="h3" textAlign={"left"}>
                    {t("Cancelled Policy Information")}
                  </Typography>
                </Box>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Policy Number")}
                    values={values?.policy_number || ""}
                    name="policy_number"
                    errors={errors?.policy_number}
                    handleChange={handleChange}
                    id="policy_number"
                    isRequired
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputDatePicker
                    fieldName={t("Cancellation Date")}
                    values={values?.cancellation_date ? dayjs(values?.cancellation_date) : null}
                    name="cancellation_date"
                    errors={errors?.cancellation_date}
                    handleChange={(e) =>
                      setUpdateFormValue("cancellation_date", dayjs(e).format("YYYY-MM-DD"))
                    }
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                <InputTimePicker
                 fieldName={t("Cancellation Time")}
                 values={values?.cancellation_time|| null}
                 name="cancellation_time"
                 errors={errors?.cancellation_time}
                 //handleChange={}
                 handleChange={(e) =>{
                  setUpdateFormValue("cancellation_time", dayjs(e))
                  console.log(dateTimeFormat(e))
                 }
                }
      
                />
                </Grid>

                {/* <Grid item xs={12} lg={3}>
                  <SelectField
                    fieldName="AM/PM"
                    options={[
                      {
                        name: "AM",
                        value: "AM",
                      },
                      {
                        name: "PM",
                        value: "PM",
                      },
                    ]}
                    values={values?.am_pm || ""}
                    name="am_pm"
                    errors={errors?.am_pm}
                    handleChange={handleChange}
                    id="am_pm"
                    isRequired
                    isValueClearRequired
                  />
                </Grid> */}

                <Grid item xs={12} lg={3}>
                <InputDatePicker
                    fieldName={t("Effective Date of Cancellation")}
                    values={values?.effective_date_of_cancellation || ""}
                    name="effective_date_of_cancellation"
                    errors={errors?.effective_date_of_cancellation}
                    handleChange={(e) =>
                      setUpdateFormValue("effective_date_of_cancellation", dayjs(e).format("YYYY-MM-DD"))
                    }
                    isRequired
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                <InputDatePicker
                    fieldName={t("Expiration Date")}
                    values={values?.expiration_date || ""}
                    name="expiration_date"
                    errors={errors?.expiration_date}
                    handleChange={(e) =>
                      setUpdateFormValue("expiration_date", dayjs(e).format("YYYY-MM-DD"))
                    }
                    isRequired
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <SelectField
                    fieldName={t("Cancellation Request/Policy Release")}
                    options={[
                      {
                        name: "Cancellation Request",
                        value: "Cancellation Request",
                      },
                      {
                        name: "Policy Release",
                        value: "Policy Release",
                      },
                    ]}
                    values={values?.cancellation_request_policy_release || ""}
                    name="cancellation_request_policy_release"
                    errors={errors?.cancellation_request_policy_release}
                    handleChange={handleChange}
                    id="cancellation_request_policy_release"
                    isRequired
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingRight: "25px", mt: 2 }}>
              <Grid item xs={12} lg={12}>
                <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                  <Typography variant="h3" textAlign={"left"}>
                   {t("Signatures")}
                  </Typography>
                </Box>
              </Grid>
              <Grid container columnSpacing={2}>
 

                <Grid item xs={12} lg={3.1}>
                  <InputField
                    fieldName={t("Title")}
                    values={values?.title || ""}
                    name="title"
                    errors={errors?.title}
                    handleChange={handleChange}
                    id="title"
                    isRequired
                  />
                </Grid>



                <Grid item xs={12} lg={3.1}>
                  <InputField
                    fieldName={t("Additional Interest Name")}
                    values={values?.additional_interest_name || ""}
                    name="additional_interest_name"
                    errors={errors?.additional_interest_name}
                    handleChange={handleChange}
                    id="additional_interest_name"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={12} lg={3.1}>
                  <InputField
                    fieldName={t("Additional Interest Address")}
                    values={values?.additional_interest_address_line_one || ""}
                    name="additional_interest_address_line_one"
                    errors={errors?.additional_interest_address_line_one}
                    handleChange={handleChange}
                    id="additional_interest_address_line_one"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={12} lg={2.7}>
                  <SelectField
                    fieldName={t("Type of Additional Interest")}
                    options={[
                      {
                        name: "Lienholder",
                        value: "Lienholder",
                      },
                      {
                        name: "Mortgagee",
                        value: "Mortgagee",
                      },
                      {
                        name: "Loss Payee",
                        value: "Loss Payee",
                      },
                      {
                        name: "Lender’s Loss payable",
                        value: "Lender’s Loss payable",
                      },
                    ]}
                    values={values?.type_of_additional_interest || ""}
                    name="type_of_additional_interest"
                    errors={errors?.type_of_additional_interest}
                    handleChange={handleChange}
                    id="type_of_additional_interest"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={12} lg={3.1}>
                  <InputDatePicker
                    fieldName={t("Date (Authorized)")}
                    values={values?.date_authorized || ""}
                    name="date_authorized"
                    errors={errors?.date_authorized}
                    handleChange={(e) =>
                      setUpdateFormValue(
                        "date_authorized",
                        dayjs(e).format("YYYY-MM-DD")
                      )
                    }
                    id="date_authorized"
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} lg={3.1}>
                <InputDatePicker
                    fieldName={t("Date (Witness)")}
                    values={values?.date_witness || ""}
                    name="date_witness"
                    errors={errors?.date_witness}
                    handleChange={(e) =>
                      setUpdateFormValue(
                        "date_witness",
                        dayjs(e).format("YYYY-MM-DD")
                      )
                    }
                    id="date_witness"
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} lg={3.1}>
                  <InputDatePicker
                    fieldName={t("Date (Named Insured)")}
                    values={values?.date_named_insured || ""}
                    name="date_named_insured"
                    errors={errors?.date_named_insured}
                    handleChange={(e) =>
                      setUpdateFormValue(
                        "date_named_insured",
                        dayjs(e).format("YYYY-MM-DD")
                      )
                    }
                    id="date_named_insured"
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} lg={3.1}>
              <WithFileUpload
                fileAdded={!!values?.authorized_signature_path}
                fileName={shortenFileName(values?.authorized_signature_path)}
                actionText={'Add'}
                fieldName={t("Authorized Signature")}
                errors={errors?.authorized_signature_path}
                handleRemoveFile={() => handleRemoveFile('authorized_signature')}
                handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, 'authorized_signature')}
                isRequired
                fileType={".pdf"}
                helpText={"Pdf files only"}
              />
            </Grid>

            <Grid item xs={12} lg={3.1}>
              <WithFileUpload
                fileAdded={!!values?.signature_of_witness_path}
                fileName={shortenFileName(values?.signature_of_witness_path)}
                actionText={'Add'}
                fieldName={t("Signature of Witness")}
                errors={errors?.signature_of_witness_path}
                handleRemoveFile={() => handleRemoveFile('signature_of_witness')}
                handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, 'signature_of_witness')}
                isRequired
                fileType={".pdf"}
                helpText={"Pdf files only"}
              />
            </Grid>

            <Grid item xs={12} lg={3.1}>
              <WithFileUpload
                fileAdded={!!values?.signature_of_the_name_insured_path}
                fileName={shortenFileName(values?.signature_of_the_name_insured_path)}
                actionText={'Add'}
                fieldName={t("Signature of the named Insured")}
                errors={errors?.signature_of_the_name_insured_path}
                handleRemoveFile={() => handleRemoveFile('signature_of_the_name_insured')}
                handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, 'signature_of_the_name_insured')}
                isRequired
                fileType={".pdf"}
                helpText={"Pdf files only"}
              />
            </Grid>





              </Grid>
            </Grid>
            <Grid container sx={{ paddingRight: "25px", mt: 2 }}>
              <Grid item xs={12} lg={12}>
                <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                  <Typography variant="h3" textAlign={"left"}>
                    {t("Reason for cancellation")}
                  </Typography>
                </Box>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={3}>
                  <SelectField
                    fieldName={t("Reason for Cancellation")}
                    options={[
                      {
                        name: "Not Taken",
                        value: "Not Taken",
                      },
                      {
                        name: "Requested by Insured",
                        value: "Requested by Insured",
                      },
                      {
                        name: "Rewritten",
                        value: "Rewritten",
                      },
                      {
                        name: "Other",
                        value: "Other",
                      },
                    ]}
                    values={values?.reason_for_cancellation || ""}
                    name="reason_for_cancellation"
                    errors={errors?.reason_for_cancellation}
                    handleChange={handleChange}
                    id="reason_for_cancellation"
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Info")}
                    values={values?.info || ""}
                    name="info"
                    errors={errors?.info}
                    handleChange={handleChange}
                    id="info"
                    isRequired={false}
                    
                    disabled={values?.reason_for_cancellation !== "Other"}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Company (Rewritten)")}
                    values={values?.company_rewritten || ""}
                    name="company_rewritten"
                    errors={errors?.company_rewritten}
                    handleChange={handleChange}
                    id="company_rewritten"
                    isRequired={false}
                    disabled={values?.reason_for_cancellation !== "Rewritten"}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Policy Number (Rewritten)")}
                    values={values?.policy_number_rewritten || ""}
                    name="policy_number_rewritten"
                    errors={errors?.policy_number_rewritten}
                    handleChange={handleChange}
                    id="policy_number_rewritten"
                    isRequired={false}
                    disabled={values?.reason_for_cancellation !== "Rewritten"}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                <InputDatePicker
                    fieldName={t("Effective Date (Rewritten)")}
                    values={values?.effective_date_rewritten || ""}
                    name="effective_date_rewritten"
                    errors={errors?.effective_date_rewritten}
                    handleChange={(e) =>
                      setUpdateFormValue(
                        "effective_date_rewritten",
                        dayjs(e).format("YYYY-MM-DD")
                      )
                    }
                    isRequired={false}
                    disabled={values?.reason_for_cancellation !== "Rewritten"}
                    
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingRight: "25px", mt: 2 }}>
              <Grid item xs={12} lg={12}>
                <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                  <Typography variant="h3" textAlign={"left"}>
                    {t("Method of Cancellation")}
                  </Typography>
                </Box>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={3}>
                  <SelectField
                    fieldName= {t("Method of Cancellation")}
                    options={[
                      {
                        name: "Pro Rate",
                        value: "Pro Rate",
                      },
                      {
                        name: "Short Rate",
                        value: "Short Rate",
                      },
                      {
                        name: "Flat",
                        value: "Flat",
                      },
                      {
                        name: "Premium calculation subject to audit",
                        value: "Premium calculation subject to audit",
                      },
                    ]}
                    values={values?.method_of_cancellation || ""}
                    name="method_of_cancellation"
                    errors={errors?.method_of_cancellation}
                    handleChange={handleChange}
                    id="method_of_cancellation"
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Full Term Premium")}
                    values={values?.full_term_premium || ""}
                    name="full_term_premium"
                    errors={errors?.full_term_premium}
                    handleChange={handleChange}
                    id="full_term_premium"
                    onlyNumber={true}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Unearned Factor")}
                    values={values?.unearned_factor || ""}
                    name="unearned_factor"
                    errors={errors?.unearned_factor}
                    handleChange={handleChange}
                    id="unearned_factor"
                    onlyNumber={true}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Returned Premium")}
                    values={values?.returned_premium || ""}
                    name="returned_premium"
                    errors={errors?.returned_premium}
                    handleChange={handleChange}
                    id="returned_premium"
                    onlyNumber={true}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Remarks")}
                    values={values?.remarks || ""}
                    name="remarks"
                    errors={errors?.remarks}
                    handleChange={handleChange}
                    id="remarks"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingRight: "25px", mt: 2 }}>
              <Grid item xs={12} lg={12}>
                <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                  <Typography variant="h3" textAlign={"left"}>
                    {t("Request/Release Distribution")}
                  </Typography>
                </Box>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={3}>
                  <SelectField
                    fieldName={t("Loss Payee/Mortgagee/Lienholder")}
                    options={[
                      {
                        name: "Insured",
                        value: "Insured",
                      },
                      {
                        name: "Loss Payee",
                        value: "Loss Payee",
                      },
                      {
                        name: "Mortgagee",
                        value: "Mortgagee",
                      },
                      {
                        name: "Lienholder",
                        value: "Lienholder",
                      },
                      {
                        name: "Company",
                        value: "Company",
                      },
                      {
                        name: "Finance Company",
                        value: "Finance Company",
                      },
                      {
                        name: "Lender's Loss Payable",
                        value: "Lender's Loss Payable",
                      },
                    ]}
                    values={values?.loss_payee_mortgagee_lienholder || ""}
                    name="loss_payee_mortgagee_lienholder"
                    errors={errors?.loss_payee_mortgagee_lienholder}
                    handleChange={handleChange}
                    id="loss_payee_mortgagee_lienholder"
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName={t("Party’s Name")}
                    values={values?.party_name || ""}
                    name="party_name"
                    errors={errors?.party_name}
                    handleChange={handleChange}
                    id="party_name"
                    isRequired
                  />
                </Grid>

                {/* <Grid item xs={12} lg={3}>
                  <InputField
                    fieldName="Party’s Address"
                    values={values?.partys_address || ""}
                    name="partys_address"
                    errors={errors?.partys_address}
                    handleChange={handleChange}
                    id="partys_address"
                    isRequired
                  />
                </Grid> */}
                                 <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("address_line_one")}
                values={values?.party_address_line_one || ""}
                name="party_address_line_one"
                errors={errors?.party_address_line_one}
                handleChange={handleChange}
                id="party_address_line_one"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("address_line_two")}
                values={values?.party_address_line_two || ""}
                name="party_address_line_two"
                isRequired={false}
                errors={errors?.party_address_line_two}
                handleChange={handleChange}
                id="party_address_line_two"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AutoCompleteField
                fieldName={t("State")}
                values={values.party_state || null}
                name="party_state"
                errors={errors.party_state}
                handleChange={(party_state: any) => setUpdateFormValue("party_state", party_state)}
                options={stateoptions || []}
                id="party_state"
                // disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("City")}
                values={values?.party_city || ""}
                name="party_city"
                errors={errors?.party_city}
                handleChange={handleChange}
                id="party_city"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <InputField
                fieldName={t("Zip code")}
                values={values?.party_zipcode || ""}
                name="party_zipcode"
                errors={errors?.party_zipcode}
                handleChange={handleChange}
                id="party_zipcode"
              />
            </Grid>

            <Grid item xs={12} lg={3}>
                <InputDatePicker
                    fieldName={t("Date (Producer)")}
                    values={values?.date_producer || ""}
                    name="date_producer"
                    errors={errors?.date_producer}
                    handleChange={(e) =>
                      setUpdateFormValue(
                        "date_producer",
                        dayjs(e).format("YYYY-MM-DD")
                      )
                    }
                    isRequired
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <WithFileUpload
                    fileAdded={!!values?.producer_signature_path}
                    fileName={shortenFileName(values?.producer_signature_path)}
                    actionText={'Add'}
                    fieldName={t("Signature")}
                    // name="producer_signature"
                    errors={errors?.producer_signature_path}
                    handleRemoveFile={() => handleRemoveFile('producer_signature')}
                    handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e , 'producer_signature')}
                    isRequired
                    fileType={".pdf"}
                    helpText={"Pdf files only"}
                  />
                </Grid>


              </Grid>
            </Grid>
          </FormLayout>
        </Grid>
      </Grid>
    </MainContainerWrapper>
  );
};

export default PolicyCancellationForm;

type PolicyCancelForm = {
  handlePolicyCancel: any;
};

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ViewAllNotesModel from 'component/helper-modals/ViewAllNotesModel.model'
import EnhancedTable from 'component/tables/selectableTable'
import { commonService } from 'forms/helpers/common.service'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'store/store'

import { Success } from 'forms/helpers/toast.helper'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ReactQuill, { Quill } from 'react-quill'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ViewNotesModel from 'component/helper-modals/ViewNotes.model'
import { Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";


function ReferenceNotes({ handleNextTab }: ReferenceNotesType) {


    const [addNote, setAddNote] = useState<boolean>(false)
    const [viewNote, setViewNote] = useState<boolean>(false)
    const [viewAllNote, setViewAllNote] = useState<boolean>(false);
    const [current, setCurrent] = useState<number>(1)
    const [rows, setRows] = useState<any>();
    const [noteId, setnoteId] = useState<string>("")
    const customerId = useAppSelector((state) => state?.customer?.customer?.id);
    const quillRef = useRef<any>(null); // Reference to the Quill instance
    const [currentNote, setCurrentNote] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [noteData, setnoteData] = useState<any>("")
    const [noteList, setnoteList] = useState<any>([])
    const [enableNext, setenableNext] = useState<boolean>(false)
    const [enablePrevious, setenablePervious] = useState<boolean>(false)
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false); // State to toggle editor mode
    const [contact, setContact] = useState<boolean>(false)
    const [alertMe, setalertMe] = useState(false);
    const [alertToAll, setalertToAll] = useState(false);
    const [alertTofew, setalertTofew] = useState(false);
    const [getTenant, setgetTenant] = useState<any>([]);
    const [selectedTenants, setSelectedTenants] = useState<any[]>([]);
    const { t } = useTranslation();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const { role } = useAppSelector((state) => state.user.user);

    const handleViewAllNotesClick = () => {
        setViewAllNote(true)
    }

    const handleViewNoteClick = () => {
        setViewNote(true)
    }

    const getCurrentDateTime = () => {
        const now = new Date();
        return now.toLocaleString(); // Example: "10/23/2024, 12:34 PM"
    };

    const dateandt = getCurrentDateTime();

    const template = getCurrentDateTime() + " Created by " + role?.name;

    useEffect(() => {
        // getNotes()
    }, [])


    const getTenantList = () => {
        commonService.getServices(`tenant/tenants/get-all`)
            .then((res: any) => {
                const tenantData = res?.data?.data.map((data: any) => {
                    return {
                        value: data?.user?.id,
                        name: data.first_name + " " + data.last_name
                    }
                })
                setgetTenant(tenantData);
            })
            .catch((err: any) => {
                console.log(err)
            })
    }

    const handleCloseWithReset = () => {
        setCurrentNote(0)
    };

    useEffect(() => {
        getTenantList()
    }, []); // Only run when the modal opens


    // Function to clear only the note content, preserving the template
    const clearNoteContent = () => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            // Preserve the template but clear the user-inputted content
            const currentHTML = quill.root.innerHTML;
            const templateEndIndex = currentHTML.indexOf('</p>') + 4; // Find the end of the template
            const newContent = currentHTML.substring(0, templateEndIndex) + '<p><br></p>'; // Keep the template and reset content

            quill.root.innerHTML = newContent;
            setnoteData(newContent); // Update the state to match the editor content
        }
    };

    const handleSubmitNote = () => {

        // commonService.postService(`/customer-notes/${customerId}`, {
        //     notes: `<strong>${template}</strong><br>${noteData}`,
        //     alert_to_me: alertMe,
        //     alert_to_all: alertToAll,
        //     alert_to_few: alertTofew,
        //     alert_to: selectedTenants
        // })
        //     .then((res: any) => {
        //         Success(res?.data?.message)
        //         setCurrentNote(currentNote + 1)
        //         // clearNoteContent()
        //         setnoteData("")

        //     })
        //     .catch((err: any) => {
        //         console.log(err)
        //     })

        handleNextTab()
    }

    const modules = {
        toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            ["clean"],
        ],
    };
    const formats = ["bold", "italic", "underline", "list", "bullet", "link"];


    const handlealertMe = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setalertMe(checked);
        setalertToAll(false)
        setalertTofew(false)
    }

    const handlealertToFew = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setalertTofew(checked);
        setalertToAll(false);
        setalertMe(false)
    }

    const handlealertToAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setalertToAll(checked);
        setalertMe(false)
        setalertTofew(false)
    }

    const handleChangeSelectTenants = (e: any, newSelectedValues: any[]) => {
        const selectedId = newSelectedValues.map((i: any) => i.value)
        setSelectedTenants(selectedId);
    }

    return (
        <>
            <MainContainerWrapper
                headingText="Add Notes"
                isCreateButton={true}
                buttonText={"View All Notes"}
                buttonIcon={<RemoveRedEyeOutlinedIcon />}
                // handleClick={handleViewAllNotesClick}
                isSecondButton={true}
                secondButtonText={`View Notes`}
                // handleSecondClick={handleViewNoteClick}
                secondButtonIcon={<RemoveRedEyeOutlinedIcon />}

            >


                <Box>

                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                        {/* Quill Container */}

                        <Box sx={{ flexGrow: 1, height: "250px", }}>

                            {/* here I need that templte top of the box like header design like I need  */}
                            <Box
                                sx={{

                                    padding: "8px",
                                    border: "1px solid #ccc",
                                    backgroundColor: "#f0f0f0", // Light background for contrast
                                    fontWeight: "bold",
                                    borderBottom: "none"
                                }}
                            >
                                {template}
                            </Box>

                            <ReactQuill
                                ref={quillRef}
                                value={noteData}
                                onChange={(value: string) => setnoteData(`${value}`)} // Directly set noteData from the editor value
                                theme="snow"
                                formats={formats}
                                modules={modules}
                                style={{ height: "100%" }} // Ensure Quill takes the full height of its container
                            />
                        </Box>

                        {/* Alert To Few Section */}
                        {alertTofew && (
                            <Box sx={{}}>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={getTenant}
                                    disableCloseOnSelect={true}
                                    getOptionLabel={(option: any) => option.name}

                                    onChange={handleChangeSelectTenants} // Add this line
                                    renderOption={(props, option, { selected }) => {
                                        // const { key, ...optionProps } = props;
                                        return (
                                            <Box
                                                component="li"
                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                {...props}
                                            >
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </Box>
                                        );
                                    }}

                                    style={{ width: 350 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Alert to few" placeholder="Alert to few" />
                                    )}
                                />
                            </Box>
                        )}
                    </Box>


                    {
                        <Box sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            mt: 12
                        }}>
                            <Box sx={{ ml: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handlealertMe}
                                            checked={alertMe}
                                        />
                                    }
                                    label={t("alert_to_me")}
                                />
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handlealertToFew}
                                            checked={alertTofew}
                                        />
                                    }
                                    label={t("alert_to_few")}
                                />
                            </Box>

                            <Box sx={{ ml: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handlealertToAll}
                                            checked={alertToAll}
                                        />
                                    }
                                    label={t("alert_to_all")}
                                />
                            </Box>
                        </Box>
                    }
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 7,
                        }}
                    >

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseWithReset} // Cancel button handler
                            sx={{
                                marginRight: 1,
                                height: "2.4rem"
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                marginLeft: 1,
                                minWidth: "100px",
                                height: "2.4rem"
                            }}
                            onClick={handleSubmitNote} // Submit button handler
                        // disabled={!noteData.trim()}
                        >
                            Add
                        </Button>
                    </Box>
                </Box>
            </MainContainerWrapper>
        </>
    )
}

export default ReferenceNotes


type ReferenceNotesType = {
    handleNextTab: any
}
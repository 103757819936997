import { Box, Grid } from "@mui/material";
import FormLayout from "component/form";
import ModalComponent from "component/modal";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import WithFileUpload from "forms/hoc/formfield/FileUpload";
import { shortenFileName } from "forms/hooks/customHook";
import useForm from "forms/hooks/useForm";
import { importModalValidation, importModalValidationSchema } from "forms/hooks/validateRules";
import { useState } from "react";
import { useTranslation } from "react-i18next";





const CustomerPolicyImport = ({
  open,
  handleClose,
  handleFormSubmit,
}: CustomerPolicyImportTypes) => {


  const [loading, setLoading] = useState(false);


  const initialValues = {
    file: null,
  };

  const formSubmit = (e: any) => {
    e.preventDefault();
    if (!values) return;
    handleFormSubmit(values);
  };

  const fileUpload = () => {
    let formData = new FormData();
    formData.append("file", values?.file);

    commonService
      .fileUploadService(`excel-policy/upload`, formData)
      .then((res: any) => {
        if (res?.data) {
          setLoading(false);
          Success(res?.data?.message)
          handleClose()
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1);  // 100 milliseconds delay (you can adjust this if needed)

        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        Failed(errorMessage(err, "Failed to Upload files"));
      });
  };

  const { t } = useTranslation();

const importModalT =  importModalValidationSchema(t);

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setPatchValue,

  } = useForm(initialValues, fileUpload, importModalValidation,importModalT);


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files;
    if (selectedFile?.length) {
      const size = selectedFile[0].size;
      const limit: any = process.env.REACT_APP_UPLOAD_LIMIT;
      if (size >= (+limit || 10) * 1024 * 1024) {
        return Failed("Size of attachments cannot exceed 10MB");
      }
      setPatchValue({ file: selectedFile[0] });
    }
  };
  
  return (
    <>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        customeStyle={{ width: "30%" }}
      >
        <Box>
          {loading && <Loading />}
          <FormLayout
            handleSubmit={handleSubmit}
            backButtonText={t("Cancel")}
            backButtonClick={handleClose}
            isBackButton
            scrollable="hidden"
            submitButtonText={t("Import")}
          >
            <Grid item lg={12} pb={3}>
              <WithFileUpload
                fileAdded={!!values?.file}
                fileName={shortenFileName(values?.file?.name)}
                actionText={"Add"}
                buttonText={t("Upload File")}
                handleRemoveFile={() => setPatchValue({ ...initialValues })}
                handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleFileChange(e)
                }
                errors={errors?.file}
                fieldName={t("File")}
                fileType=".xlsx, .xls, .csv"
                helpText={t("Excel files only")}
                maxmiumText={t("Maximum file size 10mb")}
              />
            </Grid>

          </FormLayout>
        </Box>
      </ModalComponent >
    </>
  )

}


type CustomerPolicyImportTypes = {
  handleClose: any;
  open: boolean;
  handleFormSubmit: any;
}

export default CustomerPolicyImport;
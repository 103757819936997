import { createSlice, PayloadAction } from "@reduxjs/toolkit";



interface ReferenceType {
    referenceID: string | null;
}


const initialState: ReferenceType = {
    referenceID: null,
}


export const referenceSlice = createSlice({
    name: "reference",
    initialState,
    reducers: {
        setReferenceID(state, action: PayloadAction<string>) {
            state.referenceID = action.payload;
        }
    },
    extraReducers: (builder) =>
        builder.addCase("LOGOUT", (state, action) => {
            return { ...initialState };
        }),
})

export const { setReferenceID } = referenceSlice.actions;
export default referenceSlice.reducer;
const menu = [
  {
    id: `Customer_policy`,
    title: `Customer Policies`,
    type: `item`,
    url: `/customer-policy`,
    icon: `policy`,
    breadcrumbs: false,
    href: false,
    activeItem: [`/customer-policy`],
  },
  {
    id:`Tansaction History`,
    title: `Transaction History`,
    type: `item`,
    url: `/transaction-history`,
    icon: `Reconciliation`,
    breadcrumbs: false,
    href: false,
    activeItem: [
      `/transaction-history`,
    ],
  },
  {
    id: `settings`,
    title: `Settings`,
    type: `item`,
    url: `/settings`,
    icon: `setting`,
    breadcrumbs: false,
    href: false,
    activeItem: [`/settings`, '/settings/masters', '/settings/insurance', '/settings/industry', '/settings/landing', '/settings/reminder', '/settings/reminder/create', '/settings/reminder/edit', 'settings/location'],
  },
];
export const tenent = {
  id: `dashboard`,
  title: `Dashboard`,
  type: `group`,
  children: [

    {
      id: `default`,
      title: `Dashboard`,
      type: `item`,
      url: `/dashboard`,
      icon: `gridView`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/dashboard`],
    },
    {
      id: `quotations`,
      title: `Leads`,
      type: `item`,
      url: `/quotes`,
      icon: `quote`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/quotes`, `/quote/view`, `/quote/create`, `/quote/edit`],
    },
    {
      id: `Policy`,
      title: `Policies`,
      type: `item`,
      url: `/policy`,
      icon: `policy`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/policy`],
    },
    {
      id: `Customers`,
      title: `Customers`,
      type: `item`,
      url: `/customers`,
      icon: `group`,
      breadcrumbs: false,
      href: false,
      activeItem: [
        `/customers`,
        "/customers/create",
        "/customers/view",
        "/customers/policy/create",
        "/customers/policy/view",
        "/customers/edit",
        "/customers/policy/edit",
      ],
    },
    // {
    //   id: `reconciliation`,
    //   title: `Reconciliation`,
    //   type: `item`,
    //   url: `/reconciliation`,
    //   icon: `Reconciliation`,
    //   breadcrumbs: false,
    //   href: false,
    //   activeItem: [
    //     `/reconciliation`,
    //   ],
    // },
    {
      id: `reports`,
      title: `Reports`,
      type: `item`,
      url: `/reports`,
      icon: `report`,
      breadcrumbs: false,
      href: false,
      // activeItem: [`/reports`, `/reports/policyBalance`, `/reports/dailyLedger` , `/reports/paymentToCarrier`, `/reports/policyCollection`],
      activeItem: [`/reports`, `/reports/policy-count`, `/reports/customer-list` ,`/reports/fees-report` ,`/reports/reinstatement-report`, `/reports/reinstatement-due-report` , `/reports/policy-renewal-report`,  `/reports/policyBalance`, `/reports/dailyLedger` , `/reports/paymentToCarrier`, `/reports/policyCollection`],
    },


    // {
    //   id: `Employee`,
    //   title: `Employees`,
    //   type: `item`,
    //   url: `/employees`,
    //   icon: `Employees`,
    //   breadcrumbs: false,
    //   href: false,
    //   activeItem: [
    //     `/employees`,
    //     "/employee/create",
    //     "/employee/view",
    //     "/employee/edit",
    //   ],
    // },
    {
      id: `Cheque`,
      title: `Check`,
      type: `item`,
      url: `/chequelist`,
      icon: `check`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/chequelist`, `/cheque/edit`, `/cheque/view`],
    },
    {
      id: `Customer_policy`,
      title: `Customer Policies`,
      type: `item`,
      url: `/customer-policy`,
      icon: `policy`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/customer-policy`],
    },
    {
      id:`documents`,
      title: `documents`,
      type: `item`,
      url: `/all-documents`,
      icon: `documents`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/all-documents`],
    },
  ],
};

export const tenentFree = {
  id: `dashboard`,
  title: `Dashboard`,
  type: `group`,
  children: menu
};
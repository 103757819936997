import { Box, Button, Grid, Typography } from "@mui/material";
import { fontGrid } from "@mui/material/styles/cssUtils";
import FormLayout from "component/form";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import ImageCropper from "pages/cropper/Cropper";
import ColorPickerField, {
  InputField,
  InputTextAreaField,
  PhoneField,
} from "forms/hoc/formfield";
import WithFileUpload from "forms/hoc/formfield/FileUpload";
import TextView from "forms/hoc/text-view";
import { shortenFileName } from "forms/hooks/customHook";
import useForm from "forms/hooks/useForm";
import {
  CreateReconciliationValidation,
  udpateLandinPageValidationSchema,
  udpateLandingPageValidation,
} from "forms/hooks/validateRules";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "routes/auth.provider";
import Loader from "ui-component/Loader";
import { useTranslation } from "react-i18next";

export default function Landing() {
  const [loading, setLoading] = useState<any>(false);
  const [id, setGetId] = useState<any>("");
  const navigate = useNavigate();
  const { getLandingPageDetails } = useAuth();
  //const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);
  const [imageType, setImageType] = useState<string>("");
  const [isCropperOpen, setCropperOpen] = useState<boolean>(false);
  const [filePath, setFilePath] = useState("");
  const [fileType, setFileType] = useState("");

  const formSubmit = () => {
    // fileUpload();
    setLoading(true);
    const api = `/landing-page/update`;
    const bodydata = { ...values, id, logo: `${values.path}` };
    commonService.patchService(api, bodydata).then((res: any) => {
      setLoading(false);
      Success(res?.data?.message);
      getLandingPageDetails();
    });
  };

  const handleFileChange = (e: any) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length) {
      let arr = [...selectedFiles];
      const limit = parseInt(process.env.REACT_APP_UPLOAD_LIMIT || "10", 10); // Use environment variable or default to 10 MB
      const maxSizeBytes = limit * 1024 * 1024; // Convert MB to bytes

      // Calculate the total size of selected files
      let selectedSize = arr.reduce((acc, file) => acc + file.size, 0);

      if (selectedSize > maxSizeBytes) {
        // Check if the total size exceeds the maximum allowed size
        Failed(`Size of attachments cannot exceed ${limit}MB`);
        return;
      }

      // Check if all selected files are images
      for (const file of selectedFiles) {
        if (!file.type.startsWith("image/")) {
          // Alert if any file is not an image
          alert("Please select image files only.");
          return;
        }
      }

      // Process the first file if validation passes
      const file = selectedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setUpdateValue("path", file.name);
        setUpdateValue("file_type", file.type);
        setImageType(file.type);
        setImage(reader.result as string);
        setCropperOpen(true);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCrop = (croppedImage: string) => {
    fileUpload(croppedImage);
    setCropperOpen(false);
  };

  const fileUpload = (croppedImage: any) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", croppedImage);
      formData.append("upload_type", "customer");

      commonService
        .fileUploadService("/uploads", formData)
        .then((res: any) => {
          setLoading(false);
          if (res?.data) {
            setUpdateValue("path", res.data?.data[0]?.path);
            setUpdateValue("file_type", res.data?.data[0]?.file_type);
            setFilePath(res.data?.data[0]?.path);
            setFileType(res.data?.data[0]?.file_type);

            resolve(res.data);
          } else {
            Failed("Something went wrong");
            reject();
          }
          setCropperOpen(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          Failed(errorMessage(err, "Failed to Upload files"));
          reject();
        });
    });
  };
  const { t } = useTranslation();
  
  const updateLandingT = udpateLandinPageValidationSchema(t);
  const {
    errors,
    values,
    handleSubmit,
    setPatchValue,
    setUpdateValue,
    handleChange,
  } = useForm({}, formSubmit, udpateLandingPageValidation,updateLandingT);
 

  const handleRemoveFile = () => {
    setUpdateValue("path", "");
    setUpdateValue("file_type", "");
  };

  useEffect(() => {
    getContantData();
  }, []);

  const getContantData = () => {
    commonService.getServices(`/landing-page/fetch`).then((res: any) => {
      const result = res?.data?.data[0];
      const contentData: any = {};
      contentData.tag_line = result.tag_line;
      contentData.header_contact = result.header_contact;
      contentData.header_email = result.header_email;
      contentData.banner_heading = result.banner_heading;
      contentData.banner_text = result.banner_text;
      contentData.what_we_do_section = result.what_we_do_section;
      contentData.address_section = result.address_section;
      contentData.license = result.license;
      contentData.testimonials1_content = result.testimonials1_content;
      contentData.testimonials2_content = result.testimonials2_content;
      contentData.testimonials3_content = result.testimonials3_content;
      contentData.testimonials1_avatar = result.testimonials1_avatar;
      contentData.testimonials2_avatar = result.testimonials2_avatar;
      contentData.testimonials3_avatar = result.testimonials3_avatar;
      contentData.testimonials1_company = result.testimonials1_company;
      contentData.testimonials2_company = result.testimonials2_company;
      contentData.testimonials3_company = result.testimonials3_company;
      contentData.theme_color = result.theme_color;
      contentData.button_background_color = result.button_background_color;
      contentData.path = result.logo;
      contentData.file_type = result?.logo?.split(".").pop();
      setGetId(result.id);
      setPatchValue(contentData);
      setFilePath(res?.data?.data?.avatarPath);
      setFileType(res?.data?.data?.avatarPath?.split(".").pop());
      setLoading(false);
    });
  };
 
  return (
    <>
      <MainContainerWrapper headingText={t("Landing Page")} isBackButton={true}>
        <Box sx={{ p: 3 }}>
          {loading && <Loading />}
          <FormLayout handleSubmit={handleSubmit}>
            {isCropperOpen && image && (
              <ImageCropper
                image={image}
                onCrop={handleCrop}
                onClose={() => {
                  setPatchValue({
                    path: filePath,
                    file_type: fileType,
                  });
                  setCropperOpen(false);
                }}
                handleFile={fileUpload}
                imageType={imageType}
                handleClose={() => console.log("hehelo")}
                open={() => console.log("hehelo")}
                cropShape="rect"
                aspect={65 / 15}
              />
            )}
            <Box>
              <Typography variant="h2" sx={{ p: 1 }}>
                {t("Header Content")}
              </Typography>
            </Box>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Header Email")}
                  values={values?.header_email || ""}
                  name="header_email"
                  errors={errors?.header_email}
                  handleChange={handleChange}
                  id="header_email"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                {/* <InputField
                fieldName="Header Contact"
                values={values?.header_contact || ""}
                name="header_contact"
                errors={errors?.header_contact}
                handleChange={handleChange}
                id="header_contact"
              /> */}

                <PhoneField
                  name="header_contact"
                  fieldName={t("Header Contact")}
                  handleChange={handleChange}
                  errors={errors?.header_contact}
                  id="header_contact"
                  value={values?.header_contact}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ColorPickerField
                  fieldName={t("Button Color")}
                  value={values?.button_background_color || "#bc1e7b"}
                  name="button_background_color"
                  error={errors?.button_background_color}
                  handleChange={handleChange}
                  id="button_background_color"
                />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h2" sx={{ p: 1, mt: 2 }}>
                    {t("Banner Content")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Banner Heading")}
                  values={values?.banner_heading || ""}
                  name="banner_heading"
                  errors={errors?.banner_heading}
                  handleChange={handleChange}
                  id="banner_heading"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Banner text")}
                  values={values?.banner_text || ""}
                  name="banner_text"
                  errors={errors?.banner_text}
                  handleChange={handleChange}
                  id="banner_text"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ColorPickerField
                  fieldName={t("Theme Color")}
                  value={values?.theme_color || "#bc1e7b"}
                  name="theme_color"
                  error={errors?.theme_color}
                  handleChange={handleChange}
                  id="theme_color"
                />
              </Grid>
              {/* <Grid item xs={12}>
              <Box>
                <Typography variant="h2" sx={{ p: 1, mt: 2 }}>
                  Testimonials Content
                </Typography>
              </Box>
            </Grid> */}
              {/* <Grid item xs={12} lg={4}>
              <InputTextAreaField
                fieldName="Testimonials 1"
                values={values?.testimonials1_content || ""}
                name="testimonials1_content"
                errors={errors?.testimonials1_content}
                handleChange={handleChange}
                id="testimonials1_content"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputTextAreaField
                fieldName="Testimonials 2"
                values={values?.testimonials2_content || ""}
                name="testimonials2_content"
                errors={errors?.testimonials2_content}
                handleChange={handleChange}
                id="testimonials2_content"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputTextAreaField
                fieldName="Testimonials 3"
                values={values?.testimonials3_content || ""}
                name="testimonials3_content"
                errors={errors?.testimonials3_content}
                handleChange={handleChange}
                id="testimonials3_content"
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputField
                fieldName="Avatar Name 1"
                values={values?.testimonials1_avatar || ""}
                name="testimonials1_avatar"
                errors={errors?.testimonials1_avatar}
                handleChange={handleChange}
                id="ttestimonials1_avatar"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputField
                fieldName="Avatar Name 2"
                values={values?.testimonials2_avatar || ""}
                name="testimonials2_avatar"
                errors={errors?.testimonials2_avatar}
                handleChange={handleChange}
                id="testimonials2_avatar"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputField
                fieldName="Avatar Name 3"
                values={values?.testimonials3_avatar || ""}
                name="testimonials3_avatar"
                errors={errors?.testimonials3_avatar}
                handleChange={handleChange}
                id="testimonials3_avatar"
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputField
                fieldName="Company 1"
                values={values?.testimonials1_company || ""}
                name="testimonials1_company"
                errors={errors?.testimonials1_company}
                handleChange={handleChange}
                id="testimonials1_company"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputField
                fieldName="Company 2"
                values={values?.testimonials2_company || ""}
                name="testimonials2_company"
                errors={errors?.testimonials2_company}
                handleChange={handleChange}
                id="testimonials2_company"
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputField
                fieldName="Company 3"
                values={values?.testimonials3_company || ""}
                name="testimonials3_company"
                errors={errors?.testimonials3_company}
                handleChange={handleChange}
                id="testimonials3_company"
              />
            </Grid> */}
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h2" sx={{ p: 1, mt: 2 }}>
                    {t("Footer Content")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("what we can do section")}
                  values={values?.what_we_do_section || ""}
                  name="what_we_do_section"
                  errors={errors?.what_we_do_section}
                  handleChange={handleChange}
                  id="what_we_do_section"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Address section")}
                  values={values?.address_section || ""}
                  name="address_section"
                  errors={errors?.address_section}
                  handleChange={handleChange}
                  id="address_section"
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Licence Number")}
                  values={values?.license || ""}
                  name="license"
                  errors={errors?.license}
                  handleChange={handleChange}
                  id="license"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Tag Line")}
                  values={values?.tag_line || ""}
                  name="tag_line"
                  errors={errors?.tag_line}
                  handleChange={handleChange}
                  id="tag_line"
                />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h2" sx={{ p: 1, mt: 2 }}>
                   {t("Portal Logo")}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} pt={3}>
                <WithFileUpload
                  fileAdded={!!values?.path}
                  fileName={shortenFileName(values?.path)}
                  actionText={"Add"}
                  handleRemoveFile={handleRemoveFile}
                  handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFileChange(e)
                  }
                  errors={errors?.path}
                  fieldName={t("Portal Logo")}
                  fileType={"image/*"}
                  helpText={t("Image only")}
                  buttonText={t("Upload File")}
                  maxmiumText={t("Maximum file size 10mb")}
                />
              </Grid>
            </Grid>
          </FormLayout>
        </Box>
      </MainContainerWrapper>
    </>
  );
}

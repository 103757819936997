import { ConfirmationNumber } from "@mui/icons-material";
import { yellow } from "@mui/material/colors";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

type deletealertProps = {
  onCancel?: Function;
  onConfirmed?: Function;
  title?: string;
  message?: string;
  confirmButtonText?: string;
  data?: any;
};

const deleteAlert = ({
  onConfirmed,
  title,
  message,
  confirmButtonText,
  data = null,
  onCancel,
}: deletealertProps) => {
//   const { t } = useTranslation();
  return Swal.fire({
    title: title,
    text: message,
    icon: "warning",
    confirmButtonText: confirmButtonText,
    cancelButtonText: t("No"),
    confirmButtonColor: "red",
    showCancelButton: true,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed && onConfirmed) {
      onConfirmed(data);
    } else if (result.dismiss && onCancel) {
      onCancel();
    }
  });
};

export default deleteAlert;

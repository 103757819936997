import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Box, Button, IconButton } from "@mui/material";
import { getCroppedImg } from "helpers/function.helper";
import ModalComponent from "component/modal";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

interface ImageCropperProps {
  image: string;
  onCrop: (croppedImage: string) => void;
  onClose: () => void;
  handleFile: (_: any) => void;
  imageType: string;
  open: any;
  handleClose: any;
  aspect: number;
  cropShape: 'rect' | 'round';
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  image,
  onCrop,
  onClose,
  handleFile,
  imageType,
  open,
  handleClose,
  aspect,
  cropShape,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCrop = async () => {
    const file = await getCroppedImg(image, croppedAreaPixels, imageType);
    handleFile(file);
  };

  // Handle zoom in
  const handleZoomIn = () => {
    setZoom(prevZoom => Math.min(prevZoom + 0.1, 3)); // Limit maximum zoom level
  };

  // Handle zoom out
  const handleZoomOut = () => {
    setZoom(prevZoom => Math.max(prevZoom - 0.1, 1)); // Limit minimum zoom level
  };

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      customeStyle={{ width: "60%", height: "60%" }}
    >
      <>
        <Box sx={{ position: "relative", width: "100%", height: "85%" }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            cropShape={cropShape}
            onCropComplete={onCropComplete}
          />
        </Box>
        <Box sx={{ height: "15%", display: "flex", justifyContent: "flex-end", alignItems: "center", mt:2 }}>
          <IconButton onClick={handleZoomIn} aria-label="Zoom In" sx={{ mr: 1 }}>
            <ZoomInIcon />
          </IconButton>
          <IconButton onClick={handleZoomOut} aria-label="Zoom Out" sx={{ mr: 2 }}>
            <ZoomOutIcon />
          </IconButton>
          <Button onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleCrop} variant="contained" >
            Crop
          </Button>
        </Box>
      </>
    </ModalComponent>
  );
};

export default ImageCropper;

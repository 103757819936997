import { Box, Button, Stack, useTheme } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useAppSelector } from "store/store";
import { useTranslation } from "react-i18next"; 


const FormLayout = ({
  handleSubmit,
  children,
  backButtonText,
  submitButtonText,
  backButtonClick,
  skipButtonClick,
  isBackButton,
  scrollable,
  disableSave = false,
  isSaveButton = true,
  isSkip = false,
}: FormTypes) => {
  const theme = useTheme();
  const settings = useAppSelector((state) => state.settings.settings);
  const handleForm = (e: any) => {
    e?.stopPropagation();
    handleSubmit(e)
  }
  const { t } = useTranslation();
  return (
    <Stack
      component={"form"}
      flexGrow={1}
      height={"100%"}
      width={"100%"}
      noValidate
      onSubmit={handleForm}
      justifyContent={"space-between"}
    >
      <Box
        //maxHeight={"calc(75vh - 11vh)"}
        width={"100%"}
        sx={{
          overflowY: scrollable ? scrollable : "auto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
        }}
        // px={scrollable ? 0 : "30px"}
      >
        {children}
      </Box>
      {/* <PerfectScrollbar
        component="div"
        style={{
          // height: 'calc(100vh - 280px)',
          height: "calc(80vh - 150px)",
          padding: '0 20px'
        }}
      >
        {children}
        </PerfectScrollbar> */}
      <Box sx={{ pt: 2 }} display={"flex"} justifyContent={"right"}>
        <Box sx={{ mr: 2 }}>
          {isSkip && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightgray",
                color: "#121926",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={skipButtonClick}
            >
              {"Skip"}
            </Button>
          )}
        </Box>
        <Box sx={{ mr: 2 }}>
          {isBackButton && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightgray",
                color: "#121926",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={backButtonClick}
            >
              {backButtonText || t("Cancel")}
            </Button>
          )}
        </Box>
        <Box>
          {isSaveButton && (
            <Button
              variant="contained"
              disabled={disableSave}
              type="submit"
              sx={{
                fontSize:{
                  xs: "13px",
                }
              }}
            >
              {submitButtonText || t("Save_&_Proceed")}
            </Button>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

type FormTypes = {
  handleSubmit?: any;
  children: any;
  submitButtonText?: string;
  backButtonText?: string;
  isBackButton?: boolean;
  isSubmitButton?: boolean;
  backButtonClick?: any;
  skipButtonClick?: any;
  scrollable?: any;
  disableSave?: boolean;
  isSaveButton?: boolean;
  isSkip?: boolean;
};
export default FormLayout;

import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { commonService } from "forms/helpers/common.service";
import Loading from "container/loading";
import { getFormatedDisplayTime } from "forms/helpers/function.helper";
import { capitalizeFirstLetter } from "forms/hooks/customHook";

// Define the type for notification items
interface NotificationItem {
  navigation: string;
  message: string;
  created_at: string;
  data: string; // Adjust this type according to the actual data structure
}

const NotificationSection = () => {
  const theme = useTheme();
  const [isloading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [notificationList, setNotificationList] = useState<NotificationItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getNotificationList();
  }, []);

  // Function to fetch notification list
  const getNotificationList = () => {
    setLoading(true);
    commonService
      .postService(`/notification/list`, { page: page.toString(), limit })
      .then((response: any) => {
        setNotificationList(response.data?.data?.results);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // Function to handle notification click based on its navigation type
  const handleNotification = (item: NotificationItem) => {
    if (item.navigation === "customer") {
      navigate(`/customers/view/${item.data}`);
    } else if (item.navigation === "policy") {
      navigate(`/customers/policy/view/${item.data}`);
    } else if (item.navigation === "quotation") {
      navigate(`/quote/view/${item.data}`);
    }
  };

  return (
    <Box>
      <List component="nav">
        {isloading ? (
          <Loading />
        ) : (
          <Box height={"80%"} sx={{ overflowY: "auto" , bgcolor: "white"}}>
            <Typography
              sx={{
                fontSize: "1rem",
                p: "0.7rem",
                fontWeight: 700,
                borderBottom: "1px solid #BEBDBD",
              }}
            >
              Notifications
            </Typography>
            <Box>
              {notificationList.map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ cursor: "pointer", px: "1rem", borderBottom: "1px solid #BEBDBD" }}
                  onClick={() => handleNotification(item)}
                >
                  <Stack sx={{ width: "100%" }}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          my: "0.8rem",
                          fontWeight: 700,
                        }}
                      >
                        {capitalizeFirstLetter(item.navigation)}
                      </Typography>
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "0.9rem",
                          mb: "0.8rem",
                          fontWeight: 400,
                        }}
                      >
                        {item.message}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          mb: "0.8rem",
                          color: theme.palette.grey[500],
                          fontWeight: 400,
                        }}
                      >
                        {getFormatedDisplayTime(item.created_at)}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              ))}
            </Box>
          </Box>
        )}
      </List>
    </Box>
  );
};

export default NotificationSection;

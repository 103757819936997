import { Box, Button, Grid , IconButton } from "@mui/material";
import EnhancedTable from "component/tables/selectableTable";
import { commonService } from "forms/helpers/common.service";
import TextView from "forms/hoc/text-view";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import Loader from "ui-component/Loader";
import { addressHeadCellView } from "utils/table.utils";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const BankView = ({ id, handleBack } : { id: string | undefined, handleBack?: any; }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bankData, setBankData] = useState<any>({});
  const [address, setAddress] = useState<any[]>([]);
  const [isAccountMasked, setIsAccountMasked] = useState(true);

  function getBankInfo() {
    setIsLoading(true);
    commonService
      .getServices(`/tenant-bank/${id}`)
      .then((res) => {
        setIsLoading(false);
        const data: any = res?.data?.data;

        const addresses = data?.tenantBankAddresses?.map((data: any) => {
          return {
            ...data,
            address_type: data?.address_type?.id,
            state: data?.state?.id,
            statename: data?.state?.name,
          };
        });
        setAddress(addresses);
        setBankData(data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getBankInfo();
  }, []);

  const togglePhoneMask = () => {
    setIsAccountMasked(!isAccountMasked);
  };

  return (
    <MainContainerWrapper headingText={"View Bank"}>
      {isLoading && <Loader />}
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName="Bank"
              fieldValue={bankData?.bank?.values ? bankData?.bank?.values : "-"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName="Routing No"
              fieldValue={bankData?.routing_no ? bankData?.routing_no : "-"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3} display="flex" alignItems="center">
          <Box p={1}>
            <TextView
              fieldName="Account No"
              fieldValue={isAccountMasked ? "*********" : bankData?.account_no || "-"}
            />
          </Box>
          <IconButton onClick={togglePhoneMask} sx={{ marginTop: '5px' }}>
              {isAccountMasked ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName="Account Type"
              fieldValue={
                bankData?.account_type?.values
                  ? bankData?.account_type?.values
                  : "-"
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName="Default Address"
              fieldValue={
                bankData?.tenantBankAddresses?.length &&
                bankData?.tenantBankAddresses.find(
                  (item: any) => item?.is_default === 1
                )
                  ? `${
                      bankData?.tenantBankAddresses.find(
                        (item: any) => item?.is_default === 1
                      )?.address_line_1
                    }`
                  : "-"
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName="Starting Check No"
              fieldValue={
                bankData?.starting_check_no ? bankData?.starting_check_no : "-"
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName="Account Name"
              fieldValue={
                bankData?.name_of_account ? bankData?.name_of_account : "-"
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box p={1}>
            <TextView
              fieldName="Entity Type"
              fieldValue={
                bankData?.entity_type?.values
                  ? bankData?.entity_type?.values
                  : "-"
              }
            />
          </Box>
        </Grid>
        <Grid item lg={6} sx={{ mt: 2 }}>
          <EnhancedTable
            headCells={addressHeadCellView}
            rows={address}
            isPagination={false}
            width={"100%"}
          />
        </Grid>
      </Grid>
      <Box sx={{ pt: 2 }} display={"flex"} justifyContent={"right"}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightgray",
                color: "#121926",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
                ml: 2,
              }}
              onClick={() => {
                handleBack();
              }}
            >
              {"Back"}
            </Button>
          </Box>
    </MainContainerWrapper>
  );
};

export default BankView;

import { Link, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";

/* Text view type */
interface TextViewType {
  fieldName: string;
  fieldValue: any;
  alignment?: string;
  adoramentText?: string;
  isHtmlContent?: boolean;
  isLinkText?: boolean;
  onClick?: () => void; // Add this line
}

/* Text view type */
interface TextViewLinkType {
  fieldName: string;
  fieldValue: string;
  alignment?: string;
  type: "email" | "phone";
}

const TextView = ({
  fieldName,
  fieldValue,
  alignment = "flex-start",
  adoramentText,
  isHtmlContent = false,
  isLinkText = false,
  onClick, // Add this line
}: TextViewType) => {
  const theme = useTheme();

  const commonStyles = {
    color: theme.palette.text.headingText,
    fontSize: "1rem",
    letterSpacing: "0.5px",
    fontWeight: 600,
    lineHeight: "40px",
    opacity: 0.95,
    wordBreak: "break-all",
  };

  const linkStyles = {
    ...commonStyles,
    color: "#0000EE",
    textDecoration: "underline", // Underline the link text
    cursor: onClick ? "pointer" : "default", // Change cursor if onClick is present

  };
  return (
    <Box>
      <Stack direction={"column"} alignItems={alignment}>
        <Typography
          sx={{
            color: theme.palette.grey[500],
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 500,
            wordBreak: "break-all",
          }}
        >
          {fieldName}
        </Typography>
        {isHtmlContent ? (
          <div
            style={{
              color: theme.palette.text.headingText,
              fontSize: "1rem",
              letterSpacing: "0.5px",
              fontWeight: 600,
              lineHeight: "40px",
              opacity: 0.95,
              wordBreak: "break-all",
            }}
            dangerouslySetInnerHTML={{ __html: fieldValue }}
          />
        ) : (
          <Typography
          sx={isLinkText ? linkStyles : commonStyles}  // Apply styles based on isLinkText
            onClick={onClick}  // Add this line
          >
            {fieldValue}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default TextView;

export const TextViewLink = ({
  fieldName,
  fieldValue,
  alignment = "flex-start",
  type,
}: TextViewLinkType) => {
  const theme = useTheme();
  return (
    <Box>
      <Stack direction={"column"} alignItems={alignment}>
        <Typography
          sx={{
            color: theme.palette.grey[500],
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 500,
            wordBreak: "break-all",
          }}
        >
          {fieldName}
        </Typography>
        {type === "email" ? (
          <Box
            component={"a"}
            href={`mailto:${fieldValue}`}
            sx={{
              fontSize: ".9rem",
              letterSpacing: "0.5px",
              fontWeight: 500,
              textDecoration: "none",
              wordBreak: "break-all",
              pt: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                letterSpacing: "0.5px",
                fontWeight: 300,
                lineHeight: "1.5rem",
                wordBreak: "break-all",
              }}
            >
              {fieldValue}
            </Typography>
          </Box>
        ) : type === "phone" ? (
          <Box
            component={"a"}
            href={`tel:${fieldValue}`}
            sx={{
              fontSize: ".9rem",
              letterSpacing: "0.5px",
              fontWeight: 500,
              textDecoration: "none",
              wordBreak: "break-all",
              pt: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                letterSpacing: "0.5px",
                fontWeight: 300,
                lineHeight: "1.5rem",
                wordBreak: "break-all",
              }}
            >
              {fieldValue}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  );
};

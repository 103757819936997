import { Roles } from "helpers/enum.helpers";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "store/store";

const ProtectedUserRoute = (props: any) => {
    let auth = useAppSelector((state) => state.user.user);
    let location = useLocation();
    return props?.roles.some((r: any) => auth?.role?.name?.includes(r))? 
    <Outlet />
    : Object.keys(auth).length > 0 ? (
       <Navigate to={ (auth?.role?.name === Roles.Customer) ?`/customer/dashboard`: `/dashboard`} state={{ from: location }} replace />
    ) : (
      <Navigate to="/quote" state={{ from: location }} replace />
    );
  };


  export default ProtectedUserRoute
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IBlocker {
  blocker: any;
}

const initialState: IBlocker = {
  blocker: {},
};

export const blockerSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    setBlocker(state, action: PayloadAction<any>) {
      state.blocker = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    }),
});

export const { setBlocker } = blockerSlice.actions;
export default blockerSlice.reducer;


import { Box, Button, Grid } from '@mui/material'
import FormLayout from 'component/form'
import { commonService } from 'forms/helpers/common.service';
import { AutoCompleteField, InputField, PhoneField, SelectField } from 'forms/hoc/formfield';
import useForm from 'forms/hooks/useForm';
import { createLocationSchema, createLocationValidation, createReferenceValidation, createReferenceValidationSchema } from 'forms/hooks/validateRules';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setReferenceID } from 'store/slice/reference.slice';
import { useAppDispatch } from 'store/store';

function AgencyInformation({
    handleNextTab
}: AgencyInformationType) {


    const [stateList, setStateList] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();



    const initialValues = {
        reference_name: null,
        reference_type: null,
        of_agent: null,
        date_terminated: null,
        agency_name: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        zip_code: null,
        phone: null,
        fax: null,
        email: null,
        agency_code: null,
        license_number: null,
        tax_id_number: null,
    };

    const dispatch = useAppDispatch();

    useEffect(() => {
        getStateList()
    }, [])

    // Get the State List
    const getStateList = async () => {
        try {
            const stateResponse = await commonService.getServices(`/state`);
            if (stateResponse) {
                let stateList: any = stateResponse?.data?.data;
                setStateList(stateList);
            }
        } catch (error) { }
    };
    const handleBack = () => {
        navigate(-1);
    };


    const formSubmit = () => {
        dispatch(setReferenceID("FromIDDDDDDDDD"))
        handleNextTab();
    }

    const ReferenceCreateT = createReferenceValidationSchema(t);

    const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,
        setUpdateFormValue,

    } = useForm(initialValues, formSubmit, createReferenceValidation, ReferenceCreateT);


    return (
        <Box sx={{ p: 3, height: "auto", width: "100%" }}>
            <FormLayout
                handleSubmit={handleSubmit}
                isBackButton
                backButtonClick={handleBack}
                backButtonText={t("Cancel")}
                isSaveButton>
                <>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("reference_name")}
                                values={values?.reference_name || ""}
                                name="reference_name"
                                errors={errors?.reference_name}
                                handleChange={handleChange}
                                id="reference_name"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("reference_type")}
                                values={values?.reference_type || ""}
                                name="reference_type"
                                errors={errors?.reference_type}
                                handleChange={handleChange}
                                id="reference_type"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("of_agent")}
                                values={values?.of_agent || ""}
                                name="of_agent"
                                errors={errors?.of_agent}
                                handleChange={handleChange}
                                id="of_agent"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("agency_name")}
                                values={values?.agency_name || ""}
                                name="agency_name"
                                errors={errors?.agency_name}
                                handleChange={handleChange}
                                id="agency_name"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("address_line_1")}
                                values={values?.address_line_1 || ""}
                                name="address_line_1"
                                errors={errors?.address_line_1}
                                handleChange={handleChange}
                                id="address_line_1"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("address_line_2")}
                                values={values?.address_line_2 || ""}
                                name="address_line_2"
                                errors={errors?.address_line_2}
                                handleChange={handleChange}
                                id="address_line_2"
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("city")}
                                values={values?.city || ""}
                                name="city"
                                errors={errors?.city}
                                handleChange={handleChange}
                                id="city"
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <SelectField
                                fieldName={t("State")}
                                values={values?.state || ""}
                                name="state"
                                errors={errors?.state}
                                handleChange={handleChange}
                                options={stateList || []}
                                id="state"
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("zip_code")}
                                values={values?.zip_code || ""}
                                name="zip_code"
                                errors={errors?.zip_code}
                                handleChange={handleChange}
                                id="zip_code"
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <PhoneField
                                fieldName={t("phone")}
                                value={values?.phone || ""}
                                name="phone"
                                errors={errors?.phone}
                                handleChange={handleChange}
                                id="phone"
                                isRequired={false}
                            />
                        </Grid>

                        <Grid item xs={12} lg={4}>
                            <PhoneField
                                fieldName={t("fax")}
                                value={values?.fax || ""}
                                name="fax"
                                errors={errors?.fax}
                                handleChange={handleChange}
                                id="fax"
                                isRequired={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("email")}
                                values={values?.email || ""}
                                name="email"
                                errors={errors?.email}
                                handleChange={handleChange}
                                id="email"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("agency_code")}
                                values={values?.agency_code || ""}
                                name="agency_code"
                                errors={errors?.agency_code}
                                handleChange={handleChange}
                                id="agency_code"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("license_number")}
                                values={values?.license_number || ""}
                                name="license_number"
                                errors={errors?.license_number}
                                handleChange={handleChange}
                                id="license_number"
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InputField
                                fieldName={t("tax_id_number")}
                                values={values?.tax_id_number || ""}
                                name="tax_id_number"
                                errors={errors?.tax_id_number}
                                handleChange={handleChange}
                                id="tax_id_number"
                            />
                        </Grid>
                    </Grid>
                </>
            </FormLayout>
        </Box>
    )
}

export default AgencyInformation


type AgencyInformationType = {
    handleNextTab: any
}


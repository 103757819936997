import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Chip,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./selectedTable.scss";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Switch, { SwitchProps } from "@mui/material/Switch";
import filtericon from "../../asset/images/filtericon.svg";
import { hexToRgb, styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  dateFormat,
  dateTimeFormatWithoutSecond,
  maskPhoneNumber,
  maskAccountNumber,
  parsePhoneNumberformat,
  shortenFileName,
  parsePhoneFormatNumber,
} from "forms/hooks/customHook";
import NoRecordFound from "component/tables/NoRecordFound";
import { formatLicense } from "forms/hooks/customHook";
import PaginationComponent from "../pagination";
import IosShareIcon from "@mui/icons-material/IosShare";
import ResendMail from "../../asset/images/mail-resend-icon.svg";
import MailVerified from "../../asset/images/mail-verified.svg";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RedoIcon from "@mui/icons-material/Redo";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PaymentIcon from "@mui/icons-material/Payment";
import { PaymentStatus } from "helpers/enum.helpers";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector } from "store/store";
import HoverImagePopover from "forms/hoc/formfield/helperText";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UpgradeTwoToneIcon from '@mui/icons-material/UpgradeTwoTone';
import { t } from "i18next";
import { DriveFileMove } from "@mui/icons-material";

function EnhancedTableHead(props: EnhancedTableHeadTypes) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isSelectable,
    isSingleCheck,
  } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isSelectable && (
          <TableCell
            padding="checkbox"
            sx={isSingleCheck ? { border: "0" } : {}}
          >
            {!isSingleCheck && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            )}
          </TableCell>
        )}
        {headCells.map((headCell: any, index: number) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align={
              headCell.id === "action" || headCell.id === "status"
                ? "center"
                : headCell?.align ?? "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="tableheading"
            sx={{ border: "0" }}
          >
            {headCell?.isSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}{" "}
                <img
                  src={filtericon}
                  alt="filterimage"
                  className="filterimage"
                />
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>
                {" "}
                {headCell.label}
                {headCell.label === "Valid" && (
                  <HoverImagePopover
                    content={
                      "Please provide valid and unique Phone Number and Email ID for each of the entries."
                    }
                  />
                )}
              </span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type EnhancedTableHeadTypes = {
  numSelected: number;
  onRequestSort: (_: any, __: any) => void;
  onSelectAllClick: (_: any) => void;
  order: "asc" | "desc";
  orderBy: string;
  rowCount: number;
  headCells: any[];
  isSelectable: boolean;
  isSingleCheck?: boolean;
};

type EnhancedTableTypes = {
  page?: number;
  rowsPerPage?: number;
  handleChangePage?: (_: any, __: any) => void;
  handleChangeRowsPerPage?: (_: any) => void;
  headCells: any[];
  handleRequestSort?: (_: any, __: any) => void;
  rows: any[];
  order?: "asc" | "desc";
  orderBy?: string;
  isSelectable?: boolean;
  selected?: any;
  setSelected?: (_: any) => void;
  total?: number;
  actionFunction?: (_: string, __: string, ___?: any, ____?: any) => void;
  resendMail?: (_: any) => void;
  isEdit?: boolean;
  isView?: boolean;
  isCancel?: boolean;
  isRenewal?: boolean;
  isreInvite?: boolean;
  isDelete?: boolean;
  isMove?: boolean;
  isCarrier?: boolean;
  isKyc?: boolean;
  isMail?: boolean;
  isActionButton?: boolean;
  isActionPaymentButton?: boolean;
  isMoreVert?: boolean;
  setActive?: (_: string, __: boolean) => void;
  reInvite?: (_: string) => void;
  redoKyc?: (_: string) => void;
  isLoading?: boolean;
  Openmodal?: (_: string, __: string) => void;
  isPagination?: boolean;
  isNoRecordFound?: boolean;
  isSingleCheck?: boolean;
  maxHeightValue?: any;
  HeightValue?: any;
  isCheckedRound?: boolean;
  width?: string | number;
  isExport?: boolean;
  isDownload?: boolean;
  routingLink?: boolean;
  isEndorsement?: boolean;
};
type TableCellType = {
  row: any;
  head: any;
  i: number;
  isMail: boolean;
  resendMail?: (_: any) => void;
  isView: boolean;
  isreInvite: boolean;
  isKyc: boolean;
  actionFunction?: (_: string, __: string, ___?: any, ____?: any) => void;
  isEdit?: boolean;
  isDelete?: boolean;
  isMove?: boolean;
  isCancel?: boolean;
  isRenewal?: boolean;
  isCarrier?: boolean;
  isActionButton?: boolean;
  isActionPaymentButton?: boolean;
  isMoreVert?: boolean;
  Openmodal?: (_: string, __: string) => void;
  setActive?: (_: string, __: boolean) => void;
  redoKyc?: (_: string) => void;
  reInvite?: (_: string) => void;
  isExport?: boolean;
  isDownload?: boolean;
  routingLink: boolean;
  isEndorsement?: boolean;
};
const EnhancedTableCell = ({
  row,
  head,
  i,
  isMail,
  resendMail,
  isView,
  isreInvite,
  isKyc,
  actionFunction,
  isEdit,
  isDelete,
  isMove,
  isCarrier,
  isActionButton,
  isActionPaymentButton,
  isMoreVert,
  isCancel,
  isRenewal,
  Openmodal,
  setActive,
  reInvite,
  redoKyc,
  isExport,
  isDownload,
  routingLink,
  isEndorsement,

}: TableCellType) => {
  const backgroundColor = row?.color_code ? hexToRgb(row?.color_code) : "";

  const [flg_mailVerified, setFlg_mailVerified] = React.useState(false);
  const [isHover_mailVerified, setIsHover_mailVerified] = React.useState(false);

  const [flg_isReinvite, setFlg_isReinvite] = React.useState(false);
  const [isHover_isReinvite, setIsHover_isReinvite] = React.useState(false);

  const [flg_isKyc, setFlg_isKyc] = React.useState(false);
  const [isHover_isKyc, setIsHover_isKyc] = React.useState(false);

  const [flg_isView, setFlg_isView] = React.useState(false);
  const [isHover_isView, setIsHover_isView] = React.useState(false);

  const [flg_isEdit, setFlg_isEdit] = React.useState(false);
  const [isHover_isEdit, setIsHover_isEdit] = React.useState(false);

  const [flg_isDelete, setFlg_isDelete] = React.useState(false);
  const [isHover_isDelete, setIsHover_isDelete] = React.useState(false);

  const [flg_isMove, setFlg_isMove] = React.useState(false);
  const [isHover_isMove, setIsHover_isMove] = React.useState(false);

  const [flg_isMore, setFlg_isMore] = React.useState(false);
  const [isHover_isMore, setIsHover_isMore] = React.useState(false);

  const [flg_export, setFlg_export] = React.useState(false);
  const [isHover_export, setIsHover_export] = React.useState(false);

  const [flg_cancel, setFlg_cancel] = React.useState(false);
  const [isHover_cancel, setIsHover_cancel] = React.useState(false);

  const [isPhoneMasked, setIsPhoneMasked] = React.useState(true);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const settings = useAppSelector((state) => state.settings.settings);
  const IOSSwitch = styled((props: SwitchProps, checked: any) => (
    // <Switch
    //   focusVisibleClassName=".Mui-focusVisible"
    //   disableRipple
    //   {...props}
    // />
    <Switch
      checked={checked}
      {...props}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      // onChange={onchange}
      // inputProps={{ "aria-label": "controlled" }}
      sx={{ color: "#283891" }}
    />
  ))(({ theme }) => ({
    width: 37,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "#2ECA45"
              : `${
                  settings?.button_background_color
                    ? settings?.button_background_color
                    : theme.palette.primary.buttonColor
                }`,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      marginTop: 1,
      width: 15,
      height: 15,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  if (row[head]) {
    return (
      <Typography variant="subtitle1" fontWeight="bold">
        {row[head.id]}
      </Typography>
    );
  }
  switch (head.id) {
    case "name":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
            textDecoration: head?.isClickable ? "underline" : "none",
          }}
          onClick={() =>
            head?.isClickable &&
            actionFunction &&
            actionFunction(row.id, "click", i)
          }
        >
          {row[head.id]}
        </div>
      );
    case "company_name":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "firstname":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );
    case "status":
      return (
        <Box display={"flex"} justifyContent="center">
          <Chip
            label={row[head.id]}
            sx={{
              background: row.actionBackgroundColor,
              borderRadius: "5px",
              minWidth: "8rem",
              color: row.color_code,
              fontSize: "1rem",
              fontWeight: "600",
            }}
          />
        </Box>
      );
    case "vehicle_identification_number":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          {row[head.id]}
        </div>
      );

    case "email":
      return (
        <Box
          component={"a"}
          href={`mailto:${row[head.id]}`}
          sx={{
            // color: "black",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 200,
            textDecoration: "none",
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 500,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >
            {row[head.id]}
          </Typography>
        </Box>
      );
    case "mobile_number":
      return (
        <Box
          component={"a"}
          href={`tel:${row[head.id]}`}
          sx={{
            // color: "blue",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 200,
            textDecoration: "none",
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 200,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >
            {maskPhoneNumber(
              parsePhoneNumberformat(row.country_code, row?.mobile_number)
            )}
          </Typography>
        </Box>
      );

    case "phone_number":
      return (
        <Box
          component={"a"}
          href={`tel:${row[head.id]}`}
          sx={{
            // color: "blue",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 500,
            textDecoration: "none",
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 200,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >
            {maskPhoneNumber(row[head.id])}
          </Typography>
        </Box>
      );

    case "phone":
      // const [isPhoneMasked, setIsPhoneMasked] = useState(true);
      // const togglePhoneMask = () => {
      //   setIsPhoneMasked((prevState) => !prevState);
      // };
      const togglePhoneMask = () => {
        setIsPhoneMasked((prevState) => !prevState);
      };

      return (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 500,
            textDecoration: "none",
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Box
            component="a"
            href={`tel:${row[head.id]}`}
            sx={{ textDecoration: "none" }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                letterSpacing: "0.5px",
                fontWeight: 200,
                lineHeight: "1.5rem",
                wordBreak: "break-all",
              }}
            >
              {isPhoneMasked
                ? maskPhoneNumber(
                    parsePhoneFormatNumber(
                      parsePhoneNumberformat(row.country_code, row.phone) || ""
                    )
                  )
                : parsePhoneFormatNumber(
                    parsePhoneNumberformat(row.country_code, row.phone) || ""
                  )}
            </Typography>
          </Box>
          {head.skipIconButton && (
            <IconButton onClick={togglePhoneMask} sx={{ marginTop: "-7px" }}>
              {isPhoneMasked ? (
                <VisibilityIcon sx={{ fontSize: "15px" }} />
              ) : (
                <VisibilityOffIcon sx={{ fontSize: "15px" }} />
              )}
            </IconButton>
          )}
        </Box>
      );

    // return (
    //   <Box
    //     component={"a"}
    //     href={`tel:${row[head.id]}`}
    //     sx={{
    //       fontSize: ".9rem",
    //       letterSpacing: "0.5px",
    //       fontWeight: 500,
    //       textDecoration: "none",
    //       wordBreak: "break-all",
    //       pt: 1,
    //     }}
    //   >
    //     <Typography
    //       sx={{
    //         fontSize: "14px",
    //         letterSpacing: "0.5px",
    //         fontWeight: 200,
    //         lineHeight: "1.5rem",
    //         wordBreak: "break-all",
    //       }}
    //     >
    //       {maskPhoneNumber(
    //         parsePhoneNumberformat(row.country_code, row?.phone)
    //       )}
    //     </Typography>
    //   </Box>
    // );

    case "action":
      return (
        <Stack flexDirection="row" justifyContent="space-evenly">
          {isMail && (
            <>
              {!row?.email_verified ? (
                <Tooltip
                  title="Resend Verification Email"
                  open={!flg_mailVerified && isHover_mailVerified}
                >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    // aria-controls={open ? "long-menu" : undefined}
                    // aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={() => {
                      setFlg_mailVerified(!flg_mailVerified);
                      resendMail && resendMail(row.id);
                    }}
                    onMouseOver={() => setIsHover_mailVerified(true)}
                    onMouseLeave={() => setIsHover_mailVerified(false)}
                  >
                    {/* <MailOutlineIcon sx={{ color: "#283891" }} /> */}
                    <img src={ResendMail} width="24" />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip
                    title="Email Verified"
                    open={!flg_mailVerified && isHover_mailVerified}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      // aria-controls={open ? "long-menu" : undefined}
                      // aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onMouseOver={() => setIsHover_mailVerified(true)}
                      onMouseLeave={() => setIsHover_mailVerified(false)}
                    >
                      {/* <MailOutlineIcon
                        sx={{ color: "#283891", opacity: "0.4" }}
                      /> */}
                      <img
                        src={MailVerified}
                        style={{ opacity: "0.4" }}
                        width="24"
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
          {isView && (
            <Tooltip title={t("View")} open={!flg_isView && isHover_isView}>
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                disabled= {
                  row?.is_advance_payment
                }
                onClick={() => {
                  setFlg_isView(!flg_isView);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "view", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isView(true)}
                onMouseLeave={() => setIsHover_isView(false)}
              >
                <RemoveRedEyeOutlinedIcon
                  // sx={{ color: theme.palette.primary.buttonColor }}
                  sx={{
                    color:
                    row?.is_advance_payment?
                    "#00000040"
                    :`${
                      settings?.button_background_color
                        ? settings.button_background_color
                        : theme.palette.primary.buttonColor
                    }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isreInvite && (
            <Tooltip
              title="Reinvite"
              open={!flg_isReinvite && isHover_isReinvite}
            >
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isReinvite(!flg_isReinvite);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "reinvite", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isReinvite(true)}
                onMouseLeave={() => setIsHover_isReinvite(false)}
              >
                {/* <RedoIcon sx={{ color: theme.palette.primary.buttonColor }} /> */}
                <PersonAddAltIcon
                  sx={{ color: theme.palette.primary.buttonColor }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isKyc && (
            <Tooltip title="RedoKYC" open={!flg_isKyc && isHover_isKyc}>
              <IconButton
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isKyc(!flg_isKyc);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "redo", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isKyc(true)}
                onMouseLeave={() => setIsHover_isKyc(false)}
              >
                <RedoIcon sx={{ color: theme.palette.primary.buttonColor }} />
              </IconButton>
            </Tooltip>
          )}
          {isEdit && (
            <Tooltip title={t("Edit")} open={!flg_isEdit && isHover_isEdit}>
              <IconButton
                disabled={
                  row?.policy_commission_status === "Yet to pay" ||
                  row?.policy_commission_status === "Received" ||
                  row?.policy_commission_status === "Cancelled" ||
                  row?.policy_commission_status === "In Progress" ||
                  row?.status === "Suspended" ||
                  row?.status === "Cancel" ||
                  row?.status === "Expired" ||
                  row?.status === "Rejected" ||
                  row?.cheque_status === "Processed" ||
                  row?.cheque_status === "Cancelled"
                }
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isEdit(!flg_isEdit);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "edit", i, row);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isEdit(true)}
                onMouseLeave={() => setIsHover_isEdit(false)}
              >
                <EditOutlinedIcon
                  // sx={{ color: (row?.status === "Cancel" || row?.status === "Rejected")? "#00000040" : theme.palette.primary.buttonColor }}

                  sx={{
                    color:
                      row?.status === "Suspended" ||
                      row?.status === "Cancel" ||
                      row?.status === "Expired" ||
                      row?.status === "Rejected" ||
                      row?.cheque_status === "Processed" ||
                      row?.cheque_status === "Cancelled" ||
                      row?.policy_commission_status === "Yet to pay" ||
                      row?.policy_commission_status === "In Progress" ||
                      row?.policy_commission_status === "Received" ||
                      row?.policy_commission_status === "Cancelled"
                        ? "#00000040"
                        : `${
                            settings?.button_background_color
                              ? settings.button_background_color
                              : theme.palette.primary.buttonColor
                          }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isDelete && (
            <Tooltip title={t("Delete")} open={!flg_isDelete && isHover_isDelete}>
              <IconButton
                // disabled={
                //   (row?.status === "Suspended" )
                // }
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                disabled={
                  (row?.doc_from && row?.doc_from === "Elpis") ||
                  row?.status === "Suspended"
                }
                onClick={() => {
                  setFlg_isDelete(!flg_isDelete);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "delete", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isDelete(true)}
                onMouseLeave={() => setIsHover_isDelete(false)}
              >
                <DeleteOutlineIcon
                  // sx={{ color: theme.palette.primary.buttonColor }}
                  sx={{
                    color:
                      (row?.doc_from && row?.doc_from === "Elpis") ||
                      row?.status === "Suspended"
                        ? "#00000040"
                        : `${
                            settings.button_background_color
                              ? settings.button_background_color
                              : theme.palette.primary.buttonColor
                          }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isMove && (
            <Tooltip title={t("Move")} open={!flg_isMove && isHover_isMove}>
              <IconButton
                // disabled={
                //   (row?.status === "Suspended" )
                // }
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                // disabled={
                //   (row?.doc_from && row?.doc_from === "Elpis") ||
                //   row?.status === "Suspended"
                // }
                onClick={() => {
                  setFlg_isMove(!flg_isMove);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "move", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isMove(true)}
                onMouseLeave={() => setIsHover_isMove(false)}
              >
                <DriveFileMove
                  // sx={{ color: theme.palette.primary.buttonColor }}
                  sx={{
                    color:`${
                            settings.button_background_color
                              ? settings.button_background_color
                              : theme.palette.primary.buttonColor
                          }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isCarrier && (
            <Tooltip
              title="Payment To Carrier"
              open={!flg_isDelete && isHover_isDelete}
            >
              <IconButton
                disabled={
                  !!row?.paymentToCarrier ||
                  row?.cheque_status === "Draft" ||
                  row?.cheque_status === "Cancelled"
                }
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_isDelete(!flg_isDelete);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "carrier", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_isDelete(true)}
                onMouseLeave={() => setIsHover_isDelete(false)}
              >
                <PaymentIcon
                  // sx={{ color: theme.palette.primary.buttonColor }}
                  sx={{
                    color:
                      !!row?.paymentToCarrier ||
                      row?.cheque_status === "Draft" ||
                      row?.cheque_status === "Cancelled"
                        ? "#00000040"
                        : `${
                            settings.button_background_color
                              ? settings.button_background_color
                              : theme.palette.primary.buttonColor
                          }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isExport && (
            <Tooltip title={t("Export")} open={!flg_export && isHover_export}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                disabled={row?.is_paid === false }
                onClick={() => {
                  setFlg_export(!flg_export);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "export", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_export(true)}
                onMouseLeave={() => setIsHover_export(false)}
              >
                <PictureAsPdfIcon
                  sx={{
                    color:
                    row?.is_paid === false?
                    "#00000040":
                     `${
                      settings.button_background_color
                        ? settings.button_background_color
                        : theme.palette.primary.buttonColor
                    }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isDownload && (
            <Tooltip
              title={
                row?.status !== "Paid"
                  ? "Download Invoice"
                  : "Download Invoice Receipt"
              }
            >
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                // disabled={row?.status !== "Paid"}
                onClick={() => {
                  setFlg_export(!flg_export);
                  setTimeout(() => {
                    row?.status !== "Paid"
                      ? actionFunction && actionFunction(row.id, "export", i)
                      : actionFunction && actionFunction(row.id, "download", i);
                  }, 0);
                }}
              >
                {row?.status !== "Paid" ? (
                  <PictureAsPdfIcon
                    sx={{
                      color: `${
                        settings.button_background_color
                          ? settings.button_background_color
                          : theme.palette.primary.buttonColor
                      }`,
                    }}
                  />
                ) : (
                  <DownloadOutlinedIcon
                    sx={{
                      color:
                        row?.status !== "Paid"
                          ? "#00000040"
                          : `${
                              settings.button_background_color
                                ? settings.button_background_color
                                : theme.palette.primary.buttonColor
                            }`,
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}
          {isEndorsement && (
            <Tooltip title="Endorsement Request">
              <IconButton
                disabled={!row?.is_endorsement_requested}
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setFlg_cancel(!flg_cancel);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "endorsementRequested", i);
                  }, 0);
                }}
              >
                <UpgradeTwoToneIcon
                  sx={{
                    color:
                      !row?.is_endorsement_requested
                        ? "#00000040"
                        : `${
                            settings.button_background_color
                              ? settings.button_background_color
                              : theme.palette.primary.buttonColor
                          }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isRenewal && (
            <Tooltip title="Renewal">
              <IconButton
                disabled={row?.is_already_Renewal || !row?.isRenewal}
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setFlg_cancel(!flg_cancel);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "renewal", i);
                  }, 0);
                }}
              >
                <AutorenewIcon
                  // sx={{ color: theme.palette.primary.buttonColor }}
                  sx={{
                    color:
                      row?.is_already_Renewal || !row?.isRenewal
                        ? "#00000040"
                        : `${
                            settings.button_background_color
                              ? settings.button_background_color
                              : theme.palette.primary.buttonColor
                          }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isCancel && (
            <Tooltip title="Cancel" open={!flg_cancel && isHover_cancel}>
              <IconButton
                disabled={
                  row?.status === "Suspended" ||
                  row.status === "Rejected" ||
                  row.status === "Expired" ||
                  row.status == "Cancel"
                }
                aria-label="more"
                id="long-button"
                // aria-controls={open ? "long-menu" : undefined}
                // aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setFlg_cancel(!flg_cancel);
                  setTimeout(() => {
                    actionFunction && actionFunction(row.id, "cancel", i);
                  }, 0);
                }}
                onMouseOver={() => setIsHover_cancel(true)}
                onMouseLeave={() => setIsHover_cancel(false)}
              >
                <CancelIcon
                  // sx={{ color: theme.palette.primary.buttonColor }}
                  sx={{
                    color:
                      row?.status === "Suspended" ||
                      row?.status === "Rejected" ||
                      row.status === "Expired" ||
                      row?.status === "Cancel"
                        ? "#00000040"
                        : `${
                            settings.button_background_color
                              ? settings.button_background_color
                              : theme.palette.primary.buttonColor
                          }`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isMoreVert && (
            <>
              <>
                <Tooltip title="More" open={!flg_isMore && isHover_isMore}>
                  {!row?.isApproveActionDisable ? (
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={handleMenuOpen}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      onMouseOver={() => setFlg_isMore(true)}
                      onMouseLeave={() => setIsHover_isMore(false)}
                    >
                      <MoreVertIcon
                        // sx={{ color: theme.palette.primary.buttonColor }}
                        sx={{
                          color: `${
                            settings.button_background_color
                              ? settings.button_background_color
                              : theme.palette.primary.buttonColor
                          }`,
                        }}
                      />
                    </IconButton>
                  ) : (
                    <div style={{ visibility: "hidden", padding: "1.3rem" }} />
                  )}
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  sx={{ margin: "2rem" }}
                >
                  <MenuItem
                    sx={{
                      minWidth: "4rem",
                      padding: "0.5rem 1.2rem",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      actionFunction && actionFunction(row.id, "Accept", i);
                    }}
                    disabled={row.isButtonDisable}
                  >
                    <ListItemIcon>
                      <CheckCircleOutlineIcon
                        fontSize="medium"
                        sx={{ color: "#56BF54" }}
                      />
                    </ListItemIcon>
                    Approve{" "}
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minWidth: "4rem",
                      padding: "0.5rem 1.2rem",
                      fontSize: "1.1rem",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      actionFunction && actionFunction(row.id, "Reject", i);
                    }}
                    disabled={row.isButtonDisable}
                  >
                    <ListItemIcon>
                      <HighlightOffIcon
                        fontSize="medium"
                        sx={{ color: "#EF415A" }}
                      />
                    </ListItemIcon>
                    Reject{" "}
                  </MenuItem>
                </Menu>
              </>
            </>
          )}
          {isActionButton && !isMoreVert && (
            <Box>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  minWidth: "4rem",
                  padding: "0.5rem 1rem",
                }}
                onClick={() => {
                  actionFunction && actionFunction(row.id, "Accept", i);
                }}
                disabled={row.isButtonDisable}
              >
                {" "}
                Accept{" "}
              </Button>
              <Button
                sx={{ minWidth: "4rem", padding: "0.5rem 1rem", ml: 1 }}
                size="small"
                variant="outlined"
                color="error"
                onClick={() => {
                  actionFunction && actionFunction(row.id, "Reject", i);
                }}
                disabled={row.isButtonDisable}
              >
                {" "}
                Reject{" "}
              </Button>
            </Box>
          )}
          {isActionPaymentButton && (
            <Box>
              <Button
                sx={{
                  minWidth: "6rem",
                  padding: "0.5rem 1rem",
                  backgroundColor: "transparent",
                  color: "#0fadd5",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                variant="outlined"
                size="small"
                // sx={{  }}
                onClick={() => {
                  if (row?.status === PaymentStatus.PayNow)
                    actionFunction && actionFunction(row.id, "pay", i);
                }}
                disabled={row?.status !== PaymentStatus.PayNow}
              >
                {" "}
                {row?.status}{" "}
              </Button>
            </Box>
          )}
        </Stack>
      );
    case "dob":
      return dateFormat(row[head.id]);
    case "permit_expiration_date":
      return dateFormat(row[head.id]);
    case "due_date":
      return dateFormat(row[head.id]);
    case "driver_license_number":
      return formatLicense(row[head.id]);
    case "effective_date":
      return dateFormat(row[head.id]);
    case "expiration_date":
      return dateFormat(row[head.id]);
    case "receivedAt":
      return dateFormat(row[head.id]);
    case "paidAt":
      return dateFormat(row[head.id]);

    case "type":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {head?.isUserIcon && (
            <AccountCircleIcon sx={{ color: "#a5a5a5" }} fontSize="large" />
          )}
          &nbsp; &nbsp;
          {row[head.id]}
        </div>
      );
    case "total_due_amount":
      return (
        <div
          style={{
            color: "#00aed7",
          }}
        >
          &nbsp; &nbsp;
          {row[head.id]}
        </div>
      );
    case "past_due_balance":
      return (
        <div
          style={{
            color: "#d9007c",
          }}
        >
          &nbsp; &nbsp;
          {row[head.id]}
        </div>
      );
    case "transactionAmount":
      return (
        <div
          style={{
            color: "#d9007c",
          }}
        >
          &nbsp; &nbsp;
          {row[head.id]}
        </div>
      );
    case "transactionAmountElavon":
      return (
        <div
          style={{
            color: "#d9007c",
          }}
        >
          &nbsp; &nbsp;
          {row[head.id]}
        </div>
      );
    case "difference":
      return (
        <div
          style={{
            color: row[head.id] >= 0 ? "green" : "red",
          }}
        >
          &nbsp; &nbsp; ${row[head.id]}
        </div>
      );
    case "is_tally":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {row[head.id] ? (
            <CheckOutlinedIcon sx={{ color: "#00aed7" }} fontSize="large" />
          ) : (
            <ClearOutlinedIcon sx={{ color: "#d9007c" }} fontSize="large" />
          )}
        </div>
      );
      case "is_kyc_verified":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {row[head.id] ? "Yes": "No" }
          </div>
        );
    case "filesize":
      return row[head.id];
    case "icons":
      return (
        <Stack
          direction={"row"}
          justifyContent={"space-evenly"}
          className="showIcon"
        >
          <IconButton>
            <PersonAddIcon />
          </IconButton>
          <IconButton>
            <FileDownloadIcon />
          </IconButton>
          <IconButton>
            <BorderColorIcon />
          </IconButton>
        </Stack>
      );
    case "button":
      return (
        <>
          {" "}
          <IconButton
            aria-label="more"
            id="long-button"
            // aria-controls={open ? "long-menu" : undefined}
            // aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={() => {
              Openmodal && Openmodal(row.id, "open");
            }}
          >
            <IosShareIcon sx={{ color: theme.palette.primary.buttonColor }} />
          </IconButton>
        </>
      );
    // case "email_verified":
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "flex-start",
    //         alignItems: "center",
    //       }}
    //     >
    //       {!row?.email_verified ? (
    //         <MailOutlineIcon
    //           sx={{ color: "#2196f3" }}
    //           onClick={() => resendMail && resendMail(row.id)}
    //         />
    //       ) : (
    //         <></>
    //       )}
    //     </div>
    //   );
    case "is_active":
      return (
        <>
          <IOSSwitch
            checked={row.is_active}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setActive && setActive(row.id, event.target.checked)
            }
            // handleCheckChange={(event:) =>
            //   setActive && setActive(event.target.checked)
            // }
            // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            //   setActive && setActive(event.target.checked)
            // }
            // inputProps={{ "aria-label": "controlled" }}
            // sx={{ color: "#283891" }}
          />
        </>
      );
    case "template_type":
      return row[head.id]?.name;
    case "account_no":
      return maskAccountNumber(row[head.id]);
    case "created_at":
      return dateFormat(row[head.id]);
    case "timestamp":
      return dateTimeFormatWithoutSecond(row[head.id]);
    case "scheduled_amt":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;
    case "due_amount":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;
    case "broker_fee":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;
    case "due_now":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;

    case "total_premium":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;

    case "amount_paid":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;

    case "amount_received":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;
    case "amount_scheduled":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;
    case "remaining_amount":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;

    case "premium":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;

    case "fee":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;

    case "total_policy_cost":
      return <span>${parseFloat(row[head.id]).toFixed(2)}</span>;
    
    case "card_fee_percentage":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontWeight: "600",
              //textDecoration:  "underline" ,
            }}
          >
            {row[head.id]}
          </div>
        );
    case "ach_flat_value":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontWeight: "600",
              //textDecoration:  "underline" ,
            }}
          >
            ${row[head.id]}
          </div>
        );

    case "2nd_injury_fund":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );

      case "cancel_fee":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );
      case "excess_line_tax":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );
      case "excise_tax":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );
      case "expense_constant":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );
      case "total":
        return (
          <span>
            $
            {isNaN(parseFloat(row[head.id]))
              ? "0.00"
              : parseFloat(row[head.id]).toFixed(2)}
          </span>
        );
      case "amountReceived":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );
    case "policy_commission":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );
    case "agency_commission_received":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );
    case "commission_owned":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );

    case "amountPaid":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );

    case "amount":
      return (
        <span>
          $
          {isNaN(parseFloat(row[head.id]))
            ? "0.00"
            : parseFloat(row[head.id]).toFixed(2)}
        </span>
      );

    case "path":
      return (
        <span
          onClick={() => {
            window.open(process.env.REACT_APP_IMG_URL + row?.path, "_blank");
          }}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "#007aff",
          }}
        >
          {shortenFileName(row?.path)}
        </span>
      );
    // case "policy_number":
    case "policyNumber":
      return (
        <Box
          // component={"a"}
          // href={`${row[head.id]}`}
          sx={{
            // color: "blue",
            textDecoration: "underline",
            color: "#0000EE",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 500,
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 200,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >
            <span
              onClick={() =>
                navigate(`/customers/policy/view/${row?.policy_id ?? row.id}`)
              }
            >
              {row[head.id]}
            </span>
          </Typography>
        </Box>
      );
    case "policy_number":
      return (
        <Box
          // component={"a"}
          // href={`${row[head.id]}`}
          sx={{
            // color: "blue",
            textDecoration: row[head.id]? "underline" : "none",
            color: row[head.id]? "#0000EE": "inherit",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 500,
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 200,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >
            {row[head.id]? <span
              onClick={() =>
                navigate(`/customers/policy/view/${row?.policy_id ?? row.id}`)
              }
            >
              {row[head.id]}
            </span> : "-"}
          </Typography>
        </Box>
      );

      case "quotation_id":
        return (
          <Box
            // component={"a"}
            // href={`${row[head.id]}`}
            sx={{
              // color: "blue",
              textDecoration: "underline",
              color: "#0000EE",
              fontSize: ".9rem",
              letterSpacing: "0.5px",
              fontWeight: 500,
              wordBreak: "break-all",
              pt: 1,
            }}
          >
            <Typography
              sx={{
                // color: theme.palette.grey[800],
                fontSize: "14px",
                letterSpacing: "0.5px",
                fontWeight: 200,
                lineHeight: "1.5rem",
                wordBreak: "break-all",
              }}
            >
              <span
                onClick={() =>
                  navigate(`/quote/view/${row?.policy_id ?? row.id}`)
                }
              >
                {row[head.id]}
              </span>
            </Typography>
          </Box>
        );
    case "policy_number1":
      return (
        <Box
          // component={"a"}
          // href={`${row[head.id]}`}
          sx={{
            // color: "blue",
            textDecoration: "underline",
            color: "#0000EE",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 500,
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 200,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >
            <span
              onClick={() => navigate(`/customers/policy/view/${row.policyId}`)}
            >
              {row[head.id]}
            </span>
          </Typography>
        </Box>
      );
    case "user_name":
      return (
        <Box
          // component={"a"}
          // href={`${row[head.id]}`}
          sx={{
            // color: "black",
            textDecoration: "underline",
            color: "#0000EE",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 200,
            // textDecoration: "none",
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 500,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >
            <span
              onClick={() => navigate(`/customers/view/${row.customer.id}`)}
            >
              {row[head.id]}
            </span>
          </Typography>
        </Box>
      );
    case "customer_id1":
      return (
        <Box
          // component={"a"}
          // href={`${row[head.id]}`}
          sx={{
            // color: "black",
            textDecoration: row[head.id]? "underline" : "none",
            color: row[head.id]? "#0000EE": "inherit",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 200,
            // textDecoration: "none",
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 500,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >{row[head.id]?
            <span
              onClick={() =>
                navigate(`/customers/view/${row?.customer_id? row?.customer_id : row.id}`)
              }
            >
              {row[head.id]}
            </span>: '-'}
          </Typography>
        </Box>
      );
      case "customerName":
      return (
        <Box
          // component={"a"}
          // href={`${row[head.id]}`}
          sx={{
            // color: "black",
            textDecoration: "underline",
            color: "#0000EE",
            fontSize: ".9rem",
            letterSpacing: "0.5px",
            fontWeight: 200,
            // textDecoration: "none",
            wordBreak: "break-all",
            pt: 1,
          }}
        >
          <Typography
            sx={{
              // color: theme.palette.grey[800],
              fontSize: "14px",
              letterSpacing: "0.5px",
              fontWeight: 500,
              lineHeight: "1.5rem",
              wordBreak: "break-all",
            }}
          >
            <span
              onClick={() =>
                navigate(`/customers/view/${row.customer_id || row.id}`)
              }
            >
              {row[head.id]}
            </span>
          </Typography>
        </Box>
      );
    case "first_name":
      return (
        <>
          {routingLink ? (
            <Box
              // component={"a"}
              // href={`${row[head.id]}`}
              sx={{
                // color: "black",
                textDecoration: "underline",
                color: "#0000EE",
                fontSize: ".9rem",
                letterSpacing: "0.5px",
                fontWeight: 200,
                // textDecoration: "none",
                wordBreak: "break-all",
                pt: 1,
              }}
            >
              <Typography
                sx={{
                  // color: theme.palette.grey[800],
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                  fontWeight: 500,
                  lineHeight: "1.5rem",
                  wordBreak: "break-all",
                }}
              >
                <span
                  onClick={() =>
                    navigate(`/customers/view/${row.customer_id || row.id}`)
                  }
                >
                  {row[head.id]}
                </span>
              </Typography>
            </Box>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );
    case "last_name":
      return (
        <>
          {routingLink ? (
            <Box
              // component={"a"}
              // href={`${row[head.id]}`}
              sx={{
                // color: "black",
                textDecoration: "underline",
                color: "#0000EE",
                fontSize: ".9rem",
                letterSpacing: "0.5px",
                fontWeight: 200,
                // textDecoration: "none",
                wordBreak: "break-all",
                pt: 1,
              }}
            >
              <Typography
                sx={{
                  // color: theme.palette.grey[800],
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                  fontWeight: 500,
                  lineHeight: "1.5rem",
                  wordBreak: "break-all",
                }}
              >
                <span
                  onClick={() =>
                    navigate(`/customers/view/${row.customer_id || row.id}`)
                  }
                >
                  {row[head.id]}
                </span>
              </Typography>
            </Box>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );
    case "firstName":
      return (
        <>
          {routingLink ? (
            <Box
              // component={"a"}
              // href={`${row[head.id]}`}
              sx={{
                // color: "black",
                textDecoration: "underline",
                color: "#0000EE",
                fontSize: ".9rem",
                letterSpacing: "0.5px",
                fontWeight: 200,
                // textDecoration: "none",
                wordBreak: "break-all",
                pt: 1,
              }}
            >
              <Typography
                sx={{
                  // color: theme.palette.grey[800],
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                  fontWeight: 500,
                  lineHeight: "1.5rem",
                  wordBreak: "break-all",
                }}
              >
                <span onClick={() => navigate(`/customers/view/${row.id}`)}>
                  {row[head.id]}
                </span>
              </Typography>
            </Box>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );
    case "lastName":
      return (
        <>
          {routingLink ? (
            <Box
              // component={"a"}
              // href={`${row[head.id]}`}
              sx={{
                // color: "black",
                textDecoration: "underline",
                color: "#0000EE",
                fontSize: ".9rem",
                letterSpacing: "0.5px",
                fontWeight: 200,
                // textDecoration: "none",
                wordBreak: "break-all",
                pt: 1,
              }}
            >
              <Typography
                sx={{
                  // color: theme.palette.grey[800],
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                  fontWeight: 500,
                  lineHeight: "1.5rem",
                  wordBreak: "break-all",
                }}
              >
                <span
                  onClick={() =>
                    navigate(`/customers/view/${row.customer_id || row.id}`)
                  }
                >
                  {row[head.id]}
                </span>
              </Typography>
            </Box>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );
    case "cheque_number":
      return (
        <>
          {routingLink ? (
            <Box
              sx={{
                textDecoration: "underline",
                color: "#0000EE",
                fontSize: ".9rem",
                letterSpacing: "0.5px",
                fontWeight: 200,
                wordBreak: "break-all",
                pt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                  fontWeight: 500,
                  lineHeight: "1.5rem",
                  wordBreak: "break-all",
                }}
                onClick={() =>
                  navigate(`/cheque/view/${row.id}`, { state: row?.type })
                }
              >
                <span>{row[head.id]}</span>
              </Typography>
            </Box>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );


      case "chequeNumber":
      return (
        <>
          {routingLink ? (
            <Box
              sx={{
                textDecoration: "underline",
                color: "#0000EE",
                fontSize: ".9rem",
                letterSpacing: "0.5px",
                fontWeight: 200,
                wordBreak: "break-all",
                pt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                  fontWeight: 500,
                  lineHeight: "1.5rem",
                  wordBreak: "break-all",
                }}
                onClick={() =>
                  navigate(`/cheque/view/${row.id}`)
                }
              >
                <span>{row[head.id]}</span>
              </Typography>
            </Box>
          ) : (
            <span>{row[head.id]}</span>
          )}
        </>
      );

    default:
      return row[head.id];
  }
};

const isEqual = (prev: TableCellType, next: TableCellType) => {
  // if(prev.i)
  return true;
};

const TableCellMemo = React.memo(EnhancedTableCell, isEqual);
export default function EnhancedTable(props: EnhancedTableTypes) {
  const {
    page = 0,
    rowsPerPage = 10,
    handleChangePage,
    handleChangeRowsPerPage = () => {},
    headCells = [],
    handleRequestSort = () => {},
    rows = [],
    order,
    orderBy,
    isSelectable,
    setSelected,
    selected = [],
    total = 0,
    actionFunction,
    resendMail,
    isEdit = false,
    isView = false,
    isCancel = false,
    isRenewal = false,
    isreInvite = false,
    isKyc = false,
    isDelete = false,
    isMove = false,
    isActionButton = false,
    isActionPaymentButton = false,
    isMoreVert = false,
    isMail = false,
    isCarrier = false,
    isLoading = false,
    isPagination = true,
    isNoRecordFound = true,
    Openmodal,
    setActive,
    redoKyc,
    reInvite,
    isSingleCheck,
    maxHeightValue,
    HeightValue = "auto",
    isCheckedRound = false,
    width,
    isExport,
    isDownload,
    routingLink = false,
    isEndorsement = false,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //   backgroundColor: "#28389106",
    // },
    // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
    ".MuiTableCell-root": {
      paddingTop: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid #D9D9D9",
    },
  }));
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.id);
      setSelected && setSelected(newSelected);
      return;
    }
    setSelected && setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    name: string
  ) => {
    if (!isSingleCheck) {
      const selectedIndex = selected.indexOf(name);
      let newSelected: any = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected && setSelected(newSelected);
    } else {
      const value = name !== selected ? name : "";
      setSelected && setSelected(value);
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - total) : 0;

  const visibleRows = React.useMemo(() => rows, [rows]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          className="table-container"
          sx={{
            maxHeight: maxHeightValue
              ? maxHeightValue
              : matchDownMD
              ? "calc(100vh - 400px)"
              : "calc(100vh - 330px)",

            height: HeightValue,
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: width ? width : 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order || "asc"}
              orderBy={orderBy || ""}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length ?? 0}
              headCells={headCells}
              isSingleCheck={isSingleCheck}
              isSelectable={isSelectable || false}
            />
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={
                      isSelectable ? headCells.length + 1 : headCells.length
                    }
                    align="center"
                  >
                    <CircularProgress disableShrink />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {visibleRows.map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      onClick={(event) =>
                        isSelectable
                          ? handleClick(event, row.id)
                          : console.log("row.id", row.id)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer", border: "1" }}
                      // onMouseEnter={() => setMouseover(true)}
                      // onMouseLeave={() => setMouseover(false)}
                    >
                      {isSelectable && (
                        <TableCell padding="checkbox" sx={{ border: "1" }}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            icon={
                              <CircleOutlinedIcon sx={{ fontSize: "1.6rem" }} />
                            }
                            checkedIcon={
                              <CheckCircleIcon sx={{ fontSize: "1.6rem" }} />
                            }
                          />
                        </TableCell>
                      )}
                      {headCells.map((head: any, i: number) => (
                        <TableCell
                          key={`table-cell-td-${i}${row.id}`}
                          align={head?.align ?? "left"}
                          sx={{ border: "1" }}
                        >
                          <TableCellMemo
                            row={row}
                            head={head}
                            i={i}
                            isMail={isMail}
                            resendMail={resendMail}
                            actionFunction={actionFunction}
                            isEdit={isEdit}
                            isView={isView}
                            isCancel={isCancel}
                            isRenewal={isRenewal}
                            isreInvite={isreInvite}
                            isKyc={isKyc}
                            isDelete={isDelete}
                            isMove={isMove}
                            isCarrier={isCarrier}
                            isMoreVert={isMoreVert}
                            isActionButton={isActionButton}
                            isActionPaymentButton={isActionPaymentButton}
                            Openmodal={Openmodal}
                            setActive={setActive}
                            redoKyc={redoKyc}
                            reInvite={reInvite}
                            isExport={isExport}
                            isDownload={isDownload}
                            routingLink={routingLink}
                            isEndorsement= {isEndorsement}
                          />
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  );
                })}
                {!visibleRows?.length && !isLoading && isNoRecordFound && (
                  <TableRow>
                    <TableCell
                      colSpan={
                        isSelectable ? headCells.length + 1 : headCells.length
                      }
                      align="center"
                    >
                      <NoRecordFound />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {isPagination && (
          <PaginationComponent
            onChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            total={total}
            rowsPerPageOptions={[5, 10, 25]}
          />
        )}
      </Paper>
    </Box>
  );
}

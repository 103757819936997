import { PhoneNumberUtil } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();

export const getSelectedValue = (array: any[], value: string) => {
  return array.find((data) => data.value === value);
};

export const dynamicSort = (
  array: any[],
  property: string,
  order: "asc" | "desc" = "asc"
): any => {
  const result = [...array].sort((a, b) => {
    let propA = a[property];
    let propB = b[property];

    // Convert strings to lower case for case-insensitive comparison
    if (typeof propA === "string") propA = propA.toLowerCase();
    if (typeof propB === "string") propB = propB.toLowerCase();

    if (propA < propB) {
      return order === "asc" ? -1 : 1;
    }
    if (propA > propB) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
  return [...result];
};

export const formatSSN = (input: string) => {
  // Remove all non-numeric characters
  input = input.replace(/\D/g, "");

  // Format the input as "123-45-6789"
  if (input.length > 5) {
    input = input.replace(/^(\d{3})(\d{2})(\d{0,4}).*/, "$1-$2-$3");
  } else if (input.length > 3) {
    input = input.replace(/^(\d{3})(\d{0,2})/, "$1-$2");
  }

  return input;
};

export const isPhoneNumber = (number: string) => {
  const phone = phoneUtil.parseAndKeepRawInput(number); // Attempt to parse without specifying a region
  return phoneUtil.isValidNumber(phone);
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

export async function base64ToFile(url: string, filename: string, type: string) {
  return new Promise((resolve, reject) => {
  fetch(url)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], filename,{ type: `${type}` });
      resolve(file);
    }).catch((err) => {
      reject(err);
    })
  })
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  imageType: string,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc) as any;
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas') as any;

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  return await base64ToFile(croppedCanvas.toDataURL(`image/${imageType}`), `img`, imageType);

  // As a blob
  // return new Promise((resolve, reject) => {
  //   croppedCanvas.toBlob((file: any) => {
  //     console.log("file", file);
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // })
}

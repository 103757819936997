import { Box, Grid } from "@mui/material";
import FormLayout from "component/form";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { InputField } from "forms/hoc/formfield";
import TextView from "forms/hoc/text-view";
import useForm from "forms/hooks/useForm";
import { paymentConfigPageValidation, paymentConfigPageValidationSchema } from "forms/hooks/validateRules";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PaymentConfig({
  handleNextTab,
  handlePreTab,
  isView = false,
}: {
  handleNextTab: any;
  handlePreTab: any;
  isView?: boolean;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>(false);
  const { locationID } = useAppSelector((state) => state.location);
  const initialValues = {
    merchant_id: null,
    merchant_user_id: null,
    payment_url: null,
    ach_pin: null,
    cc_pin: null,
    // surcharge: null,
  };

  const formSubmit = () => {
    setLoading(true);
    const api = `/location`;
    const data = {
      payment_config: {
        ...values,
      },
      id: locationID,
      //surcharge : 0
    };
    commonService
      .postPutService(api, data, "patch")
      .then((res) => {
        Success(res?.data?.message);
        setLoading(false);
        //handleNextTab();
        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };
  const { t } = useTranslation();
  const paymentConfigT = paymentConfigPageValidationSchema(t);
  const { errors, values, handleSubmit, setPatchValue, handleChange } = useForm(
    initialValues,
    formSubmit,
    paymentConfigPageValidation,
    paymentConfigT
  );

  useEffect(() => {
    if (locationID) getPaymentConfigData();
  }, []);

  const getPaymentConfigData = () => {
    commonService
      .getServices(`/location/${locationID}?tab=payment_config`)
      .then((res: any) => {
        const result = res?.data?.data?.payment_config;
        if (result) {
          const paymentData: any = {};
          paymentData.merchant_id = result?.merchant_id;
          paymentData.merchant_user_id = result?.merchant_user_id;
          paymentData.payment_url = result?.payment_url;
          paymentData.ach_pin = result?.ach_pin;
          paymentData.cc_pin = result?.cc_pin;
        //   paymentData.surcharge = parseFloat(result?.surcharge || 0).toFixed(2);
          setPatchValue(paymentData);
        }
        setLoading(false);
      });
  };
 
  return (
    <Box sx={{ p: 3 }}>
      {loading && <Loading />}
      <FormLayout
        handleSubmit={handleSubmit}
        isSaveButton={!isView}
        // submitButtonText="Submit"
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} lg={4}>
            {isView ? (
              <TextView
                fieldName={t("Merchant Id")}
                fieldValue={values?.merchant_id ? values?.merchant_id : "-"}
              />
            ) : (
              <InputField
                fieldName={t("Merchant Id")}
                values={values?.merchant_id || ""}
                name="merchant_id"
                errors={errors?.merchant_id}
                handleChange={handleChange}
                id="merchant_id"
              />
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            {isView ? (
              <TextView
                fieldName={t("Merchant user Id")}
                fieldValue={
                  values?.merchant_user_id ? values?.merchant_user_id : "-"
                }
              />
            ) : (
              <InputField
                fieldName={t("Merchant user Id")}
                values={values?.merchant_user_id || ""}
                name="merchant_user_id"
                errors={errors?.merchant_user_id}
                handleChange={handleChange}
                id="merchant_user_id"
              />
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            {isView ? (
              <TextView
                fieldName={t("Payment URL")}
                fieldValue={values?.payment_url ? values?.payment_url : "-"}
              />
            ) : (
              <InputField
                fieldName={t("Payment URL")}
                values={values?.payment_url || ""}
                name="payment_url"
                errors={errors?.payment_url}
                handleChange={handleChange}
                id="payment_url"
              />
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            {isView ? (
              <TextView
                fieldName={t("ACH PIN")}
                fieldValue={values?.ach_pin ? values?.ach_pin : "-"}
              />
            ) : (
              <InputField
                fieldName={t("ACH PIN")}
                values={values?.ach_pin || ""}
                name="ach_pin"
                errors={errors?.ach_pin}
                handleChange={handleChange}
                id="ach_pin"
              />
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            {isView ? (
              <TextView
                fieldName={t("Card PIN")}
                fieldValue={values?.cc_pin ? values?.cc_pin : "-"}
              />
            ) : (
              <InputField
                fieldName={t("Card PIN")}
                values={values?.cc_pin || ""}
                name="cc_pin"
                errors={errors?.cc_pin}
                handleChange={handleChange}
                id="cc_pin"
              />
            )}
          </Grid>
          {/* <Grid item xs={12} lg={4}>
                    {isView?
                    <TextView
                    fieldName="Surcharge"
                    fieldValue={
                        values?.surcharge ? values?.surcharge : "-"
                    }
                  />
                    :<InputField
                        fieldName="Surcharge"
                        values={values?.surcharge || ""}
                        name="surcharge"
                        errors={errors?.surcharge}
                        handleChange={handleChange}
                        id="surcharge"
                        onlyDecimalNumber={true}
                        length={5}
                        adorament="endAdornment"
                        adoramentText="%"
                    />}
                </Grid> */}
        </Grid>
      </FormLayout>
    </Box>
  );
}

import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      flexGrow={1}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2, height: "100%" }}>{children}</Box>}
    </Stack>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabComponent = ({
  handleChange,
  value,
  tabs,
  setTab,
}: TabComponentTypes) => {
  const { Component } = tabs.find(
    (tabValue) => tabValue.value === value
  ) as TabsList;

  return (
    <Stack sx={{ width: "100%", flexGrow: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          scrollButtons={true}
          variant="scrollable"
          allowScrollButtonsMobile
          selectionFollowsFocus
        >
          {tabs.map((value: TabsList, index: any) => (
            <Tab
              key={index}
              label={value.label}
              value={value.value}
              {...a11yProps(value.value)}
              disabled={value.disabled}
            />
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={value}>
        {Component}
      </CustomTabPanel>
    </Stack>
  );
};

type TabsList = {
  label: string;
  value: number;
  Component: any;
  disabled?: boolean;
};

type TabComponentTypes = {
  handleChange: any;
  value: number;
  tabs: TabsList[];
  setTab?: any;
};

export default TabComponent;

import { t } from "i18next";

export const customerHeadCell = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: t("first_name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: true,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: t("Contact Number"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];
export const customerPolicyHeadCell = [
  {
    id: "policy_numberr",
    numeric: false,
    disablePadding: true,
    label: t("Policy Number"),
    isSort: true,
    isBold: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];



export const CustomerListHeadCell = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: t("Phone Number"),
    isSort: true,
  },
  {
    id: "policy_count",
    numeric: false,
    disablePadding: false,
    label: t("Policy Count"),
    isSort: true,
    isBold: false,
  },

];

export const FeesReportHeadCell = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("First Name"),
    isSort: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("Last Name"),
    isSort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: t("Phone Number"),
    isSort: true,
  },
  {
    id: "policy_number",
    numeric: false,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: true,
    isBold: false,
  },


  {
    id: "2nd_injury_fund",
    numeric: false,
    disablePadding: false,
    label: t("2nd Injury Fund"),
    isSort: false,
    isBold: false,
  },

  {
    id: "cancel_fee",
    numeric: false,
    disablePadding: false,
    label: t("Cancel Fee"),
    isSort: false,
    isBold: false,
  },

  {
    id: "excess_line_tax",
    numeric: false,
    disablePadding: false,
    label: t("Excess Line Tax"),
    isSort: false,
    isBold: false,
  },
  {
    id: "excise_tax",
    numeric: false,
    disablePadding: false,
    label: t("Excise Tax"),
    isSort: false,
    isBold: false,
  },
  {
    id: "expense_constant",
    numeric: false,
    disablePadding: false,
    label: t("Expense Constant"),
    isSort: false,
    isBold: false,
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: t("Total Fees"),
    isSort: false,
    isBold: false,
  },
];

export const PolicyRenewalReportHeadCell = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: t("Phone Number"),
    isSort: true,
  },
  {
    id: "policy_number",
    numeric: false,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: true,
    isBold: false,
  },


];

export const ReinstatementReportHeadCell = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: t("Phone Number"),
    isSort: true,
  },
  {
    id: "policy_number",
    numeric: false,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: true,
    isBold: false,
  },

];


export const PolicyCountBasedOnInsuranceCompanyHeadCell = [
  {
    id: "insuranceCompanyName",
    numeric: false,
    disablePadding: false,
    label: t("Insurance Company Name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "insuranceCount",
    numeric: false,
    disablePadding: false,
    label: t("Policy Count"),
    isSort: true,
    isBold: false,
  },


];



export const transactionsHistoryHeadCell = [
  {
    id: "policy_Number",
    numeric: false,
    disablePadding: true,
    label:t("policy_number"),
    isSort: false,
    isBold: false,
  },
  {
    id: "customer_Name",
    numeric: false,
    disablePadding: false,
    label:t("Customer Name"),
    isSort: false,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label:t("Location"),
    isSort: false,
  },
  {
    id: "transactionAmount",
    numeric: false,
    disablePadding: false,
    label:t("transaction_amount"),
    isSort: false,
  },
  {
    id: "card_fee_percentage",
    numeric: false,
    disablePadding: false,
    label:t("Card Fee Percentage"),
    isSort: false,
  },
  {
    id: "type_of_card",
    numeric: false,
    disablePadding: false,
    label:t("mode_of_payment"),
    isSort: false,
  },
  {
    id: "orignal_amount",
    numeric: false,
    disablePadding: false,
    label:t("policy_amount"),
    isSort: false,
  },
  {

    id: "tnxdate",
    numeric: false,
    disablePadding: false,
    label:t("transaction_date"),
    isSort: false,
  }

];
export const ACHHistoryHeadCell = [
  {
    id: "policy_Number",
    numeric: false,
    disablePadding: true,
    label:t("policy_number"),
    isSort: false,
    isBold: false,
  },
  {
    id: "customer_Name",
    numeric: false,
    disablePadding: false,
    label:t("Customer Name"),
    isSort: false,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label:t("Location"),
    isSort: false,
  },
  {
    id: "transactionAmount",
    numeric: false,
    disablePadding: false,
    label:t("transaction_amount"),
    isSort: false,
  },
  {
    id: "ach_flat_value",
    numeric: false,
    disablePadding: false,
    label:t("ACH Flat Fee"),
    isSort: false,
  },
  {
    id: "type_of_card",
    numeric: false,
    disablePadding: false,
    label:t("mode_of_payment"),
    isSort: false,
  },
  {
    id: "orignal_amount",
    numeric: false,
    disablePadding: false,
    label:t("policy_amount"),
    isSort: false,
  },
  {

    id: "tnxdate",
    numeric: false,
    disablePadding: false,
    label:t("transaction_date"),
    isSort: false,
  }

];



export const emailHeadCell = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: t("email"),
    isSort: false,
    isBold: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const phoneHeadCell = [
  {
    id: "phoneType",
    numeric: false,
    disablePadding: true,
    label: t("phone_type"),
    isSort: false,
    isBold: true,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: t("Phone Number"),
    isSort: false,
  },
  {
    id: "notes",
    numeric: false,
    disablePadding: false,
    label: t("notes"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const addressHeadCell = [
  {
    id: "address_line_1",
    numeric: false,
    disablePadding: true,
    label: t("Address Line One"),
    isSort: false,
    isBold: true,
  },
  {
    id: "statename",
    numeric: false,
    disablePadding: false,
    label: t("state"),
    isSort: false,
  },
  // {
  //   id: "city",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "City",
  //   isSort: false,
  // },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const emailHeadCellView = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: t("email"),
    isSort: false,
    isBold: true,
  },
  {
    id: "default",
    numeric: false,
    disablePadding: false,
    label:t( "Default"),
    isSort: false,
  },
];

export const phoneHeadCellView = [
  {
    id: "phoneType",
    numeric: false,
    disablePadding: true,
    label: t("phone_type"),
    isSort: false,
    isBold: true,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: t("Contact Number"),
    isSort: false,
    isBold: true,
    skipIconButton: true,
  },
  {
    id: "note",
    numeric: false,
    disablePadding: false,
    label:t( "notes"),
    isSort: false,
  },
];

export const addressHeadCellView = [
  {
    id: "address_line_1",
    numeric: false,
    disablePadding: true,
    label: t("Address_line_one"),
    isSort: false,
    isBold: true,
  },
  {
    id: "statename",
    numeric: false,
    disablePadding: false,
    label: t("state"),
    isSort: false,
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: t("city"),
    isSort: false,
  },
];

export const driverHeadCells = [
  {
    id: "prefix",
    numeric: false,
    disablePadding: true,
    label: t("prefix"),
    isSort: false,
    isBold: true,
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: false,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: false,
  },
  {
    id: "is_kyc_verified",
    numeric: false,
    disablePadding: false,
    label: t("KYC Verified"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const quoteDriverHeadCells = [
  {
    id: "prefix",
    numeric: false,
    disablePadding: true,
    label: t("prefix"),
    isSort: false,
    isBold: true,
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: false,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: false,
  },
  {
    id: "dob",
    numeric: false,
    disablePadding: false,
    label: t("DOB"),
    isSort: false,
  },
  {
    id: "is_kyc_verified",
    numeric: false,
    disablePadding: false,
    label: t("kYC Verified"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const driverHeadCellsView = [
  {
    id: "prefix",
    numeric: false,
    disablePadding: true,
    label: t("prefix"),
    isSort: false,
    isBold: true,
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: false,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: false,
  },
  {
    id: "is_kyc_verified",
    numeric: false,
    disablePadding: false,
    label: t("KYC Verified"),
    isSort: false,
  }
];

export const policyHeadCells = [
  {
    id: "policy_number",
    numeric: false,
    disablePadding: true,
    label: t("policy_number"),
    isSort: true,
    isBold: true,
  },
  {
    id: "effective_date",
    numeric: false,
    disablePadding: true,
    label: t("effective_date"),
    isSort: true,
    isBold: true,
  },
  {
    id: "expiration_date",
    numeric: false,
    disablePadding: true,
    label: t("expiration_date"),
    isSort: true,
    isBold: true,
  },
  {
    id: "total_policy_cost",
    numeric: false,
    disablePadding: true,
    label: t("Total_Policy_Cost"),
    isSort: false,
    isBold: true,
    align: "right",
  },
  {
    id: "policy_type",
    numeric: false,
    disablePadding: false,
    label: t("policy_type"),
    isSort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: t("policy_status"),
    isSort: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: false,
  },
  {
    id: "driverCount",
    numeric: false,
    disablePadding: false,
    label: t("drivers"),
    isSort: false,
  },
  {
    id: "vehicleCount",
    numeric: false,
    disablePadding: false,
    label: t("vehicles"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

// export const transactionsHistoryHeadCell = [


//   {
//     id:"policyNumber",
//     numeric: false,
//     disablePadding: true,
//     label: "Policy Number",
//     isBold: true,
//   },
//   {
//     id:"customerName",
//     numeric: false,
//     disablePadding: true,
//     label: "Customer Name",
//     isSort: false,
//     isBold: true,
//   },
//   {
//     id:"transactionAmount",
//     numeric: true,
//     disablePadding: true,
//     label: "Transaction Amount",
//     isSort: false,
//     isBold: true,
//   },
//   {
//     id:"location",
//     numeric: false,
//     disablePadding: true,
//     label: "Location",
//     isSort: false,
//     isBold: true,
//   },
//   {
//     id:"paymentType",
//     numeric: false,
//     disablePadding: true,
//     label: "Payment Type",
//     isSort: false,
//     isBold: true,
//   },
//   {
//     id:"card_fee_percentage",
//     numeric: true,
//     disablePadding: true,
//     label: "Card Fee Percentage",
//     isSort: false,
//     isBold: true,
//   },
//   {
//     id:"orignal_amount",
//     numeric: true,
//     disablePadding: true,
//     label: "Original Amount",
//     isSort: false,
//     isBold: true,
//   }

// ]
export const customerVehicleViewHeadCell = [
  {
    id: "vin",
    numeric: false,
    disablePadding: true,
    label: t("Vehicle Identification Number"),
    isSort: false,
    isBold: true,
  },
  {
    id: "policy_number1",
    numeric: true,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: false,
  },
  {
    id: "model",
    numeric: false,
    disablePadding: true,
    label: t("Model"),
    isSort: false,
    isBold: true,
  },
  {
    id: "year",
    numeric: true,
    disablePadding: false,
    label: t("Year"),
    isSort: false,
  },

  {
    id: "make",
    numeric: false,
    disablePadding: false,
    label: t("Make"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];
export const customerVehicledashboardHeadCell = [
  {
    id: "vin",
    numeric: false,
    disablePadding: true,
    label: "Vehicle Identification Number",
    isSort: false,
    isBold: true,
  },
  {
    id: "policy_number1",
    numeric: true,
    disablePadding: false,
    label: "Policy Number",
    isSort: false,
  },
  {
    id: "model",
    numeric: false,
    disablePadding: true,
    label: "Model",
    isSort: false,
    isBold: true,
  },
  {
    id: "year",
    numeric: true,
    disablePadding: false,
    label: "Year",
    isSort: false,
  },
  {
    id: "make",
    numeric: false,
    disablePadding: false,
    label: "Make",
    isSort: false,
  },
  // {
  //   id: "action",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Action",
  //   isSort: false,
  // },
];


export const VehiclePolicyHeadCell = [
  {
    id: "policy_number1",
    numeric: true,
    disablePadding: false,
    label: "Policy Number",
  },
  {
    id: "effective_date",
    numeric: false,
    disablePadding: true,
    label: "Effective Date",
  },
  {
    id: "expiration_date",
    numeric: false,
    disablePadding: true,
    label: "Expiration Date",
  }
]

export const claimHeadCells = [
  {
    id: "claim_number",
    numeric: false,
    disablePadding: true,
    label: "Claim Number",
    isSort: false,
    isBold: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSort: false,
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
    isSort: false,
    align: "right",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    isSort: false,
  },
];

export const vehicleHeadCells = [
  {
    id: "vin",
    numeric: false,
    disablePadding: true,
    label: t("Vehicle_Identification_Number"),
    isSort: false,
    isBold: true,
  },
  {
    id: "year",
    numeric: true,
    disablePadding: false,
    label: t("Year"),
    isSort: false,
  },
  {
    id: "make",
    numeric: false,
    disablePadding: false,
    label: t("Make"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const vehicleHeadCellsView = [
  {
    id: "vin",
    numeric: false,
    disablePadding: true,
    label: t("Vehicle_Identification_Number"),
    isSort: false,
    isBold: true,
  },
  {
    id: "year",
    numeric: true,
    disablePadding: false,
    label: t("Year"),
    isSort: false,
  },
  {
    id: "make",
    numeric: false,
    disablePadding: false,
    label: t("Make"),
    isSort: false,
  },
];

export const commissionHeadCellsView = [
  {
    id: "origin",
    numeric: false,
    disablePadding: true,
    label: "Origin",
    isSort: false,
    isBold: false,
  },
  {
    id: "effective_date",
    numeric: true,
    disablePadding: false,
    label: "Date",
    isSort: false,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Process Date",
    isSort: false,
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
    isSort: false,
  },
  {
    id: "policy_commission",
    numeric: false,
    disablePadding: false,
    label: "Policy Commission",
    isSort: false,
    align: "right",
  },
  {
    id: "agency_commission_received",
    numeric: false,
    disablePadding: false,
    label: "Agency Comm Received",
    isSort: false,
    align: "right",
  },
  {
    id: "commission_owned",
    numeric: false,
    disablePadding: false,
    label: "Commission Owner",
    isSort: false,
    align: "right",
  },
  {
    id: "check",
    numeric: false,
    disablePadding: false,
    label: "Check Number",
    isSort: false,
  },
  {
    id: "reason",
    numeric: false,
    disablePadding: false,
    label: "Reason",
    isSort: false,
  },
];

export const masterTypeHeadCell = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Type",
    isSort: true,
    isClickable: true,
  },
  // {
  //   id: "is_active",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Active",
  //   isSort: true,
  // },
  // {
  //   id: "action",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Action",
  //   isSort: false,
  // },
];

export const masterOptionHeadCell = [
  {
    id: "values",
    numeric: false,
    disablePadding: false,
    label: "Name",
    isSort: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    isSort: true,
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Code",
    isSort: true,
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: false,
    label: "Active",
    isSort: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    isSort: false,
  },
];

export const companyHeadCell = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: t("Company Name"),
    isSort: true,
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: false,
    label: t("Active"),
    isSort: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const companyCommissionHeadCell = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: t("Company Name"),
    isSort: true,
  },
  // {
  //   id: "is_active",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Active",
  //   isSort: true,
  // },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const industryHeadCell = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: t("Industry Name"),
    isSort: true,
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: false,
    label: t("Active"),
    isSort: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const invoiceTableHeader = [
  {
    id: "invoice_type",
    numeric: false,
    disablePadding: false,
    label: t("Type"),
    isSort: false,
  },
  {
    id: "invoice_no",
    numeric: false,
    disablePadding: false,
    label: t("Number"),
    isSort: false,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: t("Date Invoiced"),
    isSort: false,
  },
  {
    id: "due_now",
    numeric: false,
    disablePadding: false,
    label: t("Due Now"),
    isSort: false,
    align: "right",
  },
  {
    id: "due_amount",
    numeric: false,
    disablePadding: false,
    label: t("Amount Due"),
    isSort: false,
    align: "right",
  },
  {
    id: "due_date",
    numeric: false,
    disablePadding: false,
    label: t("due_date"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const customerInvoiceTableHeader = [
  {
    id: "invoice_type",
    numeric: false,
    disablePadding: false,
    label: t("Type"),
    isSort: false,
  },
  {
    id: "invoice_no",
    numeric: false,
    disablePadding: false,
    label: t("Number"),
    isSort: false,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: t("Date Invoiced"),
    isSort: false,
  },
  {
    id: "due_now",
    numeric: false,
    disablePadding: false,
    label: t("Due Now"),
    isSort: false,
    align: "right",
  },
  {
    id: "due_amount",
    numeric: false,
    disablePadding: false,
    label: t("Amount Due"),
    isSort: false,
    align: "right",
  },
  {
    id: "due_date",
    numeric: false,
    disablePadding: false,
    label: t("due_date"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const invoiceOverviewTableHeader = [
  {
    id: t("policy_number"),
    numeric: false,
    disablePadding: false,
    label: t("policy_number"),
    isSort: false,
    align: "left",
  },
  // {
  //   id: "first_name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Name",
  //   isSort: false,
  //   align: "left",
  // },
  {
    id: "reason",
    numeric: false,
    disablePadding: false,
    label: t("Description"),
    isSort: false,
  },
  {
    id: "scheduled_amt",
    numeric: false,
    disablePadding: false,
    label: t("Amount"),
    isSort: false,
    align: "right",
  },

  {
    id: "due_amount",
    numeric: false,
    disablePadding: false,
    label: t("Due Amount"),
    isSort: false,
    align: "right",
  },
  {
    id: "due_date",
    numeric: false,
    disablePadding: false,
    label: t("Due Date"),
    isSort: false,
  },
];
export const invoiceOverviewTableHeader1 = [
  // {
  //   id: "policy_number",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Policy Number",
  //   isSort: false,
  //   align: "left",
  // },
  // {
  //   id: "first_name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Name",
  //   isSort: false,
  //   align: "left",
  // },
  {
    id: "reason",
    numeric: false,
    disablePadding: false,
    label: "Description",
    isSort: false,
  },
  {
    id: "scheduled_amt",
    numeric: false,
    disablePadding: false,
    label: "Amount",
    isSort: false,
    align: "right",
  },

  {
    id: "due_amount",
    numeric: false,
    disablePadding: false,
    label: "Due Amount",
    isSort: false,
    align: "right",
  },
  {
    id: "due_date",
    numeric: false,
    disablePadding: false,
    label: "Due Date",
    isSort: false,
  },
];

export const quotationTableHeader = [
  {
    id: "quotation_id",
    numeric: false,
    disablePadding: false,
    label: t("Quote Number"),
    isSort: true,
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  // {
  //   id: "email",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Email",
  //   isSort: false,
  // },
  // {
  //   id: "status",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Status",
  //   isSort: false,
  // },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: t("Created At"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];
export const testimonialTableHeader = [
  {
    id: "avatarCompany",
    numeric: false,
    disablePadding: false,
    label: t("Company Name"),
    isSort: false,
  },


  {
    id: "avatarName",
    numeric: false,
    disablePadding: false,
    label: t("name"),
    isSort: false,
  },
  // {
  //   id: "testimonial",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Testimonial Description",
  //   isSort: false,
  // },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const documentViewHeader = [
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: t("file_name" ),
    isSort: false,
    isBold: true,
  },
  // {
  //   id: "reason",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Reason",
  //   isSort: false,
  // },
  {
    id: "path",
    numeric: false,
    disablePadding: false,
    label: t("Source"),
    isSort: false,
  },
];
export const DashboardPopupHeader = [
  {
    id: "chequeNumber",
    numeric: true,
    disablePadding: true,
    label: "Check Number",
    isSort: false,
    isBold: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    isSort: false,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
    isSort: false,
  },
  {
    id: "policyNumber",
    numeric: false,
    disablePadding: false,
    label: "Policy Number",
    isSort: false,
  },
];

export const employeeHeadCell = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: t("first_name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: true,
  },
  {
    id: "mobile_number",
    numeric: false,
    disablePadding: false,
    label: t("Mobile"),
    isSort: false,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const locationHeadCell = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: t("Location Name"),
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: t("Location Code"),
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
  },
]

export const customerOverViewHeadCell = [
  {
    id: t("first_name"),
    numeric: false,
    disablePadding: true,
    label:t("first_name"),
    isSort: false,
    isBold: true,
  },
  // {
  //   id: "last_name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Last Name",
  //   isSort: false,
  // },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: false,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: t("Mobile"),
    isSort: false,
  },
];

export const customerOwnViewHeadCell = [
  {
    id: "policy_number",
    numeric: false,
    disablePadding: false,
    label: "Policy Number",
    isSort: false,
  },
  // {
  //   id: "reason",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Description",
  //   isSort: false,
  // },
  {
    id: "due_date",
    numeric: false,
    disablePadding: true,
    label: "Due Date",
    isSort: false,
    isBold: true,
  },
  {
    id: "scheduled_amt",
    numeric: false,
    disablePadding: false,
    label: "Scheduled amount",
    isSort: false,
    align: "right"
  },
  {
    id: "due_amount",
    numeric: false,
    disablePadding: false,
    label: "Due amount",
    isSort: false,
    align: "right"
  },
  {
    id: "types",
    numeric: false,
    disablePadding: false,
    label: "Type",
    isSort: false,
  },

];



export const policyExpiryHeaders = [
  {
    id: "user_name",
    numeric: false,
    disablePadding: false,
    label: t("name"),
    isSort: false,
    isBold: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: t("Contact Number"),
    isSort: false,
    isBold: true,
  },
  {
    id: "policy_number",
    numeric: false,
    disablePadding: false,
    label: t("Policy_number"),
    isSort: false,
    isBold: true,
  },
  {
    id: "policy_type",
    numeric: false,
    disablePadding: false,
    label: t("Policy_type"),
    isSort: false,
    isBold: true,
  },
  {
    id: "effective_date",
    numeric: false,
    disablePadding: false,
    label: t("Effective Date"),
    isSort: false,
    isBold: true,
  },
  {
    id: "expiration_date",
    numeric: false,
    disablePadding: false,
    label: t("Expiration Date"),
    isSort: false,
    isBold: true,
  },
  {
    id: "expired_days",
    numeric: false,
    disablePadding: false,
    label: t("Remaining Days to Expiry"),
    isSort: false,
    isBold: true,
  },
];

export const policyBalanceHeaders = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: t("Contact Number"),
    isSort: false,
    isBold: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: true,
    isBold: true,
  },
  {
    id: "policy_number",
    numeric: false,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: true,
    isBold: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: false,
  },
  {
    id: "total_premium",
    numeric: false,
    disablePadding: false,
    label: t("Policy Amount"),
    isSort: false,
    isBold: true,
    align: "right"
  },
  {
    id: "amount_received",
    numeric: false,
    disablePadding: false,
    label: t("Amount Received from Client"),
    isSort: false,
    isBold: true,
    align: "right"
  },
  {
    id: "amount_paid",
    numeric: false,
    disablePadding: false,
    label: t("Amount Paid to Carrier"),
    isSort: false,
    isBold: true,
    align: "right"
  },
  {
    id: "remaining_amount",
    numeric: false,
    disablePadding: false,
    label: t("Remaining Amount"),
    isSort: false,
    isBold: true,
    align: "right"
  },
];

export const policySuspendedHeaders = [
  {
    id: "policy_number",
    numeric: false,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: true,
    isBold: true,
  },

  {
    id: "policy_status",
    numeric: false,
    disablePadding: false,
    label: t("Policy Status"),
    isSort: false,
  },
  {
    id: "policy_type",
    numeric: false,
    disablePadding: false,
    label: t("Policy Type"),
    isSort: false,
  },
   {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: false,
  },

];



export const coverageTableHeader = [
  // {
  //   id: "pt",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "PT",
  //   isSort: false,
  // },
  {
    id: "endorsement_number",
    numeric: false,
    disablePadding: false,
    label: t("Number"),
    isSort: false,
  },
  {
    id: "start_date",
    numeric: false,
    disablePadding: false,
    label: t("Eff. Date Of Endorsement"),
    isSort: false,
  },
  {
    id: "type_of_change",
    numeric: false,
    disablePadding: false,
    label: t("Type Of Change"),
    isSort: false,
  },
  {
    id: "processDate",
    numeric: false,
    disablePadding: false,
    label: t("Process Date"),
    isSort: false,
  },
  {
    id: "completion_date",
    numeric: false,
    disablePadding: false,
    label: t("Completion Date"),
    isSort: false,
  },
  {
    id: "premium",
    numeric: false,
    disablePadding: false,
    label: t("Premium"),
    isSort: false,
    align: "right",
  },
  {
    id: "fee",
    numeric: false,
    disablePadding: false,
    label: t("Fee"),
    isSort: false,
    align: "right",
  },
  // {
  //   id: "description",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Description",
  //   isSort: false,
  // },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const compareHeadCell = [
  {
    id: "policyNumber",
    numeric: false,
    disablePadding: true,
    label: t("policy_number"),
    isSort: false,
    isBold: true,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: true,
    label: t("customer_name"),
    isSort: false,
    isBold: false,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: true,
    label: t("Location"),
    isSort: false,
    isBold: false,
  },
  {
    id: "total_due_amount",
    numeric: false,
    disablePadding: false,
    label: t("total_due_amount"),
    isSort: false,
    align: "right",
  },
  {
    id: "past_due_balance",
    numeric: false,
    disablePadding: false,
    label: t("past_due_amount"),
    isSort: false,
    align: "right",
  },
  {
    id: "difference",
    numeric: false,
    disablePadding: false,
    label: t("difference_amount"),
    isSort: false,
    align: "right",
  },
  {
    id: "effective_date",
    numeric: false,
    disablePadding: false,
    label: t("effective_date"),
    isSort: false,
  },
  {
    id: "expiration_date",
    numeric: false,
    disablePadding: false,
    label: t("expiration_date"),
    isSort: false,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: t("created_date"),
    isSort: false,
  },
  {
    id: "is_tally",
    numeric: false,
    disablePadding: false,
    label: t("Tally"),
    isSort: false,
  },
];

export const comparePaymentHeadCell = [
  {
    id: "policyNumber",
    numeric: false,
    disablePadding: true,
    label: "Policy Number",
    isSort: false,
    isBold: true,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
    isSort: false,
    isBold: false,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: true,
    label: "Location",
    isSort: false,
    isBold: false,
  },
  {
    id: "invoiceNumber",
    numeric: false,
    disablePadding: true,
    label: "Invoice Number",
    isSort: false,
    isBold: true,
  },
  {
    id: "total_due_amount",
    numeric: false,
    disablePadding: false,
    label: "Due Amount",
    isSort: false,
    align: "right",
  },
  {
    id: "transactionAmount",
    numeric: false,
    disablePadding: false,
    label: "Transaction Amount",
    isSort: false,
    align: "right",
  },
  {
    id: "transactionAmountElavon",
    numeric: false,
    disablePadding: false,
    label: "Transaction Amount Elavon",
    isSort: false,
    align: "right",
  },
  {
    id: "difference",
    numeric: false,
    disablePadding: false,
    label: "Difference Amount",
    isSort: false,
    align: "right",
  },
  {
    id: "tnxdate",
    numeric: false,
    disablePadding: false,
    label: "Transaction Date",
    isSort: false,
  },
  {
    id: "transactionStatus",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSort: false,
  },
];

export const policyLedgerHeadCell = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: t("first_name"),
    isSort: true,
    isBold: false,
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  {
    id: "policyNumber",
    numeric: false,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: false,
  },
  {
    id: "policyType",
    numeric: false,
    disablePadding: false,
    label: t("Policy Type"),
    isSort: true,
  },
  {
    id: "amountReceived",
    numeric: false,
    disablePadding: false,
    label: t("Amount Received"),
    isSort: false,
    align: "right"
  },
  {
    id: "receivedAt",
    numeric: false,
    disablePadding: false,
    label: t("Date Received"),
    isSort: false,
  },
  {
    id: "amountPaid",
    numeric: false,
    disablePadding: false,
    label: t("Amount Paid to carrier"),
    isSort: false,
    align: "right"
  },
  {
    id: "paidAt",
    numeric: false,
    disablePadding: false,
    label: t("Date Paid To Carrier"),
    isSort: false,
  },
  {
    id: "type_of_payment",
    numeric: false,
    disablePadding: false,
    label: t("Transfer Type"),
    isSort: false,
  },
];

export const policyPaymentToCarrierHeadCell = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: t("first_name"),
    isSort: true,
    isBold: false,
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  {
    id: "policyNumber",
    numeric: false,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: false,
  },
  {
    id: "policyType",
    numeric: false,
    disablePadding: false,
    label: t("Policy Type"),
    isSort: true,
  },
  {
    id: "amountPaid",
    numeric: false,
    disablePadding: false,
    label: t("Amount"),
    isSort: false,
    align: "right"
  },
  {
    id: "paymentMethod",
    numeric: false,
    disablePadding: false,
    label: t("Payment Method"),
    isSort: false,
  },
  {
    id: "paidAt",
    numeric: false,
    disablePadding: false,
    label: t("Date Paid"),
    isSort: false,
  },
];

export const policyCollectionHeaders = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: t("first_name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: t("Contact Number"),
    isSort: false,
    isBold: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: t("email"),
    isSort: true,
    isBold: true,
  },
  {
    id: "policy_number",
    numeric: false,
    disablePadding: false,
    label: t("Policy Number"),
    isSort: true,
    isBold: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: false,
  },
  {
    id: "total_premium",
    numeric: false,
    disablePadding: false,
    label: t("Total_Paid"),
    isSort: false,
    isBold: true,
    align: "right"
  },
  {
    id: "remaining_amount",
    numeric: false,
    disablePadding: false,
    label: t("Policy Balance Due"),
    isSort: false,
    isBold: true,
    align: "right"
  },
];

export const remaindersHeaders = [
  {
    id: "remainder_name",
    numeric: false,
    disablePadding: true,
    label: t("Reminder Name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "no_of_days",
    numeric: true,
    disablePadding: true,
    label: t("No of Days"),
    isSort: true,
    isBold: true,
  },
  {
    id: "heading",
    numeric: false,
    disablePadding: true,
    label: t("Heading"),
    isSort: true,
    isBold: true,
  },
  // {
  //   id: "custom_message",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Custom Message",
  //   isSort: true,
  //   isBold: true,
  // },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: t("type"),
    isSort: true,
    isBold: true,
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: false,
    label: t("Active"),
    isSort: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
]

export const bankHeadCell = [
  {
    id: "bank",
    numeric: false,
    disablePadding: true,
    label: "Bank Name",
    isSort: true,
    isBold: true,
  },
  {
    id: "account_no",
    numeric: false,
    disablePadding: true,
    label: "Account Number",
    isSort: true,
    isBold: true,
  },
  {
    id: "name_of_account",
    numeric: false,
    disablePadding: false,
    label: "Account Name",
    isSort: true,
  },
  {
    id: "routing_no",
    numeric: false,
    disablePadding: false,
    label: "Routing Number",
    isSort: true,
  },
  {
    id: "starting_check_no",
    numeric: false,
    disablePadding: false,
    label: "Starting Check Number",
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    isSort: false,
  },
];

export const bankInsuranceHeadCell = [
  {
    id: "bank",
    numeric: false,
    disablePadding: true,
    label: t("Bank Name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "account_no",
    numeric: false,
    disablePadding: true,
    label: t("Account Number"),
    isSort: true,
    isBold: true,
  },
  {
    id: "name_of_account",
    numeric: false,
    disablePadding: false,
    label: t("Account Name"),
    isSort: true,
  },
  {
    id: "routing_no",
    numeric: false,
    disablePadding: false,
    label: t("Routing Number"),
    isSort: true,
  },
  {
    id: "insurance_company",
    numeric: false,
    disablePadding: false,
    label: t("Insurance Company"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const chequeListHeadCell = [
  {
    id: "cheque_number",
    numeric: false,
    disablePadding: true,
    label: t("Check Number"),
    isSort: true,
    isBold: true,
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: t("first_name"),
    isSort: true,
    isBold: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: t("last_name"),
    isSort: true,
  },
  {
    id: "policy_number",
    numeric: false,
    disablePadding: true,
    label: t("Policy Number"),
    isSort: true,
    isBold: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: t("Location"),
    isSort: false,
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: t("Amount"),
    isSort: true,
  },
  {
    id: "print_draft_date",
    numeric: false,
    disablePadding: false,
    label: t("Print Draft Date"),
    isSort: true,
  },
  {
    id: "cheque_status",
    numeric: false,
    disablePadding: false,
    label: t("Check Status"),
    isSort: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Action"),
    isSort: false,
  },
];

export const chequeViewHeadCell = [
  {
    id: "cheque_number",
    numeric: false,
    disablePadding: true,
    label: "Check Number",
    isSort: true,
    isBold: true,
  },
  {
    id: "cheque_drop_date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    isSort: true,
  },
  {
    id: "cheque_status",
    numeric: false,
    disablePadding: false,
    label: "Check Status",
    isSort: false,
  },
  // {
  //   id: "cancel_reason",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Reason",
  //   isSort: true,
  // },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    isSort: false,
  },
  {
    id: "timestamp",
    numeric: false,
    disablePadding: false,
    label: "Date & Time",
    isSort: false,
  },
];

export const chequeDashboardListHeadCell = [
  {
    id: "cheque_number",
    numeric: false,
    disablePadding: true,
    label: t("Check Number"),
    isSort: true,
    isBold: true,
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: t("Amount"),
    isSort: true,
  },
  {
    id: "print_draft_date",
    numeric: false,
    disablePadding: false,
    label: t("Print Draft Date"),
    isSort: true,
  },
  {
    id: "cheque_status",
    numeric: false,
    disablePadding: false,
    label: t("Check Status"),
    isSort: false,
  }
];

export const customerImportHeadCell = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "First Name",
    isSort: false,
    isBold: true,
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    isSort: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    isSort: false,
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Contact Number",
    isSort: false,
  },
  {
    id: "is_tally",
    numeric: false,
    disablePadding: false,
    label: "Valid",
    isSort: false,
  },
];


export const commissionsHeadCellsView = [
  {
    id: "order",
    numeric: false,
    disablePadding: true,
    label: t("Order"),
    isSort: false,
    isBold: false,
  },
  {
    id: "percentage_of_premium_payment",
    numeric: true,
    disablePadding: false,
    label: t("Percentage of Premium"),
    isSort: false,
  },
  {
    id: "percentage_of_commission",
    numeric: false,
    disablePadding: false,
    label: t("Percentage of Commission"),
    isSort: false,
  },
  {
    id: "amount_scheduled",
    numeric: false,
    disablePadding: false,
    label: t("Amount Scheduled"),
    isSort: false,
    align: "right",
  },
  {
    id: "amount_received",
    numeric: false,
    disablePadding: false,
    label: t("Amount Received"),
    isSort: false,
    align: "right",
  },
  // {
  //   id: "policy_commission",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Policy Commission",
  //   isSort: false,
  //   align: "right",
  // },
  {
    id: "policy_commission_status",
    numeric: false,
    disablePadding: false,
    label: t("Status"),
    isSort: false,
    align: "right",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: t("Status"),
    isSort: false,
  },
];
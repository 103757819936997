import { Box, Grid, Typography } from "@mui/material";
import FormLayout from "component/form";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { Success } from "forms/helpers/toast.helper";
import { InputField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { emailConfirgurationPagevalidation, emailConfirgurationPagevalidationSchema } from "forms/hooks/validateRules";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


export default function EmailConfig(){

    const [loading, setLoading] = useState<any>(false);
    const  navigate = useNavigate()

    const initialValues = {
        email: null,
        emailPassword: null,
        emailHost: null,
        emailPort: null,
        fromEmail:null
    }


    const handleBack = () => {
        navigate(-1);
      };

    const formSubmit = () =>{
        setLoading(true);
        const api = `/settings-config/email`
        commonService.postService(api,values)
        .then((res)=>{
            setLoading(false);
            Success(res?.data?.message)
            handleBack()
        })
     }
     const { t } = useTranslation();
     const importModalT = emailConfirgurationPagevalidationSchema(t);
   
     const {
        errors,
        values,
        handleChange,
        handleSubmit,
        setPatchValue,
        setUpdateValue,

     } =  useForm(initialValues,formSubmit,emailConfirgurationPagevalidation, importModalT)

     useEffect(()=>{
        getEmailConfigData();
     },[])

     const getEmailConfigData = () => {
        commonService
        .getServices(`/settings-config/email`)
        .then((res:any)=>{
            const result =  res?.data?.data;
            const emailConfigData : any = {}
            emailConfigData.email = result?.email;
            emailConfigData.emailPassword = result?.emailPassword;
            emailConfigData.emailHost = result?.emailHost;
            emailConfigData.emailPort = result?.emailPort;
            emailConfigData.fromEmail = result?.fromEmail;
            setPatchValue(emailConfigData);
            setLoading(false);
        })
     }

    return (
        <>
        <MainContainerWrapper headingText="Email Configuration" isBackButton = {true}>
            <Box sx={{p:3}}>
            {loading && <Loading />}
            <FormLayout 
                handleSubmit={handleSubmit} 
                submitButtonText={t("Submit")}
            >
               <>
               <Grid container columnSpacing={2}>
               <Grid item xs={12} lg={4}>
                    <InputField
                        fieldName={t("email")}
                        values={values?.email || ""}
                        name="email"
                        errors={errors?.email}
                        handleChange={handleChange}
                        id="email"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <InputField
                        fieldName={t("Email Password")}
                        values={values?.emailPassword || ""}
                        name="emailPassword"
                        errors={errors?.emailPassword}
                        handleChange={handleChange}
                        // mask={true} // This will mask the input
                        id="emailPassword"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <InputField
                        fieldName={t("Email Host")}
                        values={values?.emailHost || ""}
                        name="emailHost"
                        errors={errors?.emailHost}
                        handleChange={handleChange}
                        id="emailHost"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <InputField
                        fieldName={t("Email Host")}
                        values={values?.emailPort || ""}
                        name="emailPort"
                        errors={errors?.emailPort}
                        handleChange={handleChange}
                        id="emailPort"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <InputField
                        fieldName={t("From Email")}
                        values={values?.fromEmail || ""}
                        name="fromEmail"
                        errors={errors?.fromEmail}
                        handleChange={handleChange}
                        id="fromEmail"
                    />
                </Grid>
               </Grid>
               </> 
            </FormLayout>
            </Box>
            
        </MainContainerWrapper>
        </>
    )
}
import { Box, Grid, Typography, IconButton, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBlocker } from "store/slice/blocker.slice";
import { useAppSelector } from "store/store";
import { commonService } from "forms/helpers/common.service";
import { Success, Failed } from "forms/helpers/toast.helper";
import FormLayout from "component/form";
import { InputField, InputTextAreaField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { createTestimonialValidation, createTestimonialValidationSchema } from "forms/hooks/validateRules";
import Loading from "container/loading";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import WithFileUpload from "forms/hoc/formfield/FileUpload";
import ImageCropper from "pages/cropper/Cropper"; // Import the cropper component
import { shortenFileName } from "forms/hooks/customHook";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);
  const [imageType, setImageType] = useState<string>("");
  const [isCropperOpen, setCropperOpen] = useState<boolean>(false);
  const [filePath, setFilePath] = useState("");
  const [fileType, setFileType] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    testimonial: "",
    avatarName: "",
    avatarCompany: "",
    avatarPath: "",
  };

  const formSubmit = () => {
    dispatch(setBlocker({ status: false }));
    setLoading(true);
    const api = "/testimonials";
    const bodyData = id
      ? { ...values, avatarPath: `${values.path}`, id }
      : { ...values, avatarPath: `${values.path}` };

    commonService
      .postPutService(api, bodyData, id ? "patch" : "post")
      .then((res) => {
        Success(res?.data?.message);
        setLoading(false);
        handleBack();
      })
      .catch((err) => {
        dispatch(setBlocker({ status: true }));
        setLoading(false);
        Failed("Something went wrong, please try again later");
      });
  };

  // const handleFileChange = (e: any) => {
  //   const selectedFiles = e.target.files;
  //   if (selectedFiles && selectedFiles.length) {
  //     let arr = [...selectedFiles];
  //     const limit: any = process.env.REACT_APP_UPLOAD_LIMIT;
  //     let selectedSize = arr.reduce((acc: any, f: any) => {
  //       return f.size + acc;
  //     }, 0);

  //     if (selectedSize >= (+limit || 10) * 1024 * 1024) {
  //       return Failed("Size of attachments cannot exceed 10MB");
  //     }
  //     for (const file of selectedFiles) {
  //       if (!file.type.startsWith("image/")) {
  //         alert("Please select image files only.");
  //         return;
  //       }
  //     }
  //     const selectedFile: any = selectedFiles[0];
  //     console.log("current",selectedFile);

  //     if (selectedFile) {
  //       fileUpload(selectedFile);
  //     }
  //   }
  // };

  const handleFileChange = (e:any) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length) {
      let arr = [...selectedFiles];
      const limit = parseInt(process.env.REACT_APP_UPLOAD_LIMIT || "10", 10); // Use environment variable or default to 10 MB
      const maxSizeBytes = limit * 1024 * 1024; // Convert MB to bytes
  
      // Calculate the total size of selected files
      let selectedSize = arr.reduce((acc, file) => acc + file.size, 0);
  
      if (selectedSize > maxSizeBytes) {
        // Check if the total size exceeds the maximum allowed size
        Failed(`Size of attachments cannot exceed ${limit}MB`);
        return;
      }
  
      // Check if all selected files are images
      for (const file of selectedFiles) {
        if (!file.type.startsWith("image/")) {
          // Alert if any file is not an image
          alert("Please select image files only.");
          return;
        }
      }
  
      // Process the first file if validation passes
      const file = selectedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setUpdateValue("path", file.name);
        setUpdateValue("file_type", file.type);
        setImageType(file.type);
        setImage(reader.result as string);
        setCropperOpen(true);
      };
  
      reader.readAsDataURL(file);
    }
  };
  
  

  const handleCrop = (croppedImage: string) => {
    fileUpload(croppedImage);
    setCropperOpen(false);
  };

  const fileUpload = (croppedImage: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", croppedImage);
    formData.append("upload_type", "customer");

    commonService
      .fileUploadService("/uploads", formData)
      .then((res: any) => {
        setLoading(false);
        if (res?.data) {
          console.log("ressss file", res?.data?.data[0]?.path);
          setUpdateValue("path", res.data?.data[0]?.path);
          setUpdateValue("file_type", res.data?.data[0]?.file_type);
          setFilePath(res.data?.data[0]?.path);
          setFileType(res.data?.data[0]?.file_type);
        } else {
          Failed("Something went wrong");
        }
        setCropperOpen(false)
      })
      .catch((err) => {
        setLoading(false);
        Failed("Failed to Upload files");
      });
  };
  const { t } = useTranslation();
  const createTestiminoalT =  createTestimonialValidationSchema(t);

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setPatchValue,
    setUpdateValue,
  } = useForm(initialValues, formSubmit, createTestimonialValidation,createTestiminoalT);

  const handleRemoveFile = () => {
    setUpdateValue("path", "");
    setUpdateValue("file_type", "");
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(setBlocker({ status: true }));
    if (id) {
      getTestimonialData();
    }
  }, []);

  const getTestimonialData = () => {
    setLoading(true);
    commonService
      .getServices(`/testimonials/${id}`)
      .then((res: any) => {
        const testimonialInfo = {
          testimonial: res?.data?.data?.testimonial,
          avatarName: res?.data?.data?.avatarName,
          avatarCompany: res?.data?.data?.avatarCompany,
          path: res?.data?.data?.avatarPath,
          file_type: res?.data?.data?.avatarPath?.split(".").pop(),
        };
        setPatchValue(testimonialInfo);
        setFilePath(res?.data?.data?.avatarPath);
        setFileType(res?.data?.data?.avatarPath?.split(".").pop());
        setLoading(false);
      })
      .catch((err) => {
        Failed("Something went wrong, please try again later");
      });
  };
  
  return (
    <MainContainerWrapper
     headingText={`${id ? t("Edit") : t("Create")} ${t("Testimonial")}`}
      isBackButton={true}
    >
      <Box sx={{ p: 3, height: "80vh", width: "100%" }}>
        <FormLayout
          handleSubmit={handleSubmit}
          isBackButton
          backButtonClick={handleBack}
          backButtonText={t("Cancel")}
          isSaveButton
        >
          {loading && <Loading />}
          {isCropperOpen && image && (
            <ImageCropper
              image={image}
              onCrop={handleCrop}
              onClose={() => {
                setPatchValue({
                  path: filePath,
                  file_type: fileType,
                });
                setCropperOpen(false);
              }}
              handleFile={fileUpload}
              imageType={imageType}
              handleClose={()=>console.log("hehelo")}
              open={()=>console.log("hehelo")}
              cropShape="round"
              aspect={1/1}
            />
          )}
          <Grid container>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Name")}
                  values={values?.avatarName || ""}
                  name="avatarName"
                  errors={errors?.avatarName}
                  handleChange={handleChange}
                  id="avatarName"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputField
                  fieldName={t("Company Name")}
                  values={values?.avatarCompany || ""}
                  name="avatarCompany"
                  errors={errors?.avatarCompany}
                  handleChange={handleChange}
                  id="avatarCompany"
                />
              </Grid>
              <Grid item xs={0} lg={4}>

</Grid>
              <Grid item xs={12} pt={3} lg={4}>
                <WithFileUpload
                  fileAdded={!!values?.path}
                  fileName={shortenFileName(values?.path)}
                  actionText={"Add"}
                  handleRemoveFile={handleRemoveFile}
                  handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFileChange(e)
                  }
                  errors={errors?.path}
                  fieldName={t("Avatar Image")}
                  fileType={"image/*"}
                  helpText={t("Image only")}
                  buttonText={t("Upload File")}
                  maxmiumText={t("Maximum file size 10mb")}
                />
              </Grid>
              <Grid item xs={12} pt={3} lg={4}>
                <InputTextAreaField
                  fieldName={t("Testimonial")}
                  values={values?.testimonial || ""}
                  name="testimonial"
                  errors={errors?.testimonial}
                  handleChange={handleChange}
                  id="testimonial"
                />
              </Grid>
            </Grid>
          </Grid>
        </FormLayout>
      </Box>
    </MainContainerWrapper>
  );
};

export default Testimonial;

import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import FormLayout from "component/form";
import {
  InputField,
  PhoneField,
  SelectField,
} from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { setBlocker } from "store/slice/blocker.slice";
import { RemainderValidation, RemainderValidationSchema } from "forms/hooks/validateRules";
import AddIcon from "@mui/icons-material/Add";
import { errorMessage } from "forms/helpers/function.helper";
import { useEffect, useRef, useState } from "react";
import { commonService } from "forms/helpers/common.service";
import Loading from "container/loading";
import { useLocation, useNavigate, useParams, unstable_usePrompt } from "react-router-dom";
import { Failed, Success } from "forms/helpers/toast.helper";
import { parsePhoneNumber } from "forms/hooks/customHook";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import TextView, { TextViewLink } from "forms/hoc/text-view";
import { useAppSelector } from "store/store";
import { useDispatch } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from "react-i18next";


const ReminderCreate = () => {
  const [loading, setLoading] = useState<any>(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const blockerData = useAppSelector((state) => state.blocker.blocker)
  const actionText = state?.actionText;
  const dispatch = useDispatch();
  const initialValues = {
    remainder_name: "",
    heading: "",
    custom_message: null,
    type: ""
  };
  const [options, setOptions] = useState([{ value: "Invoice Reminder", name: "Invoice Reminder" }, { value: "Renewal Notice Reminder", name: "Renewal Notice Reminder" }]);
  const [mentionOpen, setMentionOpen] = useState(false);
  const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const quillRef = useRef<ReactQuill | null>(null);
  const [mentions, setMentions] = useState<string[]>([])

  // unstable_usePrompt({
  //   message: `Are you sure you want to leave? You haven't saved your changes yet.`,
  //   when: ( { currentLocation, nextLocation }) => {
  //     return  (
  //       (blockerData?.status) && currentLocation.pathname !== nextLocation.pathname)
  //     },
  //   })

  const formSubmit = () => {
    setLoading(true);
    console.log(values);
    const api = id ? `/invoice-reminder/${id}` : `/invoice-reminder`;
    let bodyData = {...values};
    bodyData.no_of_days = values?.no_of_days?.split(",");
    commonService
      .postPutService(api, {
        ...bodyData
      }, id ? "patch" : "post")
      .then((res) => {
        Success(res?.data?.message);
        setLoading(false);
        handleBack();
      })
      .catch((err) => {
        setLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };

  const getReminderConstants = () => {
    const api = `/invoice-reminder/constants`;
    commonService
      .getServices(api)
      .then((res) => {
        let m = res?.data?.data.map(function (item: any) {
          return item['name'];
        });
        setMentions(m)
        setLoading(false);
        // handleBack();
      })
      .catch((err) => {
        setLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  }

  const { t } = useTranslation();
  const importModalT = RemainderValidationSchema(t);

  const {
    errors,
    values,
    handleChange,
    setUpdateValue,
    handleSubmit,
    setPatchValue,
  } = useForm(initialValues, formSubmit, RemainderValidation, importModalT);

  useEffect(() => {
    //getting the reminder constants
    getReminderConstants()

    dispatch(setBlocker({ status: actionText === "View" ? false : true }));
    if (id) {
      getReminderData();
    }
  }, []);

  const getReminderData = () => {
    setLoading(true);
    commonService
      .getServices(`/invoice-reminder/${id}`)
      .then((res: any) => {
        const reminderInfo: any = {};
        reminderInfo.remainder_name = res?.data?.data?.remainder_name;
        reminderInfo.no_of_days = res?.data?.data?.no_of_days;
        reminderInfo.heading = res?.data?.data?.heading;
        reminderInfo.custom_message = res?.data?.data?.custom_message;
        reminderInfo.type = res?.data?.data?.type;
        setPatchValue(reminderInfo);
        setLoading(false);
      })
      .catch((err) => {
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
      });
  };

  const handleBack = () => {
    navigate(-1);
  }



  const handleChanges = (content: string, delta: any, source: string, editor: any) => {
    // setEditorHtml(content);
    //update custom message
    const reminderInfo: any = {};
    reminderInfo.custom_message = content;
    setPatchValue(reminderInfo);
    // setUpdateValue("custom_message", content);

    const cursorPosition = editor.getSelection()?.index;
    if (cursorPosition !== undefined) {
      const textBeforeCursor = editor.getText(0, cursorPosition);
      const mentionTriggerIndex = textBeforeCursor.lastIndexOf('@');

      if (mentionTriggerIndex !== -1 && cursorPosition > mentionTriggerIndex) {
        const mentionQuery = textBeforeCursor.slice(mentionTriggerIndex + 1, cursorPosition);
        const filteredSuggestions = mentions.filter((mention) =>
          mention.toLowerCase().includes(mentionQuery.toLowerCase())
        );
        setSuggestions(filteredSuggestions);

        const bounds = editor.getBounds(cursorPosition);
        setMentionPosition({
          top: bounds.top + bounds.height,
          left: bounds.left,
        });

        setMentionOpen(true);
      } else {
        setMentionOpen(false);
      }
    } else {
      setMentionOpen(false);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const selection = editor.getSelection();
      if (selection) {
        const cursorPosition = selection.index;
        const textBeforeCursor = editor.getText(0, cursorPosition);
        const mentionTriggerIndex = textBeforeCursor.lastIndexOf('@');
        editor.deleteText(mentionTriggerIndex, cursorPosition - mentionTriggerIndex);
        editor.insertText(mentionTriggerIndex, "{" + suggestion + "} ");
        editor.setSelection(mentionTriggerIndex + suggestion.length);
      }
    }
    setMentionOpen(false);
  };

  useEffect(() => {
    if (values.type) {
      setUpdateValue("heading", values.type == "Invoice Reminder" && actionText == "Create" ? "Urgent: Payment Due for Invoice" : values.type == "Renewal Notice Reminder" && actionText == "Create" ? "Reminder: Your Policy Renewal is Coming Up" : values.type == "Reconciliation" && actionText == "Create" ? "" : values?.heading)
      setUpdateValue("custom_message", values.type == "Invoice Reminder" && actionText == "Create" ? `<div class="container">
<div class="content">
<p>Dear {Customer Name} ,</p>
<p>We hope this message finds you well.</p>
<p>This is a friendly reminder that your invoice #{Invoice Number}, is due on {Due Date}.</p>
<h3>Invoice Details:</h3>
<ul>
<li><strong>Invoice Number:</strong> {Invoice Number}</li>
<li><strong>Due Date:</strong> {Due Date}</li>
<li><strong>Total Amount Due:</strong> $ {Amount Due}</li>
</ul>
<p>To ensure timely processing and avoid any late fees, please arrange for payment by the due date.</p>
<p>If you have already made the payment, please disregard this reminder.</p>
<p>Thank you for your prompt attention to this matter.</p><br/>
 <p>{click here} to download Invoice.</p><br/>
<p>Best Regards,</p>
<p>{Carrier Name}</p>
</div>
<div class="footer">
<p>&copy; 2024 {Carrier Name}. All rights reserved.</p>
</div>
</div>
` : values.type == "Renewal Notice Reminder" && actionText == "Create" ? `<div class="container">
        <div class="content">
            <div class="container">
        <div class="content">
            <p>Dear {Customer Name} ,</p>
            <p>We hope this email finds you well.</p>
            <p>This is a friendly reminder that your policy #{Policy Number}  is up for renewal on {Renewal Date}. To ensure continuous coverage and avoid any interruptions, please take a moment to review and renew your policy before the due date.</p>
            <h3>Policy Details:</h3>
            <ul>
                <li><strong>Policy Number:</strong> {Policy Number}</li>
                <li><strong>Renewal Date:</strong> {Renewal Date}</li>
            </ul>
            <br/><br/>
            <p>Best Regards,</p>
            <p>{Carrier Name}</p>
        </div>
        <div class="footer">
            <p>&copy; 2024 {Carrier Name}. All rights reserved.</p>
        </div>
    </div>` : values.type == "Reconciliation" && actionText == "Create" ? "" : values?.custom_message)
    }
  }, [values.type])

  console.log("values", values)

  return (
    <MainContainerWrapper
      headingText={`${actionText ?? "Create"} Reminder`}
    >
      <Box
        sx={{
          p: 3,
          height: "80vh",
          width: "100%"
        }
        }>
        <FormLayout
          handleSubmit={handleSubmit}
          isBackButton
          backButtonClick={handleBack}
          backButtonText={actionText === "View" ? "Back" : "Cancel"}
          isSaveButton={actionText !== "View"}
        >
          {loading && <Loading />}
          <Grid container>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={12}>



                <Box width={{ lg: '60%', xs: '100%' }}>
                  {actionText === "View" ?
                    <TextView
                      fieldName="Type"
                      fieldValue={values?.no_of_days ? values?.type : "-"}
                    />
                    : <SelectField
                      fieldName="Type"
                      values={values?.type || ""}
                      name="type"
                      errors={errors?.type}
                      handleChange={handleChange}
                      options={options}
                      id="type"
                    />}
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Box width={{ lg: '60%', xs: '100%' }}>
                  {actionText === "View" ?
                    <TextView
                      fieldName="Number of Days"
                      fieldValue={values?.no_of_days ? values?.no_of_days : "-"}
                    />
                    : <InputField
                      fieldName="Number of Days"
                      values={values?.no_of_days}
                      name="no_of_days"
                      errors={errors?.no_of_days}
                      handleChange={handleChange}
                      id="no_of_days"
                      isInfo={true}
                      onlyNumComma = {true}
                      // onlyNumber={true}
                    />}
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>

                <Box width={{ lg: '60%', xs: '100%' }}>
                  {actionText === "View" ?
                    <TextView
                      fieldName="Reminder Name"
                      fieldValue={values?.remainder_name ? values?.remainder_name : "-"}
                    />
                    : <InputField
                      fieldName="Reminder Name"
                      values={values?.remainder_name || ""}
                      name="remainder_name"
                      errors={errors?.remainder_name}
                      handleChange={handleChange}
                      id="remainder_name"
                    />}
                </Box>


              </Grid>
              <Grid item xs={12} lg={12}>
                <Box width={{ lg: '60%', xs: '100%' }}>
                  {actionText === "View" ?
                    <TextView
                      fieldName="Heading"
                      fieldValue={values?.heading ? values?.heading : "-"}
                    />
                    : <InputField
                      fieldName="Heading"
                      values={values?.heading || ""}
                      name="heading"
                      errors={errors?.heading}
                      handleChange={handleChange}
                      id="heading"
                    />}
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                {actionText === "View" ?
                  <TextView
                    fieldName="Custom Message"
                    isHtmlContent={true}
                    fieldValue={values?.custom_message ? values?.custom_message : "-"}
                  />
                  :
                  <Box width={{ lg: '60%', xs: '100%' }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1}
                      mt={1}
                    >
                      <Typography
                        py="10px"
                        fontWeight={400}
                        fontSize={"1rem"}
                      // color={theme.palette.text.dark}
                      >
                        Custom Message
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Stack>

                    <Box style={{ position: 'relative' }} sx={{
                      '.quill': {
                        border: !!errors?.custom_message ? "1px solid red" : ""
                      },
                      '.ql-container.ql-snow': {
                        height: '200px',
                      }
                    }}>
                      <ReactQuill
                        ref={quillRef}
                        // style={{ height: '200px' }}
                        value={values?.custom_message}
                        onChange={handleChanges}
                        modules={{
                          toolbar: [
                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                            [{ size: [] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            ['link', 'image', 'video'],
                            ['clean']
                          ],
                        }}
                      />
                      {mentionOpen && (
                        <div
                          style={{
                            position: 'absolute',
                            top: mentionPosition.top,
                            left: mentionPosition.left,
                            backgroundColor: 'white',
                            border: '1px solid #ccc',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                            zIndex: 1000,
                            maxHeight: 200,
                            overflowY: 'auto'
                          }}
                        >
                          <ul style={{ margin: 0, padding: 8, listStyleType: 'none' }}>
                            {suggestions.map((suggestion, index) => (
                              <li
                                key={index}
                                style={{ padding: 8, cursor: 'pointer' }}
                                onMouseDown={() => handleSuggestionClick(suggestion)}
                              >
                                {suggestion}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {!!errors.custom_message && <small style={{ color: '#f44336', fontFamily: 'PoppinsVariableFont', backgroundColor: '#ffffff' }}>{errors.custom_message}</small>}
                    </Box>
                  </Box>
                }

              </Grid>
            </Grid>
          </Grid>
        </FormLayout>
      </Box>
    </MainContainerWrapper>
  );
};

export default ReminderCreate;

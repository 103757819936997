import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import EnhancedTable from "component/tables/selectableTable";
import Loading from "container/loading";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import { dynamicSort } from "helpers/function.helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { driverHeadCellsView, policySuspendedHeaders } from "utils/table.utils";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { t } from "i18next";
import { AutoCompleteField, InputDatePicker } from "forms/hoc/formfield";


export default function SuspensionPolicy() {
  const policySuspendedHeaders = [
    {
      id: "policy_number",
      numeric: false,
      disablePadding: false,
      label: t("Policy Number"),
      isSort: true,
      isBold: true,
    },
  
    {
      id: "policy_status",
      numeric: false,
      disablePadding: false,
      label: t("Policy Status"),
      isSort: false,
    },
    {
      id: "policy_type",
      numeric: false,
      disablePadding: false,
      label: t("Policy Type"),
      isSort: false,
    },
     {
      id: "location",
      numeric: false,
      disablePadding: false,
      label: t("Location"),
      isSort: false,
    },
  
  ];

    const [from, setFrom] = useState(dayjs().subtract(30, "day"));
    const [to, setTo] = useState(dayjs());
    let [policyData, setPolicyData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [order, setOrder] = useState<"desc" | "asc">("asc");
    const [orderBy, setOrderBy] = useState<string>("firstName");
    const [typeList, setTypeList] = useState([]);
    const [location, setLocation] = useState<any>(null);
    const navigate = useNavigate();
  
  
    useEffect(() => {
      handleFilter();
    }, []);
  
    useEffect(() => {
      getLocation();
    }, [])
  
    const getLocation = async () => {
      try {
        const masterData: any = await commonService.getServices(
          `/location/get-all-location`
        );
        const tableDetails = masterData?.data?.data?.results?.map((item: any) => {
          return {
            value: item?.id,
            name: item?.name
          };
        });
        setTypeList(tableDetails);
      } catch (error) {}
    };
  
  
    const handleFilter = async () => {
      try {
        const bodyData : any = {}
        if(location) bodyData['location'] = location?.value;
        const policyData: any = await commonService.postService(
          `/policy/suspended-policy`,
          bodyData
        );
        console.log("policcuyyy,",policyData);
        
        const tableDetails = policyData?.data?.data?.map((item: any) => {
          return {
            id: item?.policy_id,
            policy_number: item?.policy_number,
            policy_status: item?.policy_status,
            policy_type : item?.policy_type,
            location:item?.location
          };
        });
        setPolicyData(tableDetails || []);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        Failed(errorMessage(err, "Something went wrong"));
      }
    };
  
    const handleExport = async () => {
      try {
        // const isBefore = dayjs(from).isBefore(dayjs(to));
        // if (!isBefore) {
        //   Failed("From date must be a before to date");
        //   return;
        // }
        setDownloading(true);
        const pdf = await axios({
          method: "post",
          url: `${process.env.REACT_APP_END_URL}/policy/suspended-policy/export`,
          data: {
            location:location?.value
          },
          responseType: "blob",
        });
        saveAs(pdf?.data, `Suspended Policies-${Date.now()}.xlsx`);
        setDownloading(false);
      } catch (err) {
        setDownloading(false);
        console.log("err", err);
        Failed(errorMessage(err, "Something went wrong, Please try again later"));
      }
    };
  
    const sortArray = (key: string, value: string) => {
      setLoading(true);
      const sortedArray = dynamicSort(
        policyData,
        value,
        order === "asc" ? "desc" : "asc"
      );
      policyData = [];
  
      setTimeout(() => {
        policyData = [...sortedArray];
        setPolicyData(policyData);
        setLoading(false);
      }, 100);
      setOrder(order === "asc" ? "desc" : "asc");
    }
    return (
      <>
        {downloading && <Loading />}
        <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              border: "solid 1px #ccc",
              backgroundColor: "#f1f1f1",
              borderRadius: "10px 10px 0 0",
              borderBottom: "none",
            }}
          >
                        <Stack
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"left"}
                alignItems={"center"}
              >
              <Box
                    onClick={() => navigate(-1)} 
                    sx={{ cursor: "pointer", display: "flex", alignItems: "center", mr: 1 , paddingLeft : 2}}
                  >
                    <KeyboardBackspaceIcon /> 
               </Box>
            <Typography
              pb="10px"
              variant="h3"
              pt="10px"
              sx={{ marginLeft: "10px", fontSize: "1.2rem" }}
            >
             {t("Suspended Policies")}
            </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              border: "solid 1px #ccc",
              borderRadius: "0px 0px 10px 10px",
              overflow: "hidden",
            }}
          >
            <Grid
              container
              pb={2}
              columnSpacing={2}
              display={"flex"}
              alignItems={"flex-end"}
              sx={{ backgroundColor: "#fff" }}
              spacing={2}
            >
              {/* <Grid item lg={2} xs={12}>
                <Box sx={{ ml: 2 }} >
                <InputDatePicker
                  values={from}
                  name="from"
                  id="from"
                  fieldName={t("From")}
                  handleChange={(e) => setFrom(dayjs(e))}
                  isRequired={false}
                />
                </Box>
              </Grid>
              <Grid item lg={2} xs={12}>
              <Box sx={{ ml: 2 }} >
                <InputDatePicker
                  values={to}
                  name="to"
                  id="to"
                  fieldName={t("To")}
                  isRequired={false}
                  handleChange={(e) => setTo(dayjs(e))}
                />
                </Box>
              </Grid> */}

<Grid item
              lg={3}
              xs={12}
              >
            <Box 
                sx={{
                  marginTop:"8px",
                  px:2
                }}
                >
                  <AutoCompleteField
                    placeholder={t("Filter By Location")}
                    isRequired={false}
                    values={location || null}
                    name="type"
                    handleChange={(type: any) => setLocation(type)}
                    options={typeList}
                    id="type"
                  />
                
                </Box>
            </Grid>
              <Grid
                item
                lg={5}
                xs={12}
              >
                <Button variant="contained"
                sx={{
                  ml: {
                    xs: "10px",
                  },
                  minHeight: "51px"
                  
                }}
                onClick={handleFilter}>
                  {t("Filter")}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ml: {
                      xs: "10px",
                    },
                    minHeight: "51px"
                    
                  }}
                  disabled={!policyData.length}
                  onClick={() => policyData.length && handleExport()}
                >
                  {t("Export")}
                </Button>
              </Grid>
              <Grid item
                lg={3}
                xs={12}
                >
              <Box 
                  sx={{
                    px: 2
                  }}
                  >
                    {/* <AutoCompleteField
                      placeholder={"Filter By Location"}
                      isRequired={false}
                      values={location || null}
                      name="type"
                      handleChange={(type: any) => setLocation(type)}
                      options={typeList}
                      id="type"
                    /> */}
                  
                  </Box>
              </Grid>
            </Grid>
            <Grid sx={{ padding: "10px", backgroundColor: "#fff" }}>
              <EnhancedTable
                headCells={policySuspendedHeaders}
                rows={loading ? [] : policyData}
                maxHeightValue={"550px"}
                isPagination={false}
                isLoading={loading}
                handleRequestSort={sortArray}
                routingLink={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

export enum QuoteStatus {
  INITIATED = "INITIATED",
  APPROVED = "APPROVED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED"
}

export enum Roles {
  TenantAdmin= "Tenant Admin",
  TenantEmployee= "Tenant Employee",
  Customer= "Customer",
}

export enum PaymentStatus {
  Paid ="Paid", 
  Scheduled ="Scheduled", 
  PayNow ="Pay now"
}

export const CustomerTypes: any = {
  "Individual": "INDIVITUAL", 
  "Two Individuals": "TWO_INDIVITUAL", 
  "Corporation": "CORPORATE"
}

import { Stack } from '@mui/material';
import TabComponent from 'component/tabs';
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import { useEffect, useState } from 'react'
import PaymentConfig from '../PaymentConfig';
import BankList from '../List';
import { useParams } from 'react-router-dom';
import { setLocationID } from 'store/slice/location.slice';
import { useAppDispatch, useAppSelector } from 'store/store';
import Loading from 'container/loading';
import LocationView from './LocationView';
 import { useTranslation } from "react-i18next";
 

const Location = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const { locationID } = useAppSelector((state) => state.location);

    const handleNextTab = () => {
      setTab((pre: number) => pre + 1);
    };
  
    const handlePreTab = () => {
      setTab((pre: number) => pre - 1);
    };
    const params = useParams();
    useEffect(() => {
      if(params?.id) {
        dispatch(setLocationID(params?.id));
      }
      setTimeout(() => {
        setLoading(false);
      }, 0);
      return() => {
        dispatch(setLocationID(null));
      }
    }, []);

    const tabs = 
    [
      {
        label: t("Location"),
        value: 0,
        Component: (
          <LocationView />
        ),
        disabled: false,
      },
      {
        label: t("Payment Config"),
        value: 1,
        Component: <PaymentConfig isView={true} handleNextTab={handleNextTab} handlePreTab={handlePreTab} />,
        disabled: false,
      },
      // {
      //   label: "Banks",
      //   value: 2,
      //   Component: <BankList isViewList={true} handlePreTab={handlePreTab} />,
      //   disabled: false,
      // }
    ];
  return (
    <MainContainerWrapper headingText={t("Location")} isBackButton = {true}>
      <Stack flexGrow={1}>
        {!locationID ? 
        <Loading /> :
        <TabComponent
          handleChange={(e: any, value: number) => setTab(value)}
          value={tab}
          tabs={tabs}
        />}
      </Stack>
  </MainContainerWrapper>
  )
}

export default Location;
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "store/store";
import { Roles } from "helpers/enum.helpers";

const GuestRoute = () => {
    const auth = useAppSelector((state) => state.user.user);
    console.log("GuestRoute", auth);
    let location = useLocation();
    return Object.keys(auth).length > 0 ? (
      auth?.role?.name === Roles.Customer ? (
        <Navigate to={`/customer/dashboard`} state={{ from: location }} replace />
      ) :
      auth?.plan?.is_free === 0?
      (
        <Navigate to={`/customer/dashboard`} state={{ from: location }} replace />
      ):
      (
        <Navigate to={`/customer-policy`} state={{ from: location }} replace />
      )
    ) : (
      <Outlet />
    );
  };

  

export default GuestRoute;
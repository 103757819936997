import { tenent, tenentFree } from "./tenent";
import client from "./client";
import { Roles } from "helpers/enum.helpers";
import Employee from "./employee";

const menuItems: any = {
  [Roles.TenantAdmin]: [tenent],
  [Roles.TenantEmployee]: [Employee],
  [Roles.Customer]: [client],
  "tenentFree": [tenentFree],
};

export default menuItems;

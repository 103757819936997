import { Box } from '@mui/material'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function RefernceList() {



  const { t } = useTranslation()

  const financeCompanyListHeaderCell = [

    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("Name"),
      isSort: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: t("email"),
      isSort: true,
      isBold: true,
    },
    {
      id: "contact_name",
      numeric: false,
      disablePadding: false,
      label: t("contact_name"),
      isSort: true,
    },
    {
      id: "account",
      numeric: false,
      disablePadding: false,
      label: t("account"),
      isSort: true,
    },
    {
      id: "website",
      numeric: false,
      disablePadding: false,
      label: t("website"),
      isSort: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: t("Action"),
      isSort: false,
    },

  ]

  const navigate =  useNavigate()

  const handleCreateReference = () => {
    navigate(`create`)
  }

  return (
    <>
      <MainContainerWrapper
        headingText='Reference List'
        isCreateButton
        isBackButton={true}
        buttonText={t("Create Reference")}
        buttonIcon={<span>&#43;</span>}
        handleClick={handleCreateReference}
      >

      </MainContainerWrapper>
    </>
  )
}

export default RefernceList
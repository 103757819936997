import {Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import EnhancedTable from "component/tables/selectableTable";
import dayjs from "dayjs";
import { commonService } from "forms/helpers/common.service";
import { AutoCompleteField, InputDatePicker } from "forms/hoc/formfield";
import { useEffect, useState } from "react";
import { CustomerListHeadCell } from "utils/table.utils";
import axios from "axios";
import { saveAs } from "file-saver";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import Loading from "container/loading";
import { dynamicSort } from "helpers/function.helper";
import { useAppSelector } from "store/store";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { t } from "i18next";

const RenewalReport = () => {
  const ProspectHeadCell = [
    {
        id: "first_name1",
        numeric: false,
        disablePadding: false,
        label: t("first_name"),
        isSort: true,
      },
      {
        id: "last_name1",
        numeric: false,
        disablePadding: false,
        label: t("last_name"),
        isSort: true,
      },
    {
      id: "policy_number",
      numeric: true,
      disablePadding: false,
      label: t("policy_number"),
      isSort: true,
    },
    {
        id: "effective_date",
        numeric: false,
        disablePadding: false,
        label: t("effective_date"),
        isSort: true,
        isBold: false,
      },
    {
        id: "expiration_date",
        numeric: false,
        disablePadding: false,
        label: t("expiration_date"),
        isSort: true,
        isBold: false,
      },

    {
      id: "insurance_company",
      numeric: false,
      disablePadding: false,
      label: t("insurance_company"),
      isSort: true,
    },
    {
      id: "policy_type",
      numeric: false,
      disablePadding: false,
      label: t("Policy_type"),
      isSort: true,
    },

  
  ];
  
  const [from, setFrom] = useState(dayjs().subtract(30, "day"));
  const [to, setTo] = useState(dayjs());
  let [policyData, setPolicyData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<string>("first_name");
  const [typeList, setTypeList] = useState([]);
  const [location, setLocation] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    handleFilter();
  }, [location]);

//   useEffect(() => {
//     getLocation();
//   }, [])

  const handleFilter = async () => {
    try {
      const isBefore = dayjs(from).isBefore(dayjs(to));
      if (!isBefore) {
        Failed("From date must be a before to date");
        return;
      }
      setLoading(true);
      const bodyData: any = {
        from: dayjs(from).format("YYYY-MM-DD"),
        to: dayjs(to).format("YYYY-MM-DD"),
      }
    //   if(location) bodyData['location'] = location?.value;
      const policyData = await commonService.postService(
        `dashboard/policy-renewed-report`,
        bodyData
      );
      const sortedArray = dynamicSort(policyData?.data?.data, orderBy, order);

      const mappedPolicyData = policyData?.data?.data.map((item:any)=> {
        return {
          ...item,
          // quotation_id : item?.quotation_id ? item?.quotation_id : "-"
          first_name1 : item?.customer?.contactInfos[0]?.first_name ? item?.customer?.contactInfos[0]?.first_name : "-",
          last_name1 : item?.customer?.contactInfos[0]?.last_name ? item?.customer?.contactInfos[0]?.last_name : "-",
        // email : item?.customer?.contactInfos[0]?.emails[0]?.email ? item?.customer?.contactInfos[0]?.emails[0]?.email : "-",
        // phone_number : item?.customer?.contactInfos[0]?.phones[0]?.phone ? item?.customer?.contactInfos[0]?.phones[0]?.phone  : "-",
          expiration_date : item?.expiration_date ? dayjs(item?.expiration_date).format("MM-DD-YYYY") : "-",
          effective_date : item?.effective_date ? dayjs(item?.effective_date).format("MM-DD-YYYY") : "-",
          insurance_company : item?.insurance_company?.name ? item?.insurance_company?.name : "-",
          policy_type : item?.policy_type?.values ? item?.policy_type?.values : "-",

          // email : ite


        }
      })
      setPolicyData(mappedPolicyData || []);
      console.log("adedie",policyData)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Failed(errorMessage(err, "Something went wrong"));
    }
  };

  const getLocation = async () => {
    try {
      const masterData: any = await commonService.getServices(
        `/location/get-all-location`
      );
      const tableDetails = masterData?.data?.data?.results?.map((item: any) => {
        return {
          value: item?.id,
          name: item?.name
        };
      });
      setTypeList(tableDetails);
    } catch (error) {}
  };

  const handleExport = async () => {
    try {
      const isBefore = dayjs(from).isBefore(dayjs(to));
      if (!isBefore) {
        Failed("From date must be a before to date");
        return;
      }
      setDownloading(true);
      const pdf = await axios({
        method: "post",
        url: `${process.env.REACT_APP_END_URL}/dashboard/policy-renewed-report/export`,
        data: {
          from: dayjs(from).format("YYYY-MM-DD"),
          to: dayjs(to).format("YYYY-MM-DD"),
        },
        responseType: "blob",
      });
      saveAs(pdf?.data, `Renewal-report-${Date.now()}.xlsx`);
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.log("err", err);
      Failed(errorMessage(err, "Something went wrong, Please try again later"));
    }
  };

  const sortArray = (key: string, value: string) => {
    setLoading(true);
    const sortedArray = dynamicSort(
      policyData,
      value,
      order === "asc" ? "desc" : "asc"
    );
    policyData = [];

    setTimeout(() => {
      policyData = [...sortedArray];
      setPolicyData(policyData);
      setLoading(false);
    }, 100);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  return (
    <>
      {downloading && <Loading />}
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            border: "solid 1px #ccc",
            backgroundColor: "#f1f1f1",
            borderRadius: "10px 10px 0 0",
            borderBottom: "none",
          }}
        >
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"left"}
              alignItems={"center"}
            >
            <Box
                  onClick={() => navigate(-1)} 
                  sx={{ cursor: "pointer", display: "flex", alignItems: "center", mr: 1 , paddingLeft : 2}}
                >
                  <KeyboardBackspaceIcon /> 
             </Box>
          <Typography
            pb="10px"
            variant="h3"
            pt="10px"
            sx={{ marginLeft: "10px", fontSize: "1.2rem" }}
          >
            {t("Renewal Report")}
          </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            border: "solid 1px #ccc",
            borderRadius: "0px 0px 10px 10px",
            overflow: "hidden",
          }}
        >
          <Grid
            container
            pb={2}
            columnSpacing={2}
            display={"flex"}
            alignItems={"flex-end"}
            sx={{ backgroundColor: "#fff" }}
            spacing={2}
          >
            <Grid item lg={2} xs={12}>
              <Box sx={{ ml: 2 }} >
              <InputDatePicker
                values={from}
                name="from"
                id="from"
                fieldName={t("From")}
                handleChange={(e) => setFrom(dayjs(e))}
                isRequired={false}
              />
              </Box>
            </Grid>
            <Grid item lg={2} xs={12}>
            <Box sx={{ ml: 2 }} >
              <InputDatePicker
                values={to}
                name="to"
                id="to"
                fieldName={t("To")}
                isRequired={false}
                handleChange={(e) => setTo(dayjs(e))}
              />
              </Box>
            </Grid>
            <Grid
              item
              lg={5}
              xs={12}
            >
              <Button variant="contained"
              sx={{
                ml: {
                  xs: "10px",
                },
                minHeight: "51px"
                
              }}
              onClick={handleFilter}>
                {t("Filter")}
              </Button>
              <Button
                variant="contained"
                sx={{
                  ml: {
                    xs: "10px",
                  },
                  minHeight: "51px"
                  
                }}
                disabled={!policyData.length}
                onClick={() => policyData.length && handleExport()}
              >
                {t("Export")}
              </Button>
            </Grid>
            {/* <Grid item
              lg={3}
              xs={12}
              >
            <Box 
                sx={{
                  px: 2
                }}
                >
                  <AutoCompleteField
                    placeholder={"Filter By Location"}
                    isRequired={false}
                    values={location || null}
                    name="type"
                    handleChange={(type: any) => setLocation(type)}
                    options={typeList}
                    id="type"
                  />
                
                </Box>
            </Grid> */}
          </Grid>
          <Grid sx={{ padding: "10px", backgroundColor: "#fff" }}>
            <EnhancedTable
              headCells={ProspectHeadCell}
              rows={loading ? [] : policyData}
              maxHeightValue={"550px"}
              isPagination={false}
              isLoading={loading}
              handleRequestSort={sortArray}
              routingLink={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RenewalReport;

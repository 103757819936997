import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  settings: {},
  language: "",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings(state, action: PayloadAction<any>) {
      state.settings = action.payload;
    },
    setLanguage(state, action: PayloadAction<any>) {
      state.language = action.payload;
    },
  },
  // extraReducers: (builder) =>
  //   builder.addCase("LOGOUT", (state, action) => {
  //     return { ...initialState };
  //   }),
});

export const { setSettings, setLanguage } = settingsSlice.actions;
export default settingsSlice.reducer;

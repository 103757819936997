import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Switch,
  Box,
  Stack,
  TextField,
  Badge,
} from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { commonService } from "forms/helpers/common.service";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { handleKeyNumberPress } from "forms/hooks/customHook";
import Loader from "ui-component/Loader";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { t } from "i18next";

const SubscriptionPlans = () => {
  const [checked, setChecked] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [planList, setPlanList] = useState<any[]>([]);
  const [featureList, setFeatureList] = useState<any[]>([]);
  const [employeesCount, setEmployeesCount] = useState<string>("1");
  const [locationsCount, setLocationsCount] = useState<string>("0");
  const [freeEmployeesCount, setFreeEmployeesCount] = useState<string>("0");
  const [freeLocationsCount, setFreeLocationsCount] = useState<string>("0");
  const [brokerId, setBrokerId] = useState<string>("");
  const [brokerLocations, setBrokerLocations] = useState<string>("0");

  const colors = [
    "#2e7d32",
    "#2980b9",
    "#c0392b",
    "#9b59b6",
    "#34495e",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#95a5a6",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f39c12",
    "#d35400",
    "#bdc3c7",
    "#7f8c8d",
  ];

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  async function getBrokerDetails() {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_SUPER_ADMIN_END_URL}/broker/broker-details-by-domain`,
          {
            domain:
              window.location.hostname === "localhost"
                ? "access.agencyportal.blockchaincloudapps.com"
                : window.location.host,
          }
        )
        .then((res) => {
          const employeeCount = +res?.data?.data?.employee_count === 0 ? "1" : res?.data?.data?.employee_count;
          const locationCount = +res?.data?.data?.location_count === 0 ? "1" : res?.data?.data?.location_count;
          setEmployeesCount(employeeCount);
          setLocationsCount(locationCount);
          setBrokerLocations(locationCount)
          setBrokerId(res?.data?.data?.id);
          resolve(res?.data?.data);
        })
        .catch((err) => {
          Failed(errorMessage(err, "Something went wrong"));
          reject();
        });
    });
  }

  useEffect(() => {
    getBrokerDetails();
    getPlanDetails();
  }, []);

  const getPlanDetails = async () => {
    setIsLoading(true);
    await getFeatureList();
    setIsLoading(false);
  };

  const getFeatureList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_SUPER_ADMIN_END_URL}/subscription-plans/feature`
        )
        .then((res) => {
          setFeatureList(res?.data?.data);
          resolve(res?.data?.data);
        })
        .catch((err) => {
          Failed(errorMessage(err, "Something went wrong"));
          reject();
        });
    });
  };

  //calculation details
  useEffect(() => {
    getDetails();
  }, [employeesCount, locationsCount]);

  const getDetails = () => {
    if (+employeesCount > 0 && +locationsCount > 0 && !!employeesCount && !!locationsCount) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.REACT_APP_SUPER_ADMIN_END_URL}/account-config/calculation-except-free`,
            {
              employeeCount: employeesCount,
              locationCount: locationsCount,
            }
          )
          .then((res) => {
            setPlanList(res?.data?.data);
            resolve(res?.data?.data);
          })
          .catch((err) => {
            Failed(errorMessage(err, "Something went wrong"));
            reject();
          });
      });
    }
  };

  const getPaymentLink = (planId: string) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      if (+locationsCount < +brokerLocations) {
        Failed(errorMessage(``, `The minimum location count should be ${brokerLocations} because you have already created ${brokerLocations} locations.`));
        setIsLoading(false);
      } else {
        axios
          .post(
            `${process.env.REACT_APP_SUPER_ADMIN_END_URL}/payment/initiate-for-email-link`,
            {
              broker_hash: brokerId,
              plan: planId,
              employeeCount: +employeesCount,
              locationCount: +locationsCount,
              coming_from: "agent",
              domain:
                window.location.hostname === "localhost"
                  ? "localhost:3000"
                  : window.location.host,
            }
          )
          .then((res) => {
            window.open(res?.data?.url, "_self");
          })
          .catch((err) => {
            Failed(errorMessage(err, "Something went wrong"));
            reject();
            // alert(err)
          });
      }
    });
  };

  return (
    <>
      <MainContainerWrapper
        headingText={t("Upgrade Plan")}
        isBackButton={true}
      // buttonText="Upgrade Plan"
      // buttonIcon={<span>&#43;</span>}
      // handleClick={hanlePlanUpdate} // Update to open the modal
      // isCreateButton={true}
      >
        {isLoading && <Loader />}
        <Box sx={{ p: 1, overflow: "auto", height: "80vh" }}>
          {/* <Typography variant="h1" sx={{ textAlign: "center", mt: 4 }}>
            Choose the best plan for you
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center", mt: 3 }}>
            A First Class Service with the Utmost Professionalism and Integrity
          </Typography> */}
          <Box py={3}>
            <Container>
              <TableContainer>
                <Stack
                  alignItems={"flex-start"}
                  direction={"row"}
                  spacing={2}
                  width={"100%"}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                    sx={{ flexWrap: "nowrap" }}
                  >
                    <Typography
                      variant="h5"
                      noWrap
                      sx={{ fontWeight: "bold", fontSize: 19 }}
                    >
                      No of Employees:
                    </Typography>
                    <TextField
                      size="small"
                      sx={{ width: "160px" }}
                      InputLabelProps={{ style: { fontSize: "18px" } }}
                      value={employeesCount}
                      variant="outlined"
                      color="primary"
                      onKeyPress={(e) => {
                        handleKeyNumberPress(e);
                      }}
                      onChange={(e) => {
                        setEmployeesCount(e?.target?.value);
                      }}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    sx={{ flexWrap: "nowrap" }}
                  >
                    <Typography
                      variant="h5"
                      noWrap
                      sx={{ fontWeight: "bold", fontSize: 19 }}
                    >
                      No of Locations:
                    </Typography>
                    <TextField
                      size="small"
                      sx={{ width: "160px" }}
                      InputLabelProps={{ style: { fontSize: "18px" } }}
                      value={locationsCount}
                      variant="outlined"
                      color="primary"
                      onKeyPress={(e) => {
                        handleKeyNumberPress(e);
                      }}
                      onChange={(e) => {
                        setLocationsCount(e?.target?.value);
                      }}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Stack>
                </Stack>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "none" }}>
                      <TableCell
                        sx={{ paddingLeft: 0, paddingBottom: 1 }}
                      ></TableCell>
                      {planList.map((plan: any, i: any) => {
                        if (i === planList.length)
                          return null; // Skip the last element
                        else {
                          return (
                            <TableCell
                              colSpan={4}
                              key={i}
                              align="center"
                            ></TableCell>
                          );
                        }
                      })}
                      {/* <TableCell colSpan={3} align="center">
                  <Typography
                    variant="h6"
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      padding: "8px 0",
                    }}
                  >
                    RECOMMENDED
                  </Typography>
                </TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableHead sx={{ border: "1px solid #ddd" }}>
                    <TableRow>
                      {/* <TableCell colSpan={4}></TableCell> */}
                      <TableCell
                        colSpan={4}
                        sx={{ fontWeight: "600", fontSize: "1.3rem" }}
                      >
                        Features
                      </TableCell>

                      {planList.map((item: any, i: any) => (
                        <TableCell colSpan={4} key={i} align="center">
                          {+item?.discounts > 0 ? (
                            <Badge
                              badgeContent={item?.discounts + "% Discount"}
                              sx={{
                                "& .MuiBadge-badge": {
                                  top: -10,
                                  padding: "14px",
                                  color: "#fff",
                                  background: "orange",
                                },
                              }}
                            >
                              <Typography
                                pb={1}
                                sx={{ fontWeight: "600", fontSize: "1.2rem" }}
                              >
                                {item.name}
                              </Typography>
                            </Badge>
                          ) : (
                            <Typography
                              pb={1}
                              sx={{ fontWeight: "600", fontSize: "1.2rem" }}
                            >
                              {item.name}
                            </Typography>
                          )}

                          <Stack
                            direction="row"
                            justifyContent={"center"}
                            alignItems="center"
                          >
                            <Typography
                              component="span"
                              variant="h5"
                              style={{ color: colors[i] }}
                            >
                              $
                            </Typography>
                            <Typography
                              component="span"
                              variant="h3"
                              style={{ color: colors[i], fontWeight: 700 }}
                            >
                              {item.planTotalAmount}
                            </Typography>
                          </Stack>
                          {item.is_free ? (
                            <Typography
                              variant="body1"
                              fontStyle={"oblique"}
                              sx={{ textAlign: "center", mt: 1 }}
                            >
                              Enjoy the plan with {freeEmployeesCount} employee &{" "}
                              {freeLocationsCount} location for {item?.duration}{" "}
                              {item?.duration_type === "daily"
                                ? "days"
                                : +item?.duration > 1
                                  ? "months"
                                  : "month"}
                              .
                            </Typography>
                          ) : (
                            <Typography
                              variant="body1"
                              fontStyle={"oblique"}
                              sx={{ textAlign: "center", mt: 1 }}
                            >
                              For {item?.duration}{" "}
                              {item?.duration_type === "daily"
                                ? "days"
                                : +item?.duration > 1
                                  ? "months"
                                  : "month"}
                              .
                            </Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ border: "1px solid #ddd" }}>
                    {featureList.map((feature: any, i: any) => (
                      <TableRow key={i}>
                        <TableCell colSpan={4} sx={{}}>
                          {feature.name}
                        </TableCell>
                        {planList.map((item: any, i: any) => {
                          const featureArray = item?.features.map(
                            (item: any) => item.name
                          );
                          console.log("FeatureArray", featureArray);
                          return (
                            <TableCell colSpan={4} align="center" key={i}>
                              {featureArray.includes(feature.name) ? (
                                <CheckCircle style={{ color: "green" }} />
                              ) : (
                                <Cancel style={{ color: "red" }} />
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell colSpan={4} align="center"></TableCell>
                      {/* <TableCell colSpan={4} align="center">
                  <Button
                    variant="contained"
                    color="success"
                    style={{ margin: "0 16px", backgroundColor: "#2e7d32" }}
                    onClick={() => navigate("/broker_details",{state: planList[0]?.id,})}
                  >
                    Join Free Trials
                  </Button>
                </TableCell> */}

                      {planList.map((plan: any, i: any) => {
                        // if (i === planList.length - 1)
                        //   return null; // Skip the last element
                        // else {
                        return (
                          <TableCell colSpan={4} align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                margin: "0 16px",
                                backgroundColor: colors[i],
                                width: "12rem",
                                "&.Mui-disabled": {
                                  backgroundColor: "grey", // Set the disabled button color to grey
                                  color: "white", // Optional: Set the text color when the button is disabled
                                },
                              }}
                              onClick={() => getPaymentLink(planList[i]?.id)}
                              disabled={
                                (employeesCount > freeEmployeesCount ||
                                  locationsCount > freeLocationsCount) &&
                                planList[i].is_free
                              }
                            >
                              {/* {planList[i].name == "Free" ? "Sub-domain NameTrials" : "Get Started"} */}
                              {planList[i].is_free
                                ? "Join Free Trial"
                                : "Get Started"}
                            </Button>
                          </TableCell>
                        );
                        // }
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Box>
        </Box>
      </MainContainerWrapper>
    </>
  );
};

export default SubscriptionPlans;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState : any = {
    makepayment: {}
}

export const makepaymentSlice = createSlice({
    name: "makepayment",
    initialState,
    reducers: {
        // Define reducers here
        setMakepayment(state,action:PayloadAction<any> ) {
            state.makepayment = action.payload
        },
        setPersonalInfo(state,action:PayloadAction<any>){
            state.makepayment.personalInfo = action.payload
        }
    },
    extraReducers: (builder) => {
        // Define extra reducers here
    },
})

export const { setMakepayment,setPersonalInfo } = makepaymentSlice.actions;
export default makepaymentSlice.reducer;
// src/context/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { setNotiCount, setNotiEndorsementCount } from "store/slice/user.slice";
import { useAppDispatch } from "store/store";
import { useLocalStorage } from "./localstorage.helper";
import { handleSocketToast } from "forms/helpers/socket.toast";
const socketUrl =
  process.env.REACT_APP_SOCKET_URL || "http://localhost:3001/api/socket";

interface ISocketProvider {
  socket: Socket | null;
  getNotificationCount?: any;
  setSocketToken?: any;
  readMessage?: any;
  getNotificationEndorsementCount?: any;
  readEndorsementMessage?: any;
}

const SocketContext = createContext<ISocketProvider>({ socket: null });

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }: any) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const dispatch = useAppDispatch();
  const [token, setToken] = useLocalStorage("token", null);

  const handleUserConnection = (e: any) => {
    console.log("User Joined");
  };

  useEffect(() => {
    const connection = io(socketUrl, {
      path: "/api/socket",
      transports: ["websocket", "polling"],
      auth: { token },
    });
    connection.on("connect", () => {
      setSocket(connection);
      if (token) {
        connection?.emit("joined", handleUserConnection);
      }
    });

    connection.on("disconnect", () => {
      console.log("Socket disconnected!");
      setSocket(null);
    });

    connection.on("receive-notification", () => {
      console.log("Received notification event");
      connection.emit("notification-count", handleNotification);
    });

    connection.on("notification-message", (message: string) => {
      handleSocketToast(message);
    })

    connection.on("general-notification-count", (message: any) => {
      console.log('general-notification-count', message);
      dispatch(setNotiEndorsementCount(message?.count));
    })

    return () => {
      if (socket) {
        socket?.disconnect();
      }
    };
  }, [token]);

  const handleNotification = (count: { count: number }) => {
    dispatch(setNotiCount(count?.count));
  };

  const getNotificationCount = () => {
    if (socket) {
      socket?.emit("notification-count", handleNotification);
    }
  };

  const readMessage = () => {
    if (socket) {
      socket?.emit("read-notification");
    }
  };

  const readEndorsementMessage = () => {
    if (socket) {
      socket?.emit("general-read-notification");
    }
  };

  const handleNotificationEndorsement = (count: { count: number }) => {
    // console.log('endorsements', setNotiEndorsementCount(count?.count))
    // dispatch(setNotiEndorsementCount(count?.count));
  };

  // const getNotificationEndorsementCount = () => {
  //   if (socket) {
  //     socket?.emit("general-notification-count", handleNotificationEndorsement);
  //   }
  // };

  const setSocketToken = (token: string = "") => {
    if (socket) {
      socket.disconnect(); //This uses the same socket and disconnect with the server.
      socket.auth = {
        token,
      };
      socket.open(); //Opens up a new connection with `x-auth-token` in headers
    }
  };

  return (
    <SocketContext.Provider
      value={{
        socket: socket,
        getNotificationCount,
        setSocketToken,
        readMessage,
        // getNotificationEndorsementCount,
        readEndorsementMessage,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import theme from "asset/themes";
import dayjs from "dayjs";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import TextView from "forms/hoc/text-view";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Loader from "ui-component/Loader";
import TemplateModel from "component/helper-modals/Template.model";
import VehiclePolicyListModel from "component/helper-modals/VehiclePolicyList.model";


const VehicleViewCustomer = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [vehicle, setVehicle] = useState<any>({});
  const [templateOpen, setTemplateOpen] =  useState(false);
  const {id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Component ID:", id); // Debug log
    getVehicle();
  }, []);
  

  const getVehicle = async () => {
    try {
      if (id) {
        const res = await commonService.getServices(`/vehicle/${id}`);
        const result = res?.data?.data;
        if (!!result) {
          console.log("Vehicle", result);
          setVehicle(result);
        }
      }
      setLoading(false);
    } catch (err) {
      Failed(errorMessage(err, "Something went wrong"));
    }
  };

  const handleTemplateOpen = () => {
    setTemplateOpen(!templateOpen);
  }

  return (
    <>
      {loading && <Loader />}
      {templateOpen && <VehiclePolicyListModel open={templateOpen} handleClose={handleTemplateOpen}/>}
    <MainContainerWrapper>
    <Box
        sx={{
          p: 3,
          height: "100%",
          width: "100%",
        }}
      >
      <Grid container sx={{ paddingRight: "25px" }}>
      <Grid item xs={12} lg={12}>
              <Box py={2} pl={2} bgcolor={theme?.palette?.grey[200]}>
                <Typography variant="h3" textAlign={"left"}>
                  Vehicle View
                </Typography>
              </Box>
            </Grid>
        <Grid container columnSpacing={2} pt={1}>
          {/* {!isQuote && (
            <Grid item xs={12} lg={3}>
              <Box p={1}>
                <TextView
                  fieldName="Endorsement Dateee"
                  fieldValue={vehicle?.endorsement_date || "-"}
                />
              </Box>
            </Grid>
          )} */}
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Vehicle Identification Number(VIN)"
                fieldValue={vehicle?.vin || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Make"
                fieldValue={vehicle?.make?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Model"
                fieldValue={vehicle?.model?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Year"
                fieldValue={vehicle?.year?.values || "-"}
              />
            </Box>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Number Plate"
                fieldValue={vehicle?.number_plate || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="SHL Permit"
                fieldValue={vehicle?.shl_permit ? "Yes" : "No"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="State Registered"
                fieldValue={vehicle?.state?.name || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Body Type"
                fieldValue={vehicle?.body_type?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Vehicle usage"
                fieldValue={vehicle?.vehicle_usage?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Vehicle"
                fieldValue={vehicle?.vehicle?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Odometer"
                fieldValue={vehicle?.odometer || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Performance"
                fieldValue={vehicle?.performance?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Territory"
                fieldValue={vehicle?.territory?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Comp Symbol"
                fieldValue={vehicle?.comp_symbol?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Collision Symbol"
                fieldValue={vehicle?.collison_symbol?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Cylinders"
                fieldValue={vehicle?.cylinders?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Horse Power"
                fieldValue={vehicle?.horse_power || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Fuel Type"
                fieldValue={vehicle?.fuel_type || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Purchase Date"
                fieldValue={
                  (vehicle?.purchase_date &&
                    dayjs(vehicle?.purchase_date).format("MM-DD-YYYY")) ||
                  "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Class Code"
                fieldValue={vehicle?.class_code?.values || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Seating Capacity"
                fieldValue={vehicle?.seating_capacity || "-"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Reg Exp Date"
                fieldValue={
                  (vehicle?.reg_exp_date &&
                    dayjs(vehicle?.reg_exp_date).format("MM-DD-YYYY")) ||
                  "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Diamond Exp Date"
                fieldValue={
                  (vehicle?.diamond_exp_date &&
                    dayjs(vehicle?.diamond_exp_date).format("MM-DD-YYYY")) ||
                  "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Diamond Exp Date"
                fieldValue={
                  (vehicle?.diamond_exp_date &&
                    dayjs(vehicle?.diamond_exp_date).format("MM-DD-YYYY")) ||
                  "-"
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box p={1}>
              <TextView
                fieldName="Mapped Policy List To View"
                fieldValue="Click Here to view"
                onClick={handleTemplateOpen}
                isLinkText={true}
              />
            </Box>
          </Grid>
        </Grid>
        
      </Grid>
      
      <Box sx={{ pt: 2 }} display={"flex"} justifyContent={"right"}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightgray",
                color: "#121926",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
                mr: 3,
              }}
              onClick={() => {
                navigate(-1)
                
            }}
            >
              {"Back"}
            </Button>
          </Box>
          </Box>
          
          </MainContainerWrapper>
    </>
  );
};

type VehicleViewType = {
  id: string;
  isQuote?: boolean;
  handleBack?: any;
};

export default VehicleViewCustomer;

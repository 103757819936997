
import i18next from 'i18next';

export const quoteInitailValue = {
  
  id: "",
  customer: {
    state_code: "NY",
    prefix: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    suffix: null,
    gender: null,
    marital_status: null,
    social_security: null,
    dob: null,
    email: "",
    county_code: "+1",
    mobile: "",
    business_type: null,
    website: null,
    license_number: null,
    license_state: null,
    license_issue_date: null,
    license_exp_date: null,
    address_line_one: null,
    address_line_two: null,
    state: null,
    city: null,
    zipcode: null,
    address_type: null
  },
  policy: {
    policy_state: null,
    insurance_company: null,
    policy_type: null,
    policy_term: null,
    effective_date: null,
    expiration_date: null,
    policy_status_type: null,
    premium: null,
    carrier_code: null,
    product_code: null,
    sub_product_code: null,
    underwriting_code: null,
    producer_code: null,
    payment_plan_code: null,
    creator_code: null,
    classification: null,
  },
  documents: [
    {
      name: "drivers_license", 
      document_name: "Drivers License",
      path: "",
      file_type: "",
    },
    {
      name: "tcl_drivers_license",
      document_name: "TLC Driver License",
      path: "",
      file_type: "",
    },
    {
      name: "hack_license",
      document_name: "Hack License",
      path: "",
      file_type: "",
    },
  ],
  vehicle: {
    endorsement_date: null,
    vin: null,
    make: null,
    model: null,
    year: null,
    number_plate: null,
    shl_permit: null,
    state_registered: null,
    body_type: null,
    vehicle_usage: null,
    performance: null,
    territory: null,
    comp_symbol: null,
    collison_symbol: null,
    cylinders: null,
    horse_power: null,
    fuel_type: null,
    purchase_date: null,
    class_code: null,
    seating_capacity: null,
    reg_exp_date: null,
    diamond_exp_date: null,
    vehicle: null,
    odometer: null,
  },
  driver: {
    state_code: "NY",
    same_as_applicant: false,
    prefix: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    email: null,
    suffix: null,
    gender: null,
    marital_status: null,
    dob: null,
    occupation: null,
    military_status: null,
    is_licensed_driver: null,
    date_licensed: null,
    ddc_course_date: null,
    license_expiry_date: null,
    license_number: null,
    state_licensed: null,
    tlc_license_number: null,
    tlc_effective_date: null,
    tlc_exp_date: null,
    total_accidental_conviction_point: null,
    id: null,
    social_security: null,
    address_type: null,
    address_line_one: null,
    address_line_two: null,
    state: null,
    city: null,
    zipcode: null,
    hack_license_number: null,
    hack_exp_date: null,
    hack_effective_date: null,
    is_kyc_verified: false,
  },
  tax: {
    tax_id_type_cd: "",
    tax_id: null,
    quoteId: "",
    social_security: "",
    recipients1099: false,
    recipients1099_recieve_date: null,
    recipients1099_date_from: null,
    recipients1099_date_to: null,
    w9_recieved_date: null,
    w9_recieved_from: null,
    w9_recieved_to: null,
    nsf_restricted: false,
    w9_recieved: false,
    Version: 0
  }
};

export const paymentTypeEnum: any = {
  credit: 'CCSALE',
  debit: 'CCSALE',
  ach: 'ecspurchase'
}

export const paymentModeEnum: any = {
  credit: 'cc_cash_due',
  debit: 'dc_cash_due',
  ach: 'ach_cash_due'
}

export const paymentType = [
  { value: 'credit', name: 'Credit Card' },
  { value: 'debit', name: 'Debit Card' },
  { value: 'ach', name: 'ACH' },
]

export const chequeCancelType = [
  { value: 'Processed', name: 'Processed' },
  { value: 'Cancelled', name: 'Cancelled' },
]

export const limitOptions = [
  {
    name: "EL",
    value: "ExposureLimit",
  },
  {
    name: "IL",
    value: "IncidentLimit",
  },
];

export const deductibleOptions = [
  {
    name: "DED",
    value: "DED",
  },
];
import { Box, Grid, Typography } from "@mui/material";
import EnhancedTable from "component/tables/selectableTable";
import Loading from "container/loading";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import React, { useEffect, useState, useTransition } from "react";
import { useParams } from "react-router-dom";
import { customerVehicleViewHeadCell } from "utils/table.utils";
import { useNavigate } from "react-router-dom";
import { debounce } from "helpers/debounce";
import { useTranslation } from "react-i18next";

const Vehicle_List = () => {
  const{t} = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  let [search, setSearch] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<any>();
  const [rows, setRows] = useState<any>();
  const [initailLoad, setInitailLoad] = useState(true);
  let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  let [orderBy, setOrderBy] = useState("created_at");
  const [isLoading, setIsLoading] = useState<any>(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getTableData();
  }, [page, rowsPerPage]);

  const handleAction = (id: string, action: string) => {
    if (action === "view") {
      navigate(`/customer/Vehicle/view/${id}`);
    }
  };

  const getTableData = () => {
    setIsLoading(true);
    commonService
      .getServiceWithParams(`/customer/customerVehicle`, {
        per_page: rowsPerPage,
        page: page + 1,
        sort_order: order,
        sort_by: orderBy,
        policy: id,
      })
      .then((res) => {
        console.log("Result: ", res);
        const tableDetails = res?.data?.data?.results.map((item: any) => {
          return {
            id: item?.id,
            vin: item?.vin,
            model: item?.model?.values,
            policy_number1: item?.policy[0]?.policy_number,
            policyId: item?.policy[0]?.id,
            year: item?.year?.values,
            make: item?.make?.values,
            // model: item?.model,
          };
        });
        setCount(res?.data.data?.pagination?.total);
        setRows(tableDetails);
        setIsLoading(false);
        setInitailLoad(false);
      })
      .catch((err) => {
        Failed(
          errorMessage(err, "Something went wrong, please try again later")
        );
        setIsLoading(false);
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const searchHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    search = e.target.value;
    setSearch(search);
  };
  useEffect(() => {
    const delay = 500; // Debounce delay in milliseconds
    const performSearch = (text: string) => {
      // Perform your search logic here
      if (initailLoad) return;
      if (page == 0) getTableData();
      else {
        setPage(0);
      }
    };
    // if (search.length >= 3 || !search) {
    const debouncedSearch = debounce(performSearch, delay);
    const timeoutId = setTimeout(() => debouncedSearch(search), delay);
    return () => {
      clearTimeout(timeoutId);
    };
    // }
  }, [search]);
  return (
    <>
      {isLoading && <Loading />}

      <MainContainerWrapper
        headingText={t("Vehicle_List")}
        isSearch={false}
        search={search}
        handleSearch={searchHandler}
        handleSearchClear={() => setSearch("")}
        isCreateButton={false}
        //buttonText="Add Customer"
        buttonIcon={<span>&#43;</span>}
        isFilter={false}
        //filter={filter}
        //filterList={typeList}
        //setUpdateValue={handleFilterChange}
        filterPlaceHolder="Filter"
      >
        <Grid
          lg={12}
          xs={12}
          sx={{
            mt: {
              xs: 4,
              md: 0,
              lg: 0,
              xl: 0,
              xxl: 0,
            },
          }}
        >
          <EnhancedTable
            headCells={customerVehicleViewHeadCell}
            rows={rows}
            maxHeightValue={"100%"}
            page={page}
            rowsPerPage={rowsPerPage}
            total={count}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            //isEdit={true}
            actionFunction={handleAction}
            isView={true}
          />
        </Grid>
      </MainContainerWrapper>
    </>
  );
};

export default Vehicle_List;

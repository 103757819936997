import EnhancedTable from "component/tables/selectableTable";
import dayjs from "dayjs";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import { dateFormatForm } from "forms/hooks/customHook";
import { t } from "i18next";
import MainContainerWrapper from "layout/wrappers/MainContainerWrapper";
import { useEffect, useState } from "react";
import Loader from "ui-component/Loader";
import { ACHHistoryHeadCell, transactionsHistoryHeadCell } from "utils/table.utils";
import { useTranslation } from "react-i18next";


const TransactionHistory = ({


    type,
}: {
    type: "CARD" | "ACH" | null,
}) => {
    const { t} = useTranslation();
    const transactionsHistoryHeadCell = [
        
        {
          id: "policy_Number",
          numeric: false,
          disablePadding: true,
          label:t("policy_number"),
          isSort: false,
          isBold: false,
        },
        {
          id: "customer_Name",
          numeric: false,
          disablePadding: false,
          label:t("Customer Name"),
          isSort: false,
        },
        {
          id: "location",
          numeric: false,
          disablePadding: false,
          label:t("Location"),
          isSort: false,
        },
        {
          id: "transactionAmount",
          numeric: false,
          disablePadding: false,
          label:t("transaction_amount"),
          isSort: false,
        },
        {
          id: "card_fee_percentage",
          numeric: false,
          disablePadding: false,
          label:t("Card Fee Percentage"),
          isSort: false,
        },
        {
          id: "type_of_card",
          numeric: false,
          disablePadding: false,
          label:t("mode_of_payment"),
          isSort: false,
        },
        {
          id: "orignal_amount",
          numeric: false,
          disablePadding: false,
          label:t("policy_amount"),
          isSort: false,
        },
        {
      
          id: "tnxdate",
          numeric: false,
          disablePadding: false,
          label:t("transaction_date"),
          isSort: false,
        }
      
      ];

 const ACHHistoryHeadCell = [
        {
          id: "policy_Number",
          numeric: false,
          disablePadding: true,
          label:t("policy_number"),
          isSort: false,
          isBold: false,
        },
        {
          id: "customer_Name",
          numeric: false,
          disablePadding: false,
          label:t("Customer Name"),
          isSort: false,
        },
        {
          id: "location",
          numeric: false,
          disablePadding: false,
          label:t("Location"),
          isSort: false,
        },
        {
          id: "transactionAmount",
          numeric: false,
          disablePadding: false,
          label:t("transaction_amount"),
          isSort: false,
        },
        {
          id: "ach_flat_value",
          numeric: false,
          disablePadding: false,
          label:t("ACH Flat Fee"),
          isSort: false,
        },
        {
          id: "type_of_card",
          numeric: false,
          disablePadding: false,
          label:t("mode_of_payment"),
          isSort: false,
        },
        {
          id: "orignal_amount",
          numeric: false,
          disablePadding: false,
          label:t("policy_amount"),
          isSort: false,
        },
        {
      
          id: "tnxdate",
          numeric: false,
          disablePadding: false,
          label:t("transaction_date"),
          isSort: false,
        }
      
      ];      

    const [isLoading, setIsLoading] = useState<any>(false);
    const [from, setFrom] = useState<any>(dayjs(dayjs().startOf('month').toDate()));
    const [to, setTo] = useState<any>(dayjs(dayjs().endOf('month').toDate()));
    const [page, setPage] = useState<number>(0);
    const [transHistory, setTransHistory] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    let [order, setOrder] = useState<"DESC" | "ASC">("DESC");
    let [orderBy, setOrderBy] = useState("created_at");
    const [count, setCount] = useState<any>();
    const [filter, setFilter] = useState<any>(null);
    const [typeList, setTypeList] = useState([]);
    const [location, setLocation] = useState<any>(null);
    const [loading, setLoading] = useState(false);


    // useEffect(()=>{
    //     getTableData()
    // },[filter])

    useEffect(() => {
        getLocation()
    }, [])

    useEffect(() => {
        if (filter) {
            getTableData(); // Call getTableData only when filter is available
        }
    }, [filter]);

    const getTableData = () => {
        
        const isBefore = dayjs(from).isBefore(dayjs(to));
        if (!isBefore) {
            Failed("From date must be a before to date");
            return;
        }

        setLoading(true);
        return new Promise((resolve, reject) => {
            const bodyData: any = {
                searchStartDate: dayjs(from).format("MM/DD/YYYY"),
                searchEndDate: dayjs(to).format("MM/DD/YYYY"),
                location: filter?.value || null,
                typeOfTransaction:type
            };

            commonService
                .postService("payment/reconciliation/type/make-payment", bodyData)
                .then((res: any) => {
                    if (res?.data) {
                        const comData = res?.data?.data.map((data: any) => {
                            return {
                                ...data,
                                policy_Number: data?.policyNumber,
                                customer_Name: data?.customerName,
                                location: data?.location,
                                transactionAmount: '$' + parseFloat(data?.transactionAmount).toFixed(2) || '$0.00',
                                // transactionAmountElavon: '$' + parseFloat(data?.transactionAmountElavon).toFixed(2) || '$0.00',
                                // card_fee_percentage: data?.card_fee_percentage,
                                card_fee_percentage: data?.who_has_to_pay_the_transaction_fee ===  "driver" ? `${data?.card_fee_percentage}%`: "-NA-",
                                ach_flat_value: data?.card_fee_percentage,
                                type_of_card: data?.type_of_card,
                                orignal_amount: '$' + parseFloat(data?.orignal_amount).toFixed(2) || '$0.00',
                                tnxdate: dateFormatForm(data?.txnTime),

                            }
                        })

                        setTransHistory(comData);
                        setLoading(false);
                        resolve(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(false);
                    Failed(errorMessage(err, "Something went wrong"));
                });
        });
    };
    const getLocation = async () => {
        try {
            const masterData: any = await commonService.getServices(
                `/location/get-all-location`
            );
            const tableDetails = masterData?.data?.data?.results?.map((item: any) => {
                return {
                    value: item?.id,
                    name: item?.name
                };
            });
            setTypeList(tableDetails);
            if (!filter) {  // Only set filter if it hasn't been manually set yet
                setFilter(tableDetails[0]);  // Set the initial default filter value (first location)
            }
        } catch (error) { }
    };

    const handleFilterChange = (newFilter: any) => {
        setFilter(newFilter);
        setPage(0);
    };


    return (
        <>
            {isLoading && <Loader />}
            <MainContainerWrapper
                headingText={type === "CARD" ?t("CARD HISTORY"):t("ACH HISTORY")}
                isFilter={true}
                filter={filter}
                filterList={typeList}
                setUpdateValue={handleFilterChange}
                filterPlaceHolder1={t("Filter By Location")}
            >
                <EnhancedTable
                    headCells={type=== "CARD"? transactionsHistoryHeadCell : ACHHistoryHeadCell }
                    rows={transHistory}
                    rowsPerPage={rowsPerPage}
                    isPagination={false}

                />
            </MainContainerWrapper>
        </>
    )
}

export default TransactionHistory;

